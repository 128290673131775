import React, { useMemo, useState } from "react";
import { useLocation } from 'react-router-dom'


export const DroppableContext = React.createContext();

export const DroppableWrapper = (props) => {
    const [activeItem, setActiveItem] = useState(null);
    const [shouldCancelDrop, setShouldCancelDrop] = useState(false);
    const [shouldAnimate, setShouldAnimate] = useState(false);
    const location = useLocation();

    const isInFolder = useMemo(() => location.pathname === "/" ? false : true, [location]);
    
    const values = {
        activeItem,
        setActiveItem,
        shouldCancelDrop,
        setShouldCancelDrop,
        shouldAnimate,
        setShouldAnimate,
        isInFolder
    };

    return (
        <>
            <DroppableContext.Provider value={values}>
                {props.children}
            </DroppableContext.Provider>
        </>
    );
};
