import xxhash from 'xxhash-wasm'
import { CargoHashId } from './CargoHashId.js'
import { Rng } from './Rng.js'

export const CargoHashIdGeneratorDefaults = {
  alphabet: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
  alphabetMask: 0x1F,
  prefixLength: 1,
  compressedLength: 10,
  randomBytesPoolSize: 128, // Ought to be enough for anybody
  epoch: 1619568000000, // April 28, 2021 - "CHID" was coined
}

export class CargoHashIdGenerator {
  constructor(config = {}) {
    this.config = {
      ...CargoHashIdGeneratorDefaults,
      ...config
    }

    this.timestamp = 0
    this.bytesPool = new Uint8Array(this.config.randomBytesPoolSize)
    this.bytesOffset = this.bytesPool.length
    this._rng = undefined
    this._prefixed = false
    this._xxhash = undefined
    this._compressed = false
  }

  async enablePrefix() {
    if (typeof this._rng !== 'object') {
      this._rng = await Rng.createByEnvironment()
    }

    this._prefixed = true
  }

  disablePrefix() {
    this._prefixed = false
  }

  get isPrefixed() {
    return (this._prefixed && typeof this._rng === 'object')
  }

  async enableCompression() {
    if (typeof this._xxhash !== 'function') {
      const { h32Raw } = await xxhash()
      this._xxhash = h32Raw
    }

    this._compressed = true
  }

  disableCompression() {
    this._compressed = false
  }

  get isCompressed() {
    return (this._compressed && typeof this._xxhash === 'function')
  }

  sequenceTimestamp() {
    let timestamp = (new Date()).getTime() - this.config.epoch
    if (timestamp <= this.timestamp) {
      timestamp = this.timestamp + 1
    }

    this.timestamp = timestamp

    return timestamp
  }

  readRandomBytes(size) {
    if (this.bytesOffset > (this.bytesPool.length - size)) {
      this._rng.fill(this.bytesPool)
      this.bytesOffset = 0
    }

    return this.bytesPool.subarray(this.bytesOffset, (this.bytesOffset += size))
  }

  generatePrefix(length = 1, attempts = 0, pre = '') {
    const bytesPadding = (attempts + 1) * 2
    const bytes = this.readRandomBytes(length + bytesPadding)
    for (const b of bytes) {
      pre += this.config.alphabet[b & this.config.alphabetMask] ?? ''
      if (pre.length === length) {
        return pre
      }
    }

    // Try again if the prefix is not of the specified length
    return this.generatePrefix(length, attempts + 1, pre)
  }

  generateId(siteId = undefined, userId = undefined) {
    const prefix = this.isPrefixed ? this.generatePrefix(this.config.prefixLength) : ''
    const timestamp = this.sequenceTimestamp()
    return new CargoHashId(prefix, timestamp, siteId, userId)
  }

  compressId(cargoHashId) {
    const hex = cargoHashId.encoded.toString(16)
    const hexPairs = (hex.length % 2 === 1) ? '0' + hex : hex
    const hexBytes = hexPairs.match(/[0-9A-Fa-f]{2}/g)
    const buffer = new Uint8Array(hexBytes.length)
    hexBytes.forEach((byte, i) => buffer[i] = parseInt(byte, 16))

    let hash = this._xxhash(buffer).toString()
    if (hash.length !== this.config.compressedLength) {
      hash = hash.substring(0, this.config.compressedLength)
                 .padStart(this.config.compressedLength, '0')
    }

    return `${cargoHashId.prefix}${hash}`
  }

  *generator(siteId = undefined, userId = undefined) {
    if (isNaN(userId) || isNaN(siteId)) {
      throw new Error('Hash ID generation requires valid site and user IDs')
    }

    if (siteId === 0 || userId === 0) {
      console.warn('Hash ID generation requires valid site and user IDs; allowing a \'0\' value for now, but collisions will be possible')
    }

    while (true) {
      const id = this.generateId(siteId, userId)
      if (this.isCompressed) {
        yield this.compressId(id)
      } else {
        yield id.toString()
      }
    }
  }
}
