import React, { createContext, useContext, useReducer, useMemo, useCallback } from 'react';

const AnimationContext = createContext();

const initialState = {
    potentialAnimations: new Set(),
    inProgressAnimations: new Set(),
    completedAnimations: new Map(),
};

const animationReducer = (state, action) => {

    switch (action.type) {
        // Register a potential animation.
        case 'REGISTER_POTENTIAL_ANIMATION': {

            const { key } = action;

            if (state.inProgressAnimations.has(key)) {
                // console.log(`Animation key ${key} is already in progress.`);
                return state;
            }

            const potentialAnimations = new Set(state.potentialAnimations);
            // Add animation key to potentialAnimations
            potentialAnimations.add(key);

            return {
                ...state,
                potentialAnimations,
            };

        }
        // Start a queued animation.
        case 'START_ANIMATION': {

            const { key } = action;
            // If the animation is already in progress, turn back.
            if (state.inProgressAnimations.has(key)) {
                // console.log(`Animation key ${key} already in progress.`);
                return state;
            }

            const potentialAnimations = new Set(state.potentialAnimations);
            const inProgressAnimations = new Set(state.inProgressAnimations);

            // Remove from potentialAnimations
            potentialAnimations.delete(key);
            // Add to inProgressAnimations
            inProgressAnimations.add(key);

            return {
                ...state,
                potentialAnimations,
                inProgressAnimations,
            };

        }
        // Complete an animation.
        case 'COMPLETE_ANIMATION': {

            const { key } = action;

            const potentialAnimations = new Set(state.potentialAnimations);
            const inProgressAnimations = new Set(state.inProgressAnimations);
            const completedAnimations = new Map(state.completedAnimations);

            // Remove from potential and inProgress animations
            potentialAnimations.delete(key);
            inProgressAnimations.delete(key);
            // Increment count in completedAnimations
            const currentCount = completedAnimations.get(key) || 0;
            completedAnimations.set(key, currentCount + 1);

            return {
                ...state,
                potentialAnimations,
                inProgressAnimations,
                completedAnimations,
            };
        }
        case 'CANCEL_ANIMATION': {
            const { key } = action;
            const potentialAnimations = new Set(state.potentialAnimations);
            const inProgressAnimations = new Set(state.inProgressAnimations);
            // Remove from potential and inProgress animations
            potentialAnimations.delete(key);
            inProgressAnimations.delete(key);

            // console.log(`Animation ${key} cancelled`);

            return {
                ...state,
                potentialAnimations,
                inProgressAnimations,
            };
        }
        default:
            return state;
    }
};

export const AnimationProvider = ({ children }) => {
    const [state, dispatch] = useReducer(animationReducer, initialState);

    const registerPotentialAnimation = useCallback(
        (key) => {
            dispatch({ type: 'REGISTER_POTENTIAL_ANIMATION', key });
        },
        [dispatch]
    );

    const startAnimation = useCallback(
        (key) => {
            dispatch({ type: 'START_ANIMATION', key });
        },
        [dispatch]
    );

    const completeAnimation = useCallback(
        (key) => {
            dispatch({ type: 'COMPLETE_ANIMATION', key });
        },
        [dispatch]
    );

    const cancelAnimation = useCallback(
        (key) => {
            dispatch({ type: 'CANCEL_ANIMATION', key });
        },
        [dispatch]
    );

    const value = useMemo(
        () => ({
            potentialAnimations: state.potentialAnimations,
            inProgressAnimations: state.inProgressAnimations,
            completedAnimations: state.completedAnimations, // Now a Map
            registerPotentialAnimation,
            startAnimation,
            completeAnimation,
            cancelAnimation,
        }),
        [
            state.potentialAnimations,
            state.inProgressAnimations,
            state.completedAnimations,
            registerPotentialAnimation,
            startAnimation,
            completeAnimation,
            cancelAnimation,
        ]
    );

    return <AnimationContext.Provider value={value}>{children}</AnimationContext.Provider>;
};

export const useAnimationContext = () => useContext(AnimationContext);