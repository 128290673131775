import SiteScrollerArrow from "@cargo/common/icons/site-scroller-arrow.svg";

export const SiteScrollerNav = ({ folder, pushSiteScroller }) => {
    return(
        <div className="navigation">
            <button 
                className="next"
                onMouseDown={(e)=>{
                    pushSiteScroller(
                        '/templates/' + folder.slug,
                        folder.id
                    )
                }}
                folder-id={folder.id}
            >
                <SiteScrollerArrow />
            </button>
        </div>
    )
}
