import _ from 'lodash';
import Fuse from 'fuse.js';

const fuseInstance = new Fuse([], {
	shouldSort: true,
	findAllMatches: true,
	includeMatches: true,
	matchAllTokens: true,
	threshold: .1,
	tokenize: true,
	includeScore: true,
	maxPatternLength: 32,
	minMatchCharLength: 1,
	keys: ['display_url', 'website_title']
});

export const filterSites = (sites, options) => {

	options = _.defaults(options, {
		deleted: false,
		folder: null,
		search: null,
		upgraded: null
	})

	let filteredSites = [...sites];

	if(options.search) {

		// Update fuse if we have new site data
		if(fuseInstance._docs !== sites) {
			fuseInstance.setCollection(sites);
		}

		// first apply search query to all sites
		filteredSites = _.map(fuseInstance.search(options.search), 'item');

	}

	// filter by folder
	if(options.folder) {
		// filter out everything not in this folder
		if(options.folder && options.folder.sites) {
			
			const folderSitesById = options.folder.sites.reduce((map, site) => {
				map[site.site_id] = site;
				return map;
			}, {});

			filteredSites = filteredSites
				// filter out all sites not in current folder
				.filter(site => folderSitesById.hasOwnProperty(site.id))
				// sort them as they appear in the folder
				.sort((a,b) => folderSitesById[a.id].sort - folderSitesById[b.id].sort)

		}
	}

	// filter out deleted sites
	if(options.deleted === false) {
		// filter out deleted sites
		filteredSites = filteredSites.filter(site => !site.is_deleted)
	}

	// filter out sites that aren't marked for delete.
	if( options.deleted === true ){
		// filter for deleted sites
		filteredSites = filteredSites.filter( site => { return site.is_deleted && !_.isEmpty( site.screenshot ) })
	}

	// filter out upgraded sites
	if(options.upgraded === false) {
		// filter out deleted sites
		filteredSites = filteredSites.filter(site => !site.is_upgraded)
	}

	// filter out upgraded sites
	if(options.upgraded === true) {
		// filter out deleted sites
		filteredSites = filteredSites.filter(site => site.is_upgraded)
	}

	// filter out undefined and nulls
	_.pull(filteredSites, undefined, null);

	// filter out dupes
	filteredSites = _.uniqBy(filteredSites, 'id');

	return filteredSites;

}