import { withRouter, NavLink} from 'react-router-dom';

export const CommunityMenu = (props) => {


    return ( 
        <div id="community-menu" className="light-mode">
            <NavLink exact
                key={`/community/all`} 
                to={`/community/all`} 
                className={`button-link all`}
                activeClassName="selected"
            >All</NavLink>
            <NavLink exact
                key={`/community/in-use`} 
                to={`/community/in-use`} 
                className={`button-link in-use`}
                activeClassName="selected"
            >Sites in Use</NavLink>
            <NavLink exact
                key={`/community/graphic-design`} 
                to={`/community/graphic-design`} 
                className={`button-link graphic-design`}
                activeClassName="selected"
            >Graphic Design</NavLink>
            <NavLink exact
                key={`/community/style`} 
                to={`/community/style`} 
                className={`button-link style`}
                activeClassName="selected"
            >Style</NavLink>
            <NavLink exact
                key={`/community/architecture-design`} 
                to={`/community/architecture-design`} 
                className={`button-link architecture-design`}
                activeClassName="selected"
            >Arch. & Design</NavLink>
            <NavLink exact
                key={`/community/art`} 
                to={`/community/art`} 
                className={`button-link art`}
                activeClassName="selected"
            >Art</NavLink>
            <NavLink exact
                key={`/community/photo`} 
                to={`/community/photo`} 
                className={`button-link photo`}
                activeClassName="selected"
            >Photo</NavLink>
            <NavLink exact
                key={`/community/shops`} 
                to={`/community/shops`} 
                className={`button-link shops`}
                activeClassName="selected"
            >Shops</NavLink>       
        </div> 
    )

    // {categoryList.map(category => (
    //     <NavLink exact
    //         key={`/community/${category.slug}`} 
    //         to={`/community/${category.slug}`} 
    //         className={`button-link ${category.slug}`}
    //         activeClassName="selected"
    //     >
    //         {category.navTitle || category.title}
    //     </NavLink>
    // ))}
};

export default CommunityMenu;
