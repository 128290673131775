import React, { useState, useEffect, useRef, memo } from 'react';
import DinamoIcon from "@cargo/common/icons/dinamo-inside.svg";

export const AnimatedDinamoIcon = ({ animate }) => {

    useEffect(() => {
        if (animate === true ) {
            document.querySelector('svg#dinamo-inside #dinamo').classList.add('animated', 'animated-delayed-1');
            document.querySelector('svg#dinamo-inside #inside').classList.add('animated', 'animated-delayed-2');
            document.querySelector('svg#dinamo-inside #circle').classList.add('animated', 'animated-delayed-3');
        }
    },[]);

	return (
		<DinamoIcon />
	)
}