import { actionTypes } from "../actions";
import { combineReducers } from 'redux';

import _ from "lodash";

const communityState = {
	paginationComplete: false,
	offset: 0,
	periods: []
}

export default function(state = communityState, action){

	if( !action.payload ) {
		return state;
	}

	switch(action.type) {

		case actionTypes.FETCH_COMMUNITY_PERIODS_FULFILLED:

			const newPeriods = action.payload.data;

			const checkEmptyDataObjects = (array) => {
				return array.some(item => Object.values(item.data).every(arr => Array.isArray(arr) && arr.length === 0));
			};

			const allPaginationResultsAreEmpty = checkEmptyDataObjects(newPeriods);

			return {
				paginationComplete: newPeriods.length < action.meta.amount || allPaginationResultsAreEmpty,
				offset: state.offset + newPeriods.length,
				periods: [
					...state.periods,
					...newPeriods
				]
			}

			break;

	}

	return state;

};