import React, { useState, useEffect, useRef, memo } from 'react';
import DuplicatingIcon from "@cargo/common/icons/duplicating.svg";

import { connect } from 'react-redux';
import { FlipperButton } from './flipper-button';
import { isServer } from "@cargo/common/helpers";

const imageArray = [
	"https://static.cargo.site/assets/home/jtmrevue.com_.jpg",
	"https://static.cargo.site/assets/home/francescahummler.com_.jpg",
	"https://static.cargo.site/assets/home/willadler.com.jpg",
	"https://static.cargo.site/assets/home/rufusstott.com.jpg",
	"https://static.cargo.site/assets/home/2-whyfoundry.com-2.jpg",
	"https://static.cargo.site/assets/home/3-pablomanrique.cargo.site.jpg",
	"https://static.cargo.site/assets/home/marquelwilliams.com.jpg",
	"https://static.cargo.site/assets/home/screenshot-2968355374.jpg",
	"https://static.cargo.site/assets/home/technicaluse.com.jpg",
	"https://static.cargo.site/assets/home/ourspecialsupport.com-6.jpg",
	"https://static.cargo.site/assets/home/party-001.com-4.jpg",
	"https://static.cargo.site/assets/home/edouardfargues.eu_45.jpg",
	"https://static.cargo.site/assets/home/paulspendier.xyz-2.jpg",
	"https://static.cargo.site/assets/home/esther.ee.jpg",
	"https://static.cargo.site/assets/home/sumnerstudio.info-222.jpg",
	"https://static.cargo.site/assets/home/paolasirirenard.com_.jpg",
	"https://static.cargo.site/assets/home/josefinarnell.se.jpg",
	"https://static.cargo.site/assets/home/serenagramaglia.com-4.jpg",
	"https://static.cargo.site/assets/home/programm.works.jpg",
	"https://static.cargo.site/assets/home/francescagrassi-info.jpg",
	"https://static.cargo.site/assets/home/dongyounglee.com.jpg",
	"https://static.cargo.site/assets/home/natalietauger.com.jpg"
];

const preloadImage = (url) => {

	return new Promise((resolve, reject) => {

		const img = new Image();
		img.src = url;

		img.onload = resolve;
		img.onerror = reject;

	})
}

const Flipper = ({authenticated, isMobile, darkMode, inlineOpacity}) => {

	const [visibleIndex, setVisibleIndex] = useState(0);
	const [isFlipperPreloaded, setIsFlipperPreloaded] = useState(false);
	const [isWaitingForImageRasterization, setIsWaitingForImageRasterization] = useState(false);

	const [mousePos, setMousePos] = useState({
		x: 0,
		y: 0
	});

	const [windowDimensions, setWindowDimensions] = useState({
		width: isServer ? 1920 : window.innerWidth,
		height: isServer ? 1080 : window.innerHeight
	})

	const onMouseMove = e => {
		setMousePos({
			x: e.clientX,
			y: e.clientY
		});
	}

	const onWindowResize = e => {
		setWindowDimensions({
			width: window.innerWidth,
			height: window.innerHeight
		})
	}

	useEffect(() => {

		const preloadAmount = 3;
		const promises = imageArray.slice(0, preloadAmount).map(preloadImage);
		window.addEventListener('mousemove', onMouseMove);
		window.addEventListener('resize', onWindowResize);

		Promise.allSettled(promises).then(() => {
			setIsFlipperPreloaded(true);
		})

		return () => {
			window.removeEventListener('mousemove', onMouseMove);
			window.removeEventListener('resize', onWindowResize);
		}

	}, []);

	const windowCenterX = (windowDimensions.width / 2);
	const windowCenterY = (windowDimensions.height / 2);

	const distanceX = windowCenterX - mousePos.x;
	const distanceY = windowCenterY - mousePos.y;

	let ghostX = windowCenterX + distanceX;
	let ghostY = windowCenterY + distanceY;

	const distanceToCenter = Math.sqrt( distanceX*distanceX + distanceY*distanceY );
	
	const flipperButtonRadius = 20;
	const cursorRadius = 18;
	const cursorFill = !darkMode ? 'black' : 'white';

	const activateFlipper = !isMobile && distanceToCenter < flipperButtonRadius;

	// only use the custom cursor effect when not hovering the top menu bar
	// and when not on mobile
	const useCustomCursor = !isMobile && mousePos.y > 40;

	useEffect(() => {
		let intervalId;
	
		if (activateFlipper) {

			if(!isWaitingForImageRasterization) {
				setIsWaitingForImageRasterization(true);

				// wait a moment before showing the flipper so the browser can rasterize the image
				setTimeout(() => {
					setIsWaitingForImageRasterization(false);
				}, 30);

			}

			intervalId = setInterval(() => {
				setVisibleIndex(prevIndex => {
					return (prevIndex + 1) % imageArray.length;
				});
			}, 300);
		}
	
		return () => clearInterval(intervalId);
	}, [activateFlipper]);

	let cursor = 'inherit';

	if(useCustomCursor) {
		cursor = `url('data:image/svg+xml;utf8,<svg width="${cursorRadius * 2}" height="${cursorRadius * 2}" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="40" height="40" fill="transparent"></rect><path fill-rule="evenodd" clip-rule="evenodd" d="M19.4004 20.5984V31H19.4508H20.5508H20.6004V20.5984H31V20.55V19.45V19.3984H20.6004V9H20.5508H19.4508H19.4004V19.3984H9V19.45V20.55V20.5984H19.4004Z" fill="${cursorFill}" fill-opacity="0.85"></path></svg>') ${cursorRadius} ${cursorRadius}, auto`;
	}

	if(activateFlipper) {
		cursor = 'pointer';
	}

	return (<>
		
		{useCustomCursor && !activateFlipper && <div className="flipper-cursor-ghost" style={{left: (ghostX - cursorRadius), top: (ghostY - cursorRadius)}}>
			<DuplicatingIcon />
		</div>}

		<style>{`body {
			cursor: ${cursor};
		}`}</style>

		{isFlipperPreloaded && activateFlipper && <><div className="background-flipper">

			{!isWaitingForImageRasterization && <style>{`body[data-route="home"] #manager, #home-page::before, [static-page] .background { background: black !important; }`}</style>}

			{imageArray.map((image, index) => {
				return (
					<div className="item" key={index}>
						{/* <BrowserBar displayURL={'thewebsite.com'} /> */}
						<div className="site-img"
							// src={`${image}`} 
							style={{ 
								visibility: !isWaitingForImageRasterization && index === visibleIndex ? 'visible' : 'hidden', 
								backgroundImage: `url(${image})`,
							}}
						/>
					</div>
				)
			})}

		</div>

		<FlipperButton inlineOpacity={inlineOpacity} />
		</>
		}

		{isMobile && <FlipperButton isMobile={true} inlineOpacity={inlineOpacity} />}

	</>);
}


function mapReduxStateToProps(state, ownProps) {
    return {
        authenticated: state.auth.authenticated,
		isMobile: state.homepageState.isMobile,
		darkMode: state.homepageState.darkMode
    };
}

export default connect(
	mapReduxStateToProps
)(Flipper);