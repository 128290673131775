import { actionTypes } from "../actions";
import { combineReducers } from 'redux';
import { deviceType } from "@cargo/common/helpers";
import _ from "lodash";

const defaultHomepageState = {
	currentSearchTerm: null,
	hasFolders: false,
	hasTemplates: false,
	loadingTemplates: false,
	renderedFolder: null,
	hasAccount: false,
	isMobile: false,
	darkMode: false,
	hasUserMeta: false,
	trashFetched: false,
	siteQueuedForDuplication: null,
	queryStringDuplicateId: null,
	suppressLoginForm: false,
	requireLogin: false,
	gridSystemEnabled: false,
	fontsLoaded: [],
	ratesPageInterval: 'yearly',
	deviceType: deviceType()
}

export default function(state = defaultHomepageState, action){

	if(!action.payload) {
		return state;
	}

	switch(action.type) {

		case actionTypes.UPDATE_HOMEPAGE_STATE: 

			if(action.payload.requireLogin === false) {
				// Automatically reset `canCreateNewAccount` when closing login
				action.payload.loginCanCreateNewAccount = false;
			}

			return _.assign({}, defaultHomepageState, state, action.payload);

		case actionTypes.FETCH_TEMPLATES_PENDING: 

			return {
				...state,
				loadingTemplates: true
			}

		case actionTypes.FETCH_TEMPLATES_FULFILLED: 

			return {
				...state,
				hasTemplates: true,
				loadingTemplates: false
			}

		case actionTypes.FETCH_FOLDERS_FULFILLED: 

			return {
				...state,
				hasFolders: true
			}
			
		case actionTypes.FETCH_DELETED_SITES_FULFILLED: 

			return {
				...state,
				trashFetched: true
			}

		case actionTypes.FETCH_ACCOUNT_FULFILLED:
			const newState = {
				...state,
				hasAccount: true
			}

			if(action.payload.data?.folders) {
				newState.hasFolders = true;
			}

			return newState;

		case actionTypes.UPDATE_USER_META_FULFILLED: 

			const newState1 = {
				...state,
				hasUserMeta: true
			}

			return newState1;


		case actionTypes.LOGOUT_USER_FULFILLED:

			if(action.meta?.silent) {
				// do not not anything so the UI doesn't shift. We do this when logging out
				// through the context menu as layout will shift while we await the browser reload
				return state;
			}

			return {
				...state,
				hasAccount: false,
				hasFolders: false,
				trashFetched: false
			}

		case actionTypes.FETCH_LEGACY_NEWSLETTER_FULFILLED:

			return {
				...state,
				newsletter: {
					edition: action.meta.edition,
					html: action.payload.data.content
				}
			}

	}

	return state 

};
