export const EnvBoolean = (unknownBoolean: any): boolean => {
  if (typeof unknownBoolean === 'boolean') {
    return Boolean(unknownBoolean)
  }

  try {
    const stringBoolean = String(unknownBoolean).toLowerCase().trim()
    switch (stringBoolean) {
    case 'false':
    case 'no':
    case '0':
    case '':
    case 'undefined':
    case 'null':
      return false
    default:
      return true
    }
  } catch(error) {
    // This could only be an Object failing to convert to string or a Symbol,
    // and since JavaScript loves truthy as default we'll let that pass through.
  }

  return true
}

export const isSSR = typeof window === 'undefined';

/**
 * Removes object entries where the value equals to `undefined`
 *
 * @param obj
 */
export const removeUndefined = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object') removeUndefined(obj[key]);
    else if (obj[key] === undefined) delete obj[key];
  });
  return obj;
};
