import { actionTypes } from "../actions";
import { TemplateGroups } from "../components/templates/templates-config";
import _ from "lodash";

let isDev = CARGO_ENV !== 'production';

let ammendTemplateFolders = ( folders ) => {

	const DLRowsArray = TemplateGroups.reduce((acc, obj) => {
		return acc.concat(obj.rows);
	}, []);

	return folders.map(folder => {

		const sites = folder.sites;
		// TemplateGroups is a static data file that defines the sections and rows of the design lab.
		// This is used to determine section title, description and color. Relevant to the SectionHeader component.
		const section = TemplateGroups.find(
			(section) => { 
				return section.rows.some(
					(row) => { 
						return ( isDev ? row.dev : row.live) === folder.id 
					})
			});
		// Once we know what section a folder belongs to, we can find the row it belongs to.
		// row defines the title, description and label. Relevant to the SectionHeader component.
		const row = section?.rows.find((row)=> (isDev ? row.dev : row.live) === folder.id);

		sites.map(site => {
			// Cleanup after API change and other "can_copy"/"can_duplicate" logic goes up.
			const is_coming_soon = site.website_title?.toLowerCase().match('coming soon') !== null ? true : false;

			site.is_coming_soon = is_coming_soon;
			
		});

		sites.sort((a, b) => a.sort - b.sort);

		const isIdeaFolder     = row ? folder.name.toLowerCase().match('idea') !== null || row.isIdeaFolder ? true : false : false;
		const isElementFolder  = row ? folder.name.match('Element') || DLRowsArray.some( row => ( row.live === folder.id || row.dev === folder.id ) && row.isElementFolder ) : false;
		const elementType      = isElementFolder ? DLRowsArray.find(row => (row.live === folder.id || row.dev === folder.id) && row.isElementFolder)?.elementType ?? null : null;

		// Return data using helpers for folder type etc...
		return {
			...folder,
			section: section,
			row: row,
			key: null,
			name: folder.name.replace(/Elements: /, '').replace(/Ideas: /, '').replace(/Community: /, ''),
			sites: sites,
			is_idea: isIdeaFolder,
			is_element: isElementFolder,
			element_type: elementType,
			is_community: folder.name.match('Community:') !== null,
			is_template_folder: true,
			pagination: 1,
			fullyPaginated: false,
		}
	})
}

export default function(state = [], action) {

	if(!action.payload && !action.meta) {
		return state;
	}

	switch(action.type) {

		case actionTypes.FETCH_TEMPLATES_FULFILLED: 

			let ammendedFolders = ammendTemplateFolders( action.payload.data )

			return [...ammendedFolders]

		break;

		case actionTypes.UPDATE_FOLDER_PENDING: 

			if( action.meta ){

				const updatedFolder = action.meta.folder;

				// Find site obj in state
				let existingFolderIndex = state.findIndex(folder => folder.id === updatedFolder.id );

				if( existingFolderIndex === -1 ){
					return state
				}

				// Duplicate state
				let newState = [ ...state];

				newState[existingFolderIndex] = _.cloneDeep(updatedFolder)

				return newState
			}

		break;

		case actionTypes.UPDATE_INUSE_FOLDER_FULFILLED:

			if(action.payload.data) {
				// To avoid complications, we refetch the data entirely.
				// This is because template folders undergo processing in ammendTemplateFolders.
				// This is only acceptable because this action is a part of an admin tool and not a user-facing feature.
				let type = action.meta.type;

				if( type === 'add' ){

				}

				if( type === 'remove' ){

				}

				return state 
			}

		break;

		case actionTypes.UPDATE_FOLDER_FULFILLED: 

			if(action.payload.data) {

				let existingFolderIndex = state.findIndex((item) => item.id === action.payload.data.id );
				
				if( existingFolderIndex === -1 ){
					return state
				}

				let newState = [ ...state];

				newState[existingFolderIndex] = action.payload.data;

				return newState

			}

		break;

		case actionTypes.PAGINATE_TEMPLATE_SITES_FULFILLED:

			if( action.payload.data ) {
				// update state here
				let newState = [ ...state ]
				let responseData = action.payload.data;
				let sites = responseData.sites;

				let existingTemplateFolderIndex = newState.findIndex(folder => folder.id === action.meta.folder.id );
				 
				if( existingTemplateFolderIndex === -1 ){
					return state
				}

				const templateFolder = action.meta.folder;

				// add sites data to existing folder...
				if (sites.length === 0) {
					templateFolder.fullyPaginated = true;
				} else {
					let folderSites = [...templateFolder.sites];

					for (const site of sites) {
						if (!folderSites.some((templateSite) => templateSite.id === site.id)) {
							folderSites.push(site);
						}
					}

					folderSites.sort((a, b) => a.sort - b.sort);

					templateFolder.sites = folderSites;

					templateFolder.pagination += 1;
				}

				newState[existingTemplateFolderIndex] = _.cloneDeep(templateFolder);

				return newState
			}

		break;

		case actionTypes.FETCH_TEMPLATE_SITE_FULFILLED:

			if( action.payload.data ){

				let newState = [ ...state ];
				let site = { ...action.payload.data.sites[0] };

				let existingTemplateFolderIndex = newState.findIndex(folder => folder.id === action.meta.folder.id );

				if( existingTemplateFolderIndex === -1 ){
					return state
				}

				const templateFolder = action.meta.folder;

				let folderSites = [...templateFolder.sites];

				if (!folderSites.some((templateSite) => templateSite.id === site.id)) {
					folderSites.push(site);
				}

				folderSites.sort((a, b) => a.sort - b.sort);

				templateFolder.sites = folderSites;

				newState[ existingTemplateFolderIndex ] = _.cloneDeep( templateFolder );

				return newState
			}

		break;

	}

	return state;

}
