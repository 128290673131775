import React, { useRef, useEffect, useState } from 'react';
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Folder from "../folder";

export default function SortableFolder({ item, id, isDisabled, isGhost, isOver, index, activeIndex, overIndex }) {
    const {
        attributes,
        listeners,
        isDragging,
        setNodeRef,
        transform,
        transition,
    } = useSortable({
        id,
        animateLayoutChanges: () => false,
        disabled: isDisabled,
    });

    const [height, setHeight] = useState(0);
    const elementRef = useRef(null);

    useEffect(() => {
        if ( elementRef.current && height === 0 ) {
            setHeight(elementRef.current.getBoundingClientRect().height);
        }
    }, [elementRef.current]);

    let adjustedTransform = transform;

    if (activeIndex !== -1 && activeIndex !== null && activeIndex !== index) {
        const movingDown = index > activeIndex && index <= overIndex;
        const movingUp = index < activeIndex && index >= overIndex;

        if (movingDown) {
            adjustedTransform = { ...transform, y: -(height) };
        } else if (movingUp) {
            adjustedTransform = { ...transform, y: height };
        }
    } else if ( !isDragging ) {
        adjustedTransform = { ...transform, scaleX: 1, scaleY: 1, x: 0, y: 0 };
    }

    const style = {
        transform: CSS.Translate.toString(adjustedTransform),
        transition,
        position: 'relative',
        zIndex: isDragging ? 999 : 'auto',
    };

    // if( transform && transform.y > 4 ){
    //     debugger
    // }

    return (
        <div
            className={`${isGhost ? 'ghost' : ''}`}
            ref={(node) => {
                setNodeRef(node);
                elementRef.current = node;
            }}
            style={style}
            {...attributes}
            {...listeners}
        >
            <Folder
                isDragging={isDragging}
                isDisabled={isDisabled}
                item={item}
                key={`item-${id}`}
                id={id}
            />
        </div>
    );
}