import React, {useState, useEffect, useRef} from 'react';
import { useSelector } from 'react-redux'
import { useIntercom } from 'react-use-intercom';
import _ from 'lodash';
import { Button } from "@cargo/ui-kit/button/button";
import IntercomIconLeft from "@cargo/common/icons/intercom-launcher-left.svg";

let intercomLoadTimeout;
let onIntercomLoadError = null;
let intercomLoaded = false;

export const IntercomButton = ({location, sentry, Alert, type, side}) => {

    const { boot, shutdown, hide, show, update, isOpen } = useIntercom();
    const [ hasUnreadMessages, setHasUnreadMessages ] = useState(false);
    const state = useSelector((state) => state);

    const userData = useSelector((state) => state?.user ? state.user : state.account );
    const hasAnnexUpgrade = useSelector((state) => state?.sites ? state.sites.find(site => site.has_annex_upgrade === true) : state.site.has_annex_upgrade );

    if (!userData) return null

	useEffect(() => {
		if (!userData) return;

        const link = document.querySelector('#intercom-launcher');

        Intercom('onUnreadCountChange', function(unreadCount) {

            if(location === 'c3-admin'){
                setHasUnreadMessages(unreadCount > 0);
            }

            if(!intercomLoaded) {
                intercomLoaded = true;
                console.log('[Intercom] Loaded successfully');
            }

        });

        window.Intercom.beforeInit = ([method, args]) => {
            if(method === 'showArticle' || method === 'showNewMessage' || method === "show") {
                // Intercom has not loaded yet...
                onIntercomLoadError?.();
            }
        }

        // const iconColor = location === 'c3-admin' ? 'blue' : 'orange';
        // const headerColor = location === 'c3-admin' ? 'red' : 'orange';
        const horizontalPadding =   location === 'u.cargo' ? 30 : window.innerWidth  - link.getBoundingClientRect().x + 17;
        const verticalPadding   =   location === 'u.cargo' ? 30 : window.innerHeight - link.getBoundingClientRect().y - 100;
        const displayName = userData.full_name && userData.full_name.length > 0 ? userData.full_name : userData.email;
        boot({
            // actionColor: iconColor,
            // backgroundColor: headerColor,
            customLauncherSelector: '#intercom-launcher',
            horizontalPadding: horizontalPadding,
            verticalPadding  : verticalPadding,
            hideDefaultLauncher: true,
			name: displayName,
			email: userData.email,
            customAttributes:{
                user: 'https://admin.cargo.site/accountdetail/'+userData.id,
                errors:'https://cargo3.sentry.io/issues/?project=4504992645578752&query=user.id%3A'+userData.id,
                has_subsription: userData.has_subscription,
                annexed: hasAnnexUpgrade,
                version: 'C3'
            }
        });

	}, [userData])

    useEffect(() => {

        onIntercomLoadError = () => {

            console.log('[Intercom] Attempt to interact with support widget failed');

            if(sentry) {
                sentry.captureMessage("[Intercom] Attempt to interact with support widget failed");
            }

            if(Alert) {
                Alert.openModal?.({
                    htmlHeader: `It appears that Cargo Support cannot load.<br />This might be due to an ad blocker in your browser. Try disabling it, or get in touch via <a style='color: #222222; text-decoration: underline;' href="mailto:support@cargo.site">support@cargo.site</a>`, 
                    type: 'notice'
                });
            }

        }

    }, [Alert?.openModal])

    useEffect(() => {
        if(intercomLoaded) {
            window.dispatchEvent(
                new CustomEvent('intercom-loaded', {
                    detail: { intercomLoaded: true }
                })
            );
        }
        
    },[intercomLoaded])

    if( type === 'text' ){
        return (
            <Button
                label="Contact"
                onClick={(e)=>{ Intercom('showNewMessage'); }}
            />
        )
    }
    return (
        <Button 
            icon={ <IntercomIconLeft /> }
            id="intercom-launcher"
            onPointerDown={(e)=>{
                window.dispatchEvent(
                    new CustomEvent('intercom-loaded', {
                        detail: { intercomLoaded: true }
                    })
                );
            }}
            onClick={() => {
                if(!intercomLoaded) {
                    // clicking the intercom help button but we are not booted yet
                    onIntercomLoadError?.();
                }
            }}
            tooltip="Help"
            className={`${isOpen ? 'popover-open ' : ''}${hasUnreadMessages ? 'has-unread-messages' : ''}${location === 'u.cargo' ? ' get-help' : ''}${type ? ' '+type : ''}`}
        />
    )

};
