import React, { useContext } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actions } from "../actions";
import { Button } from '@cargo/ui-kit';
import { AnimatedElement, fadeInGlideUp1, fadeInGlideUp3, useAnimationPath } from "@cargo/common/animation-context";
import{ SiteDuplicationContext } from './site-duplication-provider';


const LoginMenu = ({ layeredRouter, isMobile }) => {

    // For triggering the text reveal fade animation on page load
    const initialLocation = layeredRouter.getInitialRouteOnLoad();
	const shouldApplyInitialStyles = useAnimationPath('fade', '/', true, initialLocation.pathname);
    const { loginFromContext } = useContext(SiteDuplicationContext);

    return (
        <div id="login-menu">
            {!isMobile ? (
                <Button 
                    className="start"
                    onClick={()=>{ loginFromContext({canCreateNewAccount: true}) }}
                >
                    <AnimatedElement
                        as="span"
                        animation={fadeInGlideUp3}
                        animationKey="fade"
                        applyInitialStyles={shouldApplyInitialStyles}
                    >
                        Start
                    </AnimatedElement>
                </Button>   
            ) : null}
                        
            <Button 
                className="login" 
                onClick={e => {
                    loginFromContext();
                }}
            >
               <AnimatedElement
                    as="span"
                    animation={fadeInGlideUp3}
                    animationKey="fade"
                    applyInitialStyles={shouldApplyInitialStyles}
                >
                    Login ↗
                </AnimatedElement>
            </Button>
        </div>
    );
};

function mapReduxStateToProps(state, ownProps) {
    return {
        isMobile: state.homepageState.isMobile
    };
}

export default withRouter(connect(
    mapReduxStateToProps, 
)(LoginMenu));