const RngEnvironment = Object.freeze({
  Browser: Symbol('RngEnvironmentBrowser'),
  NodeJs: Symbol('RngEnvironmentNodeJs'),
})

export class Rng {
  constructor(rngEnvironment) {
    this.environment = rngEnvironment
    this.crypto = undefined
  }

  static async createByEnvironment() {
    if (typeof window !== 'undefined' && typeof window?.crypto?.getRandomValues === 'function') {
      return new Rng(RngEnvironment.Browser)
    }

    try {
      const rng = new Rng(RngEnvironment.NodeJs)
      rng.crypto = await import('crypto')
      if (typeof rng.crypto?.randomFillSync === 'function') {
        return rng
      }
    } catch(error) {
      console.log('RNG??')
      console.log(error)
      throw error
    }
  }

  fill(uint8Array) {
    if (this.environment === RngEnvironment.Browser) {
      window.crypto.getRandomValues(uint8Array)
      return
    }

    if (this.environment === RngEnvironment.NodeJs) {
      this.crypto.randomFillSync(uint8Array)
      return
    }

    throw new Error('RNG failed to run')
  }
}
