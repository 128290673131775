import React from 'react';
import _ from 'lodash'
import { NavLink } from 'react-router-dom';
import DuplicatingIcon from "@cargo/common/icons/duplicating.svg";
import DownwardsArrow from "@cargo/common/icons/downwards-arrow.svg";

export const FlipperButton = ({ isMobile, onClick = () => {}, onMouseEnter, onMouseLeave, inlineOpacity }) => {

    const handleMobileMouseUp = (e) => {
        e.preventDefault();
        const scrollAmount = window.innerHeight; // Adjust this value as needed
        window.scrollBy({
            top: scrollAmount,
            behavior: 'smooth'
        });
        onClick();
        onMouseLeave();
    };

    return(
        <div className="flipper-button" style={{opacity: inlineOpacity}}>
            {isMobile ? (
                <NavLink 
                    className={`button-link home-start-link`} 
                    to={'/'} 
                    exact
                    onClick={handleMobileMouseUp}
                    onTouchEnd={handleMobileMouseUp}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                >
                    <DownwardsArrow />
                </NavLink>
            ) : (
                <NavLink 
                    className={`button-link home-start-link dark`} 
                    to={'/templates'} 
                    exact
                    onClick={onClick}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                >
                    <DuplicatingIcon/>
                </NavLink>
            )}
        </div>
    )
}
