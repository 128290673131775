import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from "../actions";
import { frontloadConnect } from '@cargo/react-frontload';

const frontload = async props => { 

	const frontloadPromises = [];

	frontloadPromises.push( 
		props.fetchLegacyNewsletterEdition(props.edition)
	);

	await Promise.allSettled(frontloadPromises);

}

const NewsletterProxy = ({edition, newsletterHTML}) => {

	const editionAsInt = parseInt(edition);

	const wrapperRef = useRef(null)
	const bodycopyRef = useRef(null)

	if(!newsletterHTML) {
		newsletterHTML = '';
	}

	newsletterHTML = newsletterHTML.replaceAll('data-src', 'src')

	const indicateLegacyPosts = function(context) {

		if (editionAsInt <= 100) {
			bodycopyRef.current.classList.add('legacy');

			var legacyHeader = wrapperRef.current.querySelectorAll('.site_header');

			_.each(legacyHeader, function(header) {
				header.innerHTML = `<div class="header_buttons"><div></div><div></div><div></div></div>`;
				header.closest('.image-link')?.classList.add('pad-bottom-trick');
			});

			var inUseLinks = Array.from(wrapperRef.current.querySelectorAll('[data-in-use-grid] a.image-link'));

			inUseLinks.forEach(function(link){
				$(this).wrapInner('<div class="border-wrapper">')
			});
			
		} else if (editionAsInt <= 126 && editionAsInt > 100) {

			bodycopyRef.current.classList.add('new')

			var img = wrapperRef.current.querySelectorAll('img')[0];

			if (img) {
				var source = img.getAttribute('src');
				if (source !== undefined && source.match('blue-bolt')) {
					if(!img.closest('.bolt-wrapper')) {
						const wrapper = document.createElement('div');
						wrapper.className = 'bolt-wrapper';

						img.parentNode.insertBefore(wrapper, img);
						wrapper.appendChild(img);
					}
				}
			}

		}

		console.log( editionAsInt )
		
		if( editionAsInt > 126 && editionAsInt < 161 ) {
			wrapperRef.current.classList.add('blog-ver-one')
		}

		if( editionAsInt > 160 ) {
			wrapperRef.current.classList.add('blog-ver-two')
		}

	}

	const reBorderStaffPick = function(context) {

		if (editionAsInt == 5 || editionAsInt == 6 || editionAsInt == 7 || editionAsInt == 9 ) {

			let staff_picks = wrapperRef.current.querySelectorAll('[data-staff-pick-image] a.half-width.image-link.pad-bottom-trick');
			
			if(staff_picks.length == 0 || staff_picks.length != 1 ){
				staff_picks = wrapperRef.current.querySelectorAll('[data-featured-in-use] a.half-width.image-link.pad-bottom-trick');
			}

			Array.from(staff_picks).forEach(pick => {
				console.log(pick)
				pick.removeAttribute('style');
				pick.setAttribute("legacy-border-special", "");
			})

		}

	}

	const removeDoubleBorder = function(context) {

		if(editionAsInt <= 55){

			var double_attr_nodes = Array.from(wrapperRef.current.querySelectorAll('[data-featured-in-use][data-in-use-grid]'));

			if(double_attr_nodes.length >= 1){

				double_attr_nodes.forEach(node => {
					node.removeAttribute('data-in-use-grid');
				})

			}
		}

	}

	const wrapStaffPick = function(context){

		if(editionAsInt <= 100){

			var existingWrapper = wrapperRef.current.querySelector('.staff-pick-wrapper');
			
			if (existingWrapper) {
				return
			}

			var el = wrapperRef.current.querySelector('[data-featured-in-use] a.half-width.pad-bottom-trick.image-link');

			var wrapper = document.createElement('div');
			wrapper.className = 'staff-pick-wrapper'

			if (!el) {
				el = wrapperRef.current.querySelector('[data-staff-pick-image] a.half-width.pad-bottom-trick.image-link');
			}

			if (el) {
				el.parentNode.insertBefore(wrapper, el);
				wrapper.appendChild(el);
			}
			
		} else {

			var updates_img = Array.from(wrapperRef.current.querySelectorAll('[data-staff-pick-image] img[data-mid]'));

			_.each(updates_img, function(image){
				if (image.width/image.height == 1.6) {
					image.closest('.image-link')?.classList.add('new_ratio');
				}
			})
			
			
		}
	}

	useEffect(() => {
		indicateLegacyPosts();
		reBorderStaffPick();
		removeDoubleBorder();
		wrapStaffPick();
	});

	return <>
		<link href="https://cargo.site/type/css?family=NeueHaasGrotesk:n4|Diatype:n2,i2,n3,i3,n4,i4,n5,i5,n7,i7" rel="stylesheet"/>
		<link href="https://s3.amazonaws.com/static.cargocollective.com/assets/homepagenew/homepage.css?v=30" rel="stylesheet" />
		<link href="https://cargo.site/_jsapps/api/_css/global.css" rel="stylesheet" />
		<link href="https://cargo.site/stylesheet?c=2670716374" rel="stylesheet" />

		<style dangerouslySetInnerHTML={{__html: `
		img[data-mid] {
			max-width: 100%;
			height: auto;
		}
		`}}></style>
		<div data-predefined-style="true" ref={wrapperRef}>
			<div className="blog">
				<div className="page_container" data-container="page" ref={bodycopyRef}>
					<bodycopy is="bodycopy" class="bodycopy content content_padding">
						<div className="page_content clearfix" dangerouslySetInnerHTML={{__html: newsletterHTML}}></div>
					</bodycopy>
				</div>
			</div>
		</div>
	</>

}

function mapReduxStateToProps(state, ownProps) {

	let newsletterHTML = null;

	// make sure we only grab the HTML for the correct newsletter edition
	if(state.homepageState.newsletter?.edition === ownProps.edition) {
		newsletterHTML = state.homepageState.newsletter.html
	}

	return {
		newsletterHTML
	};

}

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		fetchLegacyNewsletterEdition: actions.fetchLegacyNewsletterEdition
	}, dispatch);

}


export default connect(
	mapReduxStateToProps,
	mapDispatchToProps 
)(
	frontloadConnect(frontload, {
		onMount: true,
		onUpdate: false
	})(
		NewsletterProxy
	)
);