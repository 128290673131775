import React, { createContext, useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { actions } from '../actions';
import { __RouterContext } from "react-router";
import { useHistory, useParams } from 'react-router-dom';

import _ from 'lodash';

export const SiteDuplicationContext = createContext();

const SiteDuplicationProvider = ({ children }) => {

	const dispatch = useDispatch();
	const history = useHistory();
	const routerContext = useContext(__RouterContext);

	// Get URL parameters
	const params = useParams() || {};
	const activeFolderSlug = params.folder || null;
	// Redux State App state
	const siteQueuedForDuplication = useSelector((state) => state.homepageState.siteQueuedForDuplication);
	const currentSearchTerm = useSelector((state) => state.homepageState.currentSearchTerm);
	// Redux State Data
	const user = useSelector((state) => state.account || {});
	const authenticated = useSelector((state) => state?.auth?.authenticated || false);
	const sites = useSelector((state) => state.sites || []);
	const templateSites = useSelector((state) => state.templates ? state.templates.flatMap(template => template.sites) : [] );
	const folders = useSelector((state) => state.folders || []);
	const savedFolder = folders && user ? _.find(folders, (folder) => folder.id === user.saved_folder_id) : null;
	const sitesCount = useSelector((state) => state.sites?.length || 0);

	const baseRoute = routerContext.layeredRouter.layerStack[0];
	// Determine the folder to duplicate the site into...
	const forceAllFolder = ( authenticated && sitesCount > 0 && folders.some((folder) => folder.slug === 'all') && currentSearchTerm?.length > 0 ) || savedFolder?.slug === activeFolderSlug;
	const folderSlugToFind = forceAllFolder ? 'all' : activeFolderSlug || 'all';
	const currentFolder = _.find(folders, (folder) => folder.slug === folderSlugToFind) || null;
	// Determine if we should forward to the "/" route during the duplication process.
	const shouldForwardBeforeDupe = authenticated === true && !_.isEmpty(folders) && !_.isEmpty(user) ? !baseRoute.routeInfo.isUserFolder || savedFolder?.id === currentFolder?.id || savedFolder?.slug === activeFolderSlug : true;
	
	// Actions
	const setDuplicatingSiteId = (id) => dispatch(actions.setDuplicatingSiteId(id));
	const duplicateSite = (siteID, folder, insertBeforeThisSiteId, offset, withoutModel) => dispatch(actions.duplicateSite(siteID, folder, insertBeforeThisSiteId, offset, withoutModel));
	const syncAfterSiteDuplication = (folder, res) => dispatch(actions.syncAfterSiteDuplication(folder, res));
	const updateHomepageState = (state) => dispatch(actions.updateHomepageState(state));
	const fetchAccount = () => dispatch(actions.fetchAccount());

	const [loginTriggered, setLoginTriggered] = useState(false);

	// Event listeners for duplication events
	useEffect(() => {
		// window.addEventListener('duplicate-site', startSiteDuplicationFromEvent);
		window.addEventListener('duplicate-site-from-query', startSiteDuplicationFromEvent)

		return () => {
			// window.removeEventListener('duplicate-site', startSiteDuplicationFromEvent);
			window.removeEventListener('duplicate-site-from-query', startSiteDuplicationFromEvent)

		};
	}, [authenticated]);

	const loginFromContext = async (options = {}) => {
		await updateHomepageState({
			requireLogin: true,
			loginCanCreateNewAccount: options.canCreateNewAccount || false,
		});

		setLoginTriggered(true);
	};

	// Watch for login completion
	useEffect(() => {
		if (loginTriggered && authenticated) {
			if (siteQueuedForDuplication) {
				// Fetch account data
				fetchAccount().then(() => {
					handleSiteDuplication(siteQueuedForDuplication);
					setLoginTriggered(false);
				});
			} else {
				// Fetch account data
				fetchAccount().finally(() => {
					// Remove login form.
					setLoginTriggered(false);
					document.dispatchEvent(new CustomEvent('remove-login-form'));
				});
			}

		}
	}, [loginTriggered, authenticated]);

	const startSiteDuplicationFromEvent = (e) => {
		const siteId = e.detail.siteId;
		handleSiteDuplication(siteId);
	}

	const handleSiteDuplication = ( siteId, folderId = null ) => {

		if (!authenticated) {
			updateHomepageState({ siteQueuedForDuplication: siteId });

			setTimeout(() => {
				loginFromContext();
			}, 1);

		} else {

			routerContext.layeredRouter.closeOverlay();

			const insertBeforeThisSiteId = !shouldForwardBeforeDupe ? siteId : null;

			requestAnimationFrame(() => {
				// We used to set timeout here if you were logging in for a full second.
				// We may be missing data. This could have caused a race condition.
				duplicateCargoSite(siteId, insertBeforeThisSiteId, 1, folderId);

				requestAnimationFrame(() => {
					document.dispatchEvent(new CustomEvent('remove-login-form'));
				});
			});
		}

	}

	// site_id, [[folder]], insert_before_this_id = null, offset = 1, [[withoutModel = false]]
	const duplicateCargoSite = ( siteModelorId, insertBeforeThisSiteId = null, offset = 1, folderId = null ) => {

		const isSiteModelObject = typeof siteModelorId === 'object' && siteModelorId !== null;
		const siteId = isSiteModelObject ? siteModelorId.id : parseInt(siteModelorId, 10);
		const siteModel = isSiteModelObject ? siteModelorId // If siteModelorId is an object, use it.
						: sites.find(({ id }) => id === siteId) // If siteModelorId is an ID, find the sites array.
						|| templateSites.find(({ id }) => id === siteId) // If siteModelorId is an ID, find the templates array.
						|| { id: siteId, version: 'Cargo3' }; // If siteModelorId is an ID, use a shell model.

		const targetFolder = folderId ? _.find(folders, (folder) => folder.id === folderId) : currentFolder; 

		const withoutModel = siteModel.hasOwnProperty('display_url') ? false : true;

		// const willForwardBeforeDupe = shouldForwardBeforeDupe || ( user && baseRoute.routeInfo.isUserFolder && currentFolder.id !== user?.saved_folder_id && currentFolder.id !== targetFolder.id ); 

		// Before navigation...
		const preDuplicationSetup = () => {

			if (!siteModelorId) {
				console.warn('No valid site model or id provided for duplication.');
				return false;
			}

			const userRole = _.find(user.permissions, (siteItem) => siteItem.site_id === siteId);
			if (userRole?.role === 'Viewer' || userRole?.role === 'Inuse') {
				return false;
			}

			if (siteModel.version === 'Cargo3') {
				// set redux state id to clone
				setDuplicatingSiteId('clone');
			}

			return true;
		};

		// Function to call after navigation
		const startDuplication = () => {

			// Call your duplicate site function here
			duplicateSite(siteId, targetFolder, insertBeforeThisSiteId, offset, withoutModel).then((res) => {

				if (res.data.version === 'Cargo3') {
					// set redux state to real site ID
					// this will be cleared by account data pull
					setDuplicatingSiteId(res.data.id);
				}

				if (res.data.version === 'Cargo3') {
					// Immediatly fetch permissions...
					syncAfterSiteDuplication(targetFolder, res);
				} else {
					// Update site permissions after duplication state is complete.
					// The event this is looking for is emitted from the progress bar.
					window.addEventListener('syncAfterSiteDuplication', (e) => {
						syncAfterSiteDuplication(targetFolder, res);
					}, { once: true });
				}
			});
		};

		// Execute pre-duplication setup
		if ( preDuplicationSetup() ) {
			// Check if we need to navigate before duplication
			if (shouldForwardBeforeDupe) {
				history.push("/")
			}

			setTimeout(() => {
				startDuplication();
			}, 1);

		}
	};

	return (
		<SiteDuplicationContext.Provider
			value={{
				loginFromContext,
				handleSiteDuplication,
				duplicateCargoSite,
			}}
		>
			{children}
		</SiteDuplicationContext.Provider>
	);
};

export default SiteDuplicationProvider;