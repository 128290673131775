import React, {useEffect, useMemo, useState} from 'react';
import { connect } from 'react-redux';
import { actions } from "../actions";
import { API_ORIGIN } from "@cargo/common";
import cargoFetch from '@cargo/fetch';

const ProgressBar = ({progressId, isClone, url, confirmDuplication, version, site, simulateDuplication}) => {

    // If we're cloning a C3 site, and it's finished, but we're still waiting on account data
    // the progress bar should be re-initiated at 100%.
    const [progress, setProgress] = useState(!isClone && version === 'Cargo3' ? 100 : 0);
    const [requests, setRequests] = useState(0);

    const timeOutLength = useMemo(() => 30, [requests]);

    const makeRequest = () => {
        setRequests(r => r++);

        cargoFetch.get(`${API_ORIGIN}/sites/${site.id}/progress/${progressId}`)
            .then(response => {

                const percent = response.data.percent;
                setProgress(percent);

                if(percent < 100){
                    setTimeout(() => {
                        makeRequest();
                    }, timeOutLength);
                }

                if( percent === 100 && version === 'Cargo2' ){
                    confirmDuplication(progressId);
                    // Confirm site duplication and re-pull collection for permissions data
                    const syncEvent = new Event('syncAfterSiteDuplication');
                    window.dispatchEvent( syncEvent );
                }

            });

    }

    useEffect(() => {

        if( version !== 'Cargo3' ){ return }

        let num = progress;

        if( progress < 100 ){
            setTimeout(() => {
                setProgress(num+10)
            }, 100)
        }

    },[progress])

    useEffect(() => {

        if( version === 'Cargo2' && progress < 100 && !isClone && progressId ){
            makeRequest();
        }

        if( version === 'Cargo3' && progress < 100 && isClone && !progressId && !simulateDuplication){
            // fake progress for C3 as it happens almost immediately
            setProgress(10)
        }

        if( version === 'Cargo3' && simulateDuplication && !isClone ){
            // Site is cloned, but we are waiting on account data to come through.
            setProgress(100)
        }

      }, []);

    const progressPrint = useMemo(() => progress <= 10 ? 10 : progress, [progress]);

    return (
        <div className="progress">
            <div className="track">
                <div className="completed" style={{width: progressPrint+'%'}}></div>
            </div>
        </div>
    )
} 

const mapReduxStateToProps = (state) => {

    return {}
}

const mapDispatchToProps = dispatch => ({
    confirmDuplication: progressId => dispatch(actions.confirmDuplication(progressId))
});

export default connect(
    mapReduxStateToProps,
    mapDispatchToProps
)(ProgressBar);

