import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from "../actions";
import { useHistory } from "react-router-dom";
import { ContextMenuButton, ContextSubMenu, ContextMenuCheckbox } from "@cargo/common/context-menu";
import { HOMEPAGE_ORIGIN } from "@cargo/common";

const FolderLinkContextUI = ({ name, email, logout, trashFetched, hasDeletedSites, updateHomepageState }) => {

	const history = useHistory();

	return (<>
			<ContextMenuButton
				label={ name && name !== '' ? name : email}
				onPointerUp={()=>{
					history.push('/account/account', { preventScroll: true });
				}}
			/>
			<hr/>
			<ContextMenuButton label="Account Settings" 
				onPointerUp = { e => { 
					history.push('/account', { preventScroll: true });
				}}
			/>
			<ContextMenuButton label={<>Docs <em>↗</em></>}
				onPointerUp = { e => { 
					window.open('https://docs.cargo.site', '_blank').focus();
				}}
			/>
			<ContextMenuButton label="Information" onPointerUp = {e=>{
				history.push( '/information' );
			}}/>
			<ContextMenuButton label="Trash" 
				onPointerUp = { e => {
					history.push( '/trash' );
				}}
				disabled={!trashFetched || !hasDeletedSites ? true : null }
			/>
			<hr />
			<ContextMenuButton 
				label="Logout" 
				onPointerUp = { e => {

					updateHomepageState({
						suppressLoginForm: true
					})

					let logoutForward = CARGO_ENV !== 'production' ? HOMEPAGE_ORIGIN : 'https://cargo.site';

					logout({
						// pass the silent flag so the UI doesn't shift after reload
						silent: true
					}).finally(() => { 
						window.location.href = logoutForward; 
					})
					
				}}
			/>
	</>);

};

function mapReduxStateToProps(state, ownProps) {

	return {
		authenticated: state.auth.authenticated,
		email: state.account.email,
		name: state.account.full_name,
		homepageState: state.homepageState,
		sites: state.sites,
		trashFetched: state.homepageState.trashFetched,
		hasDeletedSites: state.deletedSites?.length > 0,
	};

}

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		logout: actions.logout,
		fetchAccount: actions.fetchAccount,
		fetchFolders: actions.fetchFolders,
		updateHomepageState: actions.updateHomepageState,
		addUIWindow: actions.addUIWindow,
	}, dispatch);

}

export default connect(
	mapReduxStateToProps,
	mapDispatchToProps 
)(FolderLinkContextUI);