export class CargoHashId {
  constructor(prefix = '', timestamp = 0, siteId = 0, userId = 0) {
    this.prefix = prefix
    this.timestamp = timestamp
    this.siteId = siteId
    this.userId = userId
    this._encoded = undefined
  }

  static decode(hashString = '') {
    const { groups: hash } = /(?<prefix>\D+)?(?<digits>\d+)/g.exec(hashString)
    const encoded = BigInt(hash.digits)
    if (encoded < Number.MAX_SAFE_INTEGER) {
      throw new Error('Compressed Hash IDs can not be decoded')
    }

    const bigIntToNumber = (b) => BigInt.asUintN(64, b).toString() * 1

    const prefix = hash.prefix ?? ''
    const timestamp = bigIntToNumber(encoded >> 64n)
    const siteId = bigIntToNumber((encoded >> 32n) & 0xFFFFFFn)
    const userId = bigIntToNumber(encoded & 0xFFFFFFn)

    return new CargoHashId(prefix, timestamp, siteId, userId)
  }

  get encoded() {
    if (this._encoded === undefined) {
      this._encoded = (BigInt(this.timestamp) << 64n) |
                      (BigInt(this.siteId) << 32n) |
                      (BigInt(this.userId))
    }

    return this._encoded
  }

  get digits() {
    return this.encoded.toString()
  }

  toString() {
    return `${this.prefix}${this.encoded}`
  }

  toJSON() {
    return {
      prefix: this.prefix,
      digits: this.digits,
      timestamp: this.timestamp,
      siteId: this.siteId,
      userId: this.userId,
    }
  }
}
