export const fadeInGlideUp = {
    initial: { opacity: 0, transform: 'translateY(1px)' },
    animate: { opacity: 1, transform: 'translateY(0)' },
    transition: {
        duration: 0.35,
        delay: 2.350,
        ease: [0.42, 0, 0.58, 1.0], // ease-in-out equivalent
    },
    once: true,
    deferAnimation: true,
    resetOnUnmount: false,
    resetAfterComplete: false,
};

export const fadeInGlideUp1 = {
    initial: { opacity: 0, transform: 'translateY(1px)' },
    animate: { opacity: 1, transform: 'translateY(0)' },
    transition: {
        duration: 0.6,
        delay: 0.8,
        ease: [0, 0, 0.58, 1], // ease-out equivalent
    },
    once: true,
    deferAnimation: true,
    resetOnUnmount: false,
    resetAfterComplete: false,
};

export const fadeInGlideUp2 = {
    initial: { opacity: 0, transform: 'translateY(1px)' },
    animate: { opacity: 1, transform: 'translateY(0)' },
    transition: {
        duration: 0.6,
        delay: 1.3,
        ease: [0, 0, 0.58, 1], // ease-out equivalent
    },
    once: true,
    deferAnimation: true,
    resetOnUnmount: false,
    resetAfterComplete: false,
};

export const fadeInGlideUp3 = {
    initial: { opacity: 0, transform: 'translateY(1px)' },
    animate: { opacity: 1, transform: 'translateY(0)' },
    transition: {
        duration: 0.6,
        delay: 2.35,
        ease: [0, 0, 0.58, 1], // ease-out equivalent
    },
    once: true,
    deferAnimation: true,
    resetOnUnmount: false,
    resetAfterComplete: false,
};

export const fadeInGlideUp4 = {
    initial: { opacity: 0, transform: 'translateY(1px)' },
    animate: { opacity: 1, transform: 'translateY(0)' },
    transition: {
        duration: 0.6,
        delay: 3.15,
        ease: [0, 0, 0.58, 1], // ease-out equivalent
    },
    once: true,
    deferAnimation: true,
    resetOnUnmount: false,
    resetAfterComplete: false,
};

export const fadeInGlideDown = {
    initial: { opacity: 0, transform: 'translateY(-1px)' },
    animate: { opacity: 1, transform: 'translateY(0)' },
    transition: {
        duration: 0.35,
        delay: 2.350,
        ease: [0.42, 0, 0.58, 1.0], // ease-in-out equivalent
    },
    once: true,
    deferAnimation: true,
    resetOnUnmount: false,
    resetAfterComplete: false,
};