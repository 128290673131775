import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { UIWindow, HotKey } from "./ui-kit";
import _ from 'lodash';
import { actions } from "../actions";
import { isServer, isServerRendered } from "@cargo/common/helpers";
import { LoadingAnimation } from "@cargo/ui-kit";

class UIWindowLayer extends Component {
	constructor(props) {

		super(props);
		this.state ={
			width: 1280,
			height: 720
		}

		this.debug = false;

		this.ref = React.createRef();
		this.windowLayerRef = React.createRef();

		this.handleGlobalClick = this.handleGlobalClick.bind(this);

		this.clickOriginInAdmin = null;
	}

	render() {

		const uiWindows = [];
		const focusModal = _.get(this.props.uiWindows.byGroup, 'focus-modal');
		// let isMobile = this.props.adminState.viewport === 'mobile';
		_.each(this.props.uiWindows.byId, (uiWindowObj, id) => {

			const component = React.isValidElement(uiWindowObj.component) 
				? React.cloneElement(uiWindowObj.component, uiWindowObj.props) 
				: React.createElement(uiWindowObj.component, uiWindowObj.props);

			uiWindows.push(
				<UIWindow key={id} windowSize={this.state} {...uiWindowObj.props}>
					{ component }
				</UIWindow>
			);
		});

		return (
			<>
				<div 
					// ref={this.props.windowLayerRef} 
					ref={this.windowLayerRef}
					id="uiWindow-layer"
				>

					{this.props.showLoader && <div className="window-loader">
						<LoadingAnimation reverseChance={50} className="linear delay large"/>
					</div>}

					{uiWindows}

				</div>
				
				<HotKey shortcut="escape" boundTo="interface" callback={() => {

					if( 
						document.querySelector('.context-menu-layer')
						|| document.querySelector('.alert-window.active')
					) { return }

					if( this.getWindowByID('c3-account-manager-window')
						&& (   document.querySelector('div#save-buttons .visible') 
							|| document.querySelector('.billed-to-editor')
						)
					){
						//C3 Account manager handles its own 'esc' hotkey
						return
					}
					
					if( this.getWindowByID('dns-editor') ){
						//DNS manager handles its own 'esc' hotkey
						return
					}

					document.activeElement.blur()
					window.requestAnimationFrame(()=> {
						this.closeAllWindows();
					})	
					
				}}/>

				<HotKey shortcut="cmd+shift+1" boundTo="all" callback={(e) => {

						const lastVisitedFolder = localStorage.getItem('last-visted-slug')

						if(lastVisitedFolder){
							this.props.history.push(`/${lastVisitedFolder}`);
						} else {
							this.props.history.push(`/`)
						}

				}} />

				<HotKey shortcut="cmd+shift+2" boundTo="all" callback={(e) => {
					this.props.history.push("/templates");
				}} />

			</>
		);

	}

	updateWindowSize = ()=>{
		this.setState({
			width: document.documentElement.clientWidth,
			height: document.documentElement.clientHeight
		})
	}

	componentDidUpdate(prevProps) {

		if (this.props.pathname !== prevProps.pathname) {
			this.onRouteChange();
		}

		if( this.props?.uiWindows?.byGroup?.['focus-modal'] ){

		}
	}


	componentDidMount(){
		// click
		
		window.addEventListener("pointerdown", this.handleGlobalClick);
		window.addEventListener("pointerup", this.handleGlobalClick);
		window.addEventListener("click", this.handleGlobalClick);

		// resize
		window.addEventListener('resize', this.updateWindowSize)
		this.updateWindowSize();
	}

	componentWillUnmount(){
		// click
		window.removeEventListener("pointerdown", this.handleGlobalClick);
		window.removeEventListener("pointerup", this.handleGlobalClick);
		window.removeEventListener("click", this.handleGlobalClick);
		// resize
		window.removeEventListener('resize', this.updateWindowSize)
	}

	handleGlobalClick = (event) => {

		if (this.debug) { console.log('click debug: global clickout', event.target) }
	
		let target = event.target;
		let type   = event.type;
		let inAdmin = document.contains(target);

		if ( inAdmin && type == 'pointerdown' ) {
			this.clickOriginInAdmin = true;

			return
		} else if( !inAdmin && type == 'pointerdown' ) { 
			this.clickOriginInAdmin = false;
			return
		}

		if ( inAdmin || this.clickOriginInAdmin ) {

			if (this.debug) { console.log('click debug: clickout in admin') }

			// We're clicking in the admin
			this.handleClickInAdmin(target);

		} else if( !inAdmin || !this.clickOriginInAdmin ) {

			if (this.debug) { console.log('click debug: clickout in editor') }

			// We're clicking in the content frame
			if ( this.hasActiveEditor() ) {

				this.handleEditorWindowClickout(target);

			} else {

				if (event.target.nodeName === 'A' || event.target.closest('a') !== null) {
					// allow links to propagate normally without closing any windows

				} else {

					// CLOSE ALL WINDOWS
					this.closeAllWindows(target);

				}

				
			}

		}
	}

	closeAllWindows(target) {
		if( !target ){
			this.props.removeUIWindow(uiWindow => {
				return !uiWindow.props.ignoreClickout;
			});
			return
		}
		if ( this.clickTargetIsNotAdminInterface(target) ) {
			// close every window
			this.props.removeUIWindow(uiWindow => {
				return !uiWindow.props.ignoreClickout;
			});
		}
	}

	handleEditorWindowClickout(target) {

		let activeEditorRange = _.get(this.context, 'range');

		if (activeEditorRange) {
			// click originated INSIDE of an editor
			if (this.context.editorHasFocus) {

				if (
					this.props.uiWindows.byGroup['formatting'] !== undefined && 
					this.props.uiWindows.byGroup['formatting'].length > 0
				) {
					const currentFormattingWindow = this.getWindowByID(this.props.uiWindows.byGroup['formatting'][0]);
					const commandIsAppliedToNewRange = activeEditorRange.commands[currentFormattingWindow.props.windowName].isApplied;

					if (commandIsAppliedToNewRange) {

						// leave the window open, BUT close other windows
						this.props.removeUIWindow(uiWindow => {
							return uiWindow.group !== 'formatting';
						});
					} else {

						// close it
						this.closeAllWindows(target);
					}

				}
				// check if there is an open FORMATTING window
				// this.props.removeUIWindow(uiWindow => {
				// 	return uiWindow.group === 'formatting';
				// });

			} else {

				this.closeAllWindows(target);

			}
		}
	}

	handleClickInAdmin(target) {

		// click orignated inside the window layer
		if ( this.windowLayerRef.current.contains(target) ) {

			if (this.debug) { console.log('focus modal click', this.isTargetClickoutLayer(target)) }
	
			const focusModal = this.props.uiWindows.byGroup?.['focus-modal'];

			if (focusModal && focusModal.length > 0 && this.isTargetClickoutLayer(target)) {
				// Else fire away
				this.props.removeUIWindow(uiWindow => {
					return uiWindow.group === 'focus-modal';
				});
	
				return;
			}

			// console.log('target is in window layers')
			// first check if there is a focus modal open
			// which will have the '.window-layer-focus-overlay' div present
			// if (target.matches('.window-layer-focus-overlay')) {
			// 	// close only the focus modal in this case
			// 	this.props.removeUIWindow(uiWindow => {
			// 		return uiWindow.props.type === 'focus-modal';
			// 	});
			// 	return;
			// }
		
		} else {
			// this is bad, but when you drag pointer events need to be prevented
			if (target.nodeName === 'HTML' || this.clickOriginInAdmin) {
				return;
			}
			// clicking out in the admin will close all windows if not clicking on an 'interactive' element
			this.closeAllWindows(target);
		}
		

	}

	clickTargetIsNotAdminInterface(target) {
		let button        = target.closest('button')
		let label         = target.closest('label')
		let input         = target.closest('input')
		let clickoutLayer = this.isTargetClickoutLayer(target);

		return button === null && label === null && input === null && !clickoutLayer;

	}

	isTargetClickoutLayer(target) {
		return target.classList.contains('clickout-layer') || target.closest('.clickout-layer');
	}

	onRouteChange(path) {
		// close ALL windows when changing admin routes
		this.props.removeUIWindow(uiWindow => {
			return !uiWindow.props.ignoreClickout;
		});
	}

	getWindowByID(windowID) {
		return this.props.uiWindows.byId[windowID]
	}

	hasActiveEditor() {
		return _.get(this.props.frontendState, 'PIDBeingEdited');
	}

	getActiveEditorRange() {
		return ;
	}

}

function mapReduxStateToProps(state, ownProps) {

	return {
		uiWindows: state.uiWindows,
	};

}

function mapDispatchToProps(dispatch) {

	return bindActionCreators({
		addUIWindow: actions.addUIWindow,
		removeUIWindow: actions.removeUIWindow
	}, dispatch);

}

// var UIWindowLayerWithRef = React.forwardRef((props, ref) => <UIWindowLayer 
// 	windowLayerRef={ref} {...props}
// />);

export default connect(
	mapReduxStateToProps, 
	mapDispatchToProps
)(UIWindowLayer);	