import React, { Component, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter, NavLink} from 'react-router-dom';
import { actions } from "../actions";
import _ from "lodash";

const TopMenuBar = ({ authenticated, children, id }) => {

	return ( <>
		<div id={id} className={`top-menu-bar${!authenticated ? ' logged-out' : ''}`}>
			{children}
		</div>
	</>);

}

function mapReduxStateToProps(state, ownProps) {

	return {
		authenticated: state.auth.authenticated,
	};

}

function mapDispatchToProps(dispatch) {

	return bindActionCreators({
		addUIWindow: actions.addUIWindow,
	}, dispatch);

}

export default withRouter(connect(
	mapReduxStateToProps, 
	mapDispatchToProps
)(TopMenuBar));

