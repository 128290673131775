import React, { Component, createRef, useRef, useState, cloneElement, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from "../actions";
import { frontloadConnect } from '@cargo/react-frontload';
import {generateGridLayout, generateJustifyLayout} from '../../../frontend/src/components/page/gallery/layout-helpers';
import { withRouter } from 'react-router-dom';

import { isServer,isMobile } from "@cargo/common/helpers";
import BrowserBar from './browser-bar';

import _ from 'lodash'

const resizeMap = new Map();
const layoutMap = new Map();

let canvas;
let ctx;
let updateCaptionScroll = ()=>{}
let debouncedCaptionScroll = ()=>{}

let resizeObserver, layoutObserver, intersectionObserver, layoutIntersectionObserver;

if(!isServer) {

	updateCaptionScroll = (e)=>{
		const windowHeight = window.innerHeight;
		const communityMenu = window['community-menu'];

		layoutMap.forEach((component, el)=>{

			if( !( component?.state.visible ?? false ) ) {
				return
			}

			const captionReferences = Array.from(el.querySelectorAll('.caption') );

			const animationArray = [];
			captionReferences.forEach((el)=>{
			

		        const rect = el.getBoundingClientRect();

		        // follow this value around to gauge where we are in window
		        let midPoint = rect.top + rect.height*.5;

		        // these are our 'animation' keyframes
		        // described from top (.3 opacity, no transform) to
		        // bottom (0 opacity, 1 transform)
		        let keyframes = [
		            // at top
		            {
		                opacity: 0,
		                transform: 0,
		            },

		            // in middle. remove one of these duplicate keyframes to increase the amount of space the transition occupies.
		           // or add more to increase the size of the 'middle' position
		            {
		                opacity: 1,
		                transform: 0,
		            },
		            {
		                opacity: 1,
		                transform: 0,
		            },
		            // at bottom
		            {
		                opacity: 1,
		                transform: 0,
		            },

		        ]

		        if( rect.left < 180  && communityMenu ){
		        	keyframes = [
		            // at top
			            {
			                opacity: 0,
			                transform: 0,
			            },
			            {
			                opacity: 0,
			                transform: 0,
			            },	
			            {
			                opacity: .3,
			                transform: 0,
			            },	            

			            // in middle. 
			            {
			                opacity: 1,
			                transform: 0,
			            },
			            {
			                opacity: 1,
			                transform: 0,
			            },		            
			            {
			                opacity: 1,
			                transform: 0,
			            },
			            // at bottom
			            {
			                opacity: 1,
			                transform: 0,
			            },
			            {
			                opacity: 1,
			                transform: 0,
			            },		            

			        ]
		        }

		        // clamp midPoint to values where part of the element is inside the window
		        midPoint = Math.min(windowHeight + rect.height*.5, Math.max(rect.height *-.5, midPoint) );


		        const windowSections = keyframes.length -1;

		        // calculate the midpoint's position between these two values to figure out where in the animation timeline we are
		        const windowFrameMin = rect.height*-.5;
		        const windowFrameMax = windowHeight + rect.height*.5;

		        const sectionHeight = (windowFrameMax-windowFrameMin)/windowSections;
		        const positionInSection = ((midPoint -windowFrameMin) % sectionHeight)/sectionHeight;
		        const sectionIndex = Math.floor( Math.min(windowSections-1, (midPoint -windowFrameMin)/(windowFrameMax-windowFrameMin) * windowSections));
		        
		        const currentKeyFrames = {
		            first: keyframes[sectionIndex],
		            second: keyframes[sectionIndex+1] ? keyframes[sectionIndex+1] : keyframes[sectionIndex] ,
		        };


		        const opacity = currentKeyFrames.first.opacity * (1-positionInSection) + positionInSection *currentKeyFrames.second.opacity;
		        const transform = currentKeyFrames.first.transform * (1-positionInSection) + positionInSection *currentKeyFrames.second.transform;

		        animationArray.push({
		        	opacity,
		        	transform
		        })
			})

			component.setState({animationArray})

	        
		});
	}

	debouncedCaptionScroll = _.debounce(updateCaptionScroll, 10);

	window.addEventListener('scroll', updateCaptionScroll, {passive: true})

	resizeObserver = new ResizeObserver(function(entries){

		entries.forEach(function(entry){

			let foundEntryName = ''

			const mapItem = Array.from(resizeMap).find(([entryName, mapItem])=>{

				if( mapItem.el == entry.target ){
					foundEntryName = entryName;
					return true
				}

			})[1]

			if(mapItem){

				const box = entry.contentBoxSize[0] || entry.contentBoxSize;
				const width = box.inlineSize;
				const height = box.blockSize;

				mapItem.component.setState(prevState=>{
					if(prevState[foundEntryName+'Width'] === width && prevState[foundEntryName+'Height'] == height){
						return null
					} else {
						return {
							[foundEntryName+'Width']: width,
							[foundEntryName+'Height']: height,
						}
					}
				}, ()=>{
					debouncedCaptionScroll();
				});

			}

		});
	});

	const onLayoutIntersection = (entries)=>{
		entries.forEach(entry=>{

			const component  = layoutMap.get(entry.target);
			if( component !== undefined ){
				component.setState((prevState)=>{

					// only set 'loadable' true once
					if( prevState.loadable){
						return {
							...prevState,
							visible: entry.isIntersecting || entry.isVisible,
						}
					} else {
						return {
							...prevState,
							visible: entry.isIntersecting || entry.isVisible,							
							loadable: entry.isIntersecting || entry.isVisible,
						}
					}
					
				});
				layoutMap.set(entry.target, component)
			}

		})
	}

	layoutIntersectionObserver = new IntersectionObserver(onLayoutIntersection, {
		root: document,
		rootMargin: (screen.height*2)+'px 0px',
		threshold: [0,1]
	});

	layoutObserver = new ResizeObserver(function(entries){

		entries.forEach(function(entry){

			const layoutComponent = layoutMap.get(entry.target);
			if( !layoutComponent){
				return;
			}

			const box = entry.contentBoxSize[0] || entry.contentBoxSize;
			const width = box.inlineSize;
			const height = box.blockSize;

			layoutComponent.setState({
				width, height
			})

		});
	});

}


const frontload = async props => { 

	const frontloadPromises = [];

	if( !props.hasTemplates && !props.loadingTemplates ){
		frontloadPromises.push( 
			props.fetchTemplates()
		);

		frontloadPromises.push(
			props.fetchCommunityPeriods()
		);
	}

	await Promise.allSettled(frontloadPromises);

}


export const categoryList = Object.freeze([
	{
		title: 'All',
		slug: 'all',
		key: 'all'
	},
	{
		title: 'Sites in Use',
		slug: 'in-use',
		key: 'inuse'
	},
	{
		title: 'Graphic Design',
		slug: 'graphic-design',
		key: 'graphicdesign'
	},
	{
		title: 'Style',
		slug: 'style',
		key: 'style'
	},
	{
		title: 'Architecture & Design',
		slug: 'architecture-design',
		key: 'architecturedesign',
		navTitle: 'Arch. & Design'
	},
	{
		title: 'Art',
		slug: 'art',
		key: 'art'
	},
	{
		title: 'Photo',
		slug: 'photo',
		key: 'photo'
	},
	{
		title: 'Shops',
		slug: 'shops',
		key: 'shop'
	}
]);



class Community extends Component {

	constructor(props) {
		super(props);

		this.state = {
			currentRenderLimit: 0,
			allRenderIncrement: 4,
			categoryRenderIncrement: 50,
		}

		this.state.currentRenderLimit = this.getCurrentRenderIncrement();

		this.scrollRestorationMap = new Map();
		this.lastScrollPosition = 0;

		this.paginationRef = createRef();
		this.measurementRef = createRef();

	}


	saveRef =  (el, resizeMapKey) => {

		if(el && !resizeMap.has(resizeMapKey) ){

			resizeMap.set(resizeMapKey, {
				el: el,
				component: this
			});
			resizeObserver.observe(el);

		} else if ( !el && resizeMap.has(resizeMapKey) ){

			const mapItem = resizeMap.get(resizeMapKey);
			resizeObserver.unobserve(mapItem.el);
			resizeMap.delete(resizeMapKey);

		} 
	}

	componentDidMount = () => {

		document.title = 'Cargo Community™';

		this.paginationObserver = new IntersectionObserver(this.onPaginationIntersection, {
			root: document,
			rootMargin: (screen.height * 2) + 'px',
			threshold: [0,1]
		});

		if(this.paginationRef.current) {
			this.paginationObserver.observe(this.paginationRef.current);
		}

		window.addEventListener('scroll', this.onScroll, {
			passive: true
		})



		document.body.classList.add('community');

	}

	componentWillUnmount = () => {

		resizeMap.forEach((key, mapItem)=>{
			if( mapItem.component === this){
				resizeObserver.unobserve(mapItem.el);				
				resizeMap.delete(key);
			}
		});

		this.paginationObserver.disconnect();

		window.removeEventListener('scroll', this.onScroll);

		document.body.classList.remove('community');

	}

	onPaginationIntersection = (entries) => {

		this.paginationRequired = entries[0].isIntersecting;

		if(this.paginationRequired) {
			// start paginating
			this.startPagination();
		}

	}

	getCurrentRenderIncrement = () => {
		return (this.props.category.key === 'all' || this.props.category.key === 'inuse') ? this.state.allRenderIncrement : this.state.categoryRenderIncrement
	}

	shouldComponentUpdate(nextProps, nextState) {


		// before switching to a new category
		if(this.props.category !== nextProps.category) {

			// this.startTransitionAnimation(nextProps.category.key);
			 // nextState.animating = true;

			// grab the current and new locations
			const currentSlug = this.props.category?.slug
			const newSlug = nextProps.category?.slug

			// store current location scroll position and render limit
			if(currentSlug) {
				
				this.scrollRestorationMap.set(currentSlug, {
					scrollTop: this.lastScrollPosition,
					renderLimit: this.state.currentRenderLimit
				});

			}

			// get a default next render limit
			let nextRenderLimit = this.getCurrentRenderIncrement();

			// restore new location scroll position if stored
			if(newSlug && this.scrollRestorationMap.has(newSlug)) {

				const scrollRestorationData = this.scrollRestorationMap.get(newSlug);

				// restore last render limit
				 nextRenderLimit = scrollRestorationData.renderLimit;

				// kick off scroll restoration
				requestAnimationFrame(() => {
					this.restoreScroll(scrollRestorationData.scrollTop)
				})

			}

			// Immediately set new render limit for the impending render
			nextState.currentRenderLimit = nextRenderLimit;

		}

		return true;

	}

	onScroll = (e) => {
		this.lastScrollPosition = document.scrollingElement.scrollTop;

	}

	restoreScroll = async (savedScrollPosition) => {

		this.scrollAttempts = 0;

		while (true) {

			if (
				// exceeded max attempts
				this.scrollAttempts > 10
				// or user already scrolled
				|| document.scrollingElement.scrollTop
			) {
				// bail
				break;
			}

			if ( document.scrollingElement.scrollHeight >= savedScrollPosition ) {

				// restore scroll
				document.scrollingElement.scrollTop = savedScrollPosition;

				// bail
				break;
			}

			this.scrollAttempts++;

			// wait 100ms till next attempt
			await new Promise(resolve => setTimeout(resolve, 100));

		}

	}



	componentDidUpdate(prevProps) {

		if(this.props.category !== prevProps.category) {
			// see if the new category needs pagination
			this.startPagination();
			requestAnimationFrame(()=>{
				debouncedCaptionScroll();			
			})			
		}



	}

	startPagination = async () => {
		while (this.paginationRequired) {
			const { category, community } = this.props;
			const { currentRenderLimit, allRenderIncrement, categoryRenderIncrement } = this.state;

			if (community.periods.length > currentRenderLimit) {
				await new Promise(resolve => {
					this.setState(prevState => ({
						currentRenderLimit: prevState.currentRenderLimit + (allRenderIncrement || 4)
					}), () => {
						setTimeout(resolve, 100);
					});
				});

				continue;  // skip the rest of the loop
			}
	
			// Ensure there's something to paginate before attempting
			if (community.paginationComplete) {
				break;
			}
	
			// Fetch more data
			await this.props.fetchCommunityPeriods();
	
			// Wait 100ms before checking again
			await new Promise(resolve => setTimeout(resolve, 100));
		}
	}


	renderLayout = (layout, layoutIndex, firstVisibleLayoutInPage, secondVisibleLayoutInPage)=>{
		if( !layout || layout.items.length == 0){
			return null;
		}

		const isHidden = (layout.category !== this.props.category.key) && (this.props.category.key !== 'all');

		// always load the first 20 so we minimize flashing and loading
		if(	isHidden && layoutIndex > 20){
			return null;
		}

		let pixelWidth = this.state.communityWidth;


		switch(layout.category){

			case "inuse":

				return <Layout
					firstVisibleLayoutInPage={firstVisibleLayoutInPage}
					secondVisibleLayoutInPage={secondVisibleLayoutInPage}
					index={layoutIndex}
					layout={layout}
					key={`${layout.type}-${layoutIndex}-${layout.subtype}-${layout.alignment}`}
				>
				{
					layout.items.map((mapItem, mapIndex)=>{
						return <LayoutItem
							model={mapItem}
							index={mapIndex}
							pixelWidth={pixelWidth}
							inUseLayout={true}
							key={`${layout.type}-${layout.subtype}-${layoutIndex+mapIndex}`}
						/>
					})
				}</Layout>

			case "art":
			case "photo":
			case "architecturedesign":
			case "style":
			case "graphicdesign":
			case "shop":
			case "shop-mobile":

				if( layout.type == 'spread' || layout.type == 'spreadfit'){
					return <Layout
						firstVisibleLayoutInPage={firstVisibleLayoutInPage}
						secondVisibleLayoutInPage={secondVisibleLayoutInPage}
						index={layoutIndex}
						layout={layout}
						key={`${layout.type}-${layoutIndex}-${layout.subtype}-${layout.alignment}`}
					>
						<LayoutItem
							model={layout.items[0]}
							index={0}
							pixelWidth={pixelWidth} 
						/>
					</Layout>	

				} else {

					if( layout.category === 'graphicdesign' && !this.props.isMobile ){
						pixelWidth = pixelWidth / layout.items.length;
					} else {
						pixelWidth = pixelWidth/2;					
					}

					return <Layout
						firstVisibleLayoutInPage={firstVisibleLayoutInPage}
						secondVisibleLayoutInPage={secondVisibleLayoutInPage}
						index={layoutIndex}
						layout={layout}
						key={`${layout.type}-${layoutIndex}-${layout.subtype}-${layout.alignment}`}
					>
						{layout.items.map((mapItem, mapIndex)=>{
							return <LayoutItem
								model={mapItem}
								index={mapIndex}
								pixelWidth={pixelWidth}
								key={layoutIndex+layout.subtype+mapIndex}
							/>
						})}
					</Layout>
				}
				break;
		

			default:

				return null;
				break;
		}

		
	}

	render() {


		let indexOfFirstVisiblePage = undefined;
		let indexOfSecondVisiblePage = undefined;

		const layoutJSX = this.props.periodMaps.map((layout, layoutIndex)=>{

			const isVisible = (this.props.category.key == 'all' || this.props.category.key == layout.category);

			if(  indexOfFirstVisiblePage==undefined && isVisible ){
				indexOfFirstVisiblePage = layoutIndex;
			}

			if( indexOfFirstVisiblePage!= undefined && layoutIndex > indexOfFirstVisiblePage && indexOfSecondVisiblePage==undefined && isVisible ){
				indexOfSecondVisiblePage = layoutIndex;
			}
			

			return this.renderLayout(layout,layoutIndex, layoutIndex==indexOfFirstVisiblePage, layoutIndex==indexOfSecondVisiblePage );
		})


		return <>
			<div
				id="community"
				className={`light-mode${this.props.category.key ? ' '+this.props.category.key : ''} ${this.state.animating? 'animate-in': ''}`}
				ref={(el)=> { this.saveRef(el, 'community') }}
			>
				{layoutJSX}
				<div ref={this.paginationRef}></div>
			</div>
			
		
		</>
	}

}

class Layout extends Component {

	constructor(props){
		super(props);
		this.state={
			animating: false,
			completedAnimations: 0,
			itemsLoaded: 0,

			animationArray:[{
				opacity: 1,
				transform: 0,
			},{
				opacity: 1,
				transform: 0,
			},{
				opacity: 1,
				transform: 0,
			},{
				opacity: 1,
				transform: 0,
			}],

			loadable: props.index < 20,

			width: 1000,
			height: 1000,
		}

		this.layoutRef = createRef();

	}

	render(){

		const {
			layout,
			children,
			layoutIndex,
			className ='',
			firstVisibleLayoutInPage,	
			secondVisibleLayoutInPage,	
			index,	
		} = this.props;

		const {
			animationArray,
			loadable,
			animating,
			visible,
			width,
			height,
		} = this.state;


		const {
			type = '',
			category = '',
			subtype = '',
			alignment= '',
			categoryViewOnly = false,
		} = layout;

		const aspectRatio = width / height;

		return <div
			className={`layout ${type} ${category} ${subtype} ${alignment} ${categoryViewOnly ? 'category-view-only': ''} ${layout.disregardCaption ? 'disregard-caption': ''} ${firstVisibleLayoutInPage? 'first-visible-in-page': ''}  ${secondVisibleLayoutInPage? 'second-visible-in-page': ''} ${className}`}
			key={`${layout.type}-${index}-${layout.subtype}-${layout.alignment}`}
			ref={this.layoutRef}
			style={{
				...this.props.style,
				'--layout-aspect-ratio': aspectRatio,				
				...animationArray.reduce((accumulator, mapItem, index)=>{
					return {
						['--caption-opacity-'+(index+1)]: mapItem.opacity,
						['--caption-transform-'+(index+1)]: mapItem.transform,
						...accumulator,
					}
				}, {}),
			}}
		>
			{React.Children.map(children, (child, index) =>{
				return cloneElement(child, {
					onImageLoad:this.onImageLoad,
					loadable: loadable,
					visible: visible,
				})
			})}

			{layout.category !== 'graphicdesign' && layout.category !=='inuse' ? <>
			<div className="crease"
				style={{
					backgroundImage: `url(${PUBLIC_URL}/images/crease-multiply.png)`
				}}
			/>
			<div className="crease layer-two"
				style={{
					backgroundImage: `url(${PUBLIC_URL}/images/crease-multiply.png)`
				}}
			/>
			<div className="crease layer-three"
				style={{
					backgroundImage: `url(${PUBLIC_URL}/images/crease-screen.png)`
				}}			
			/>
			<div className="crease layer-four"
				style={{
					backgroundImage: `url(${PUBLIC_URL}/images/crease-screen.png)`
				}}			
			/></> : null}	
		</div>
	}

	onImageLoad = (e)=>{
		this.setState(prevState=>{
			return {
				...prevState,
				itemsLoaded: prevState.itemsLoaded+1,
			}
		})
	}


	componentDidMount(){


		debouncedCaptionScroll();		
        layoutIntersectionObserver.observe(this.layoutRef.current);
        layoutMap.set(this.layoutRef.current, this);
		layoutObserver.observe(this.layoutRef.current);
	}

	componentWillUnmount(){

		layoutObserver.unobserve(this.layoutRef.current);		
    	layoutMap.delete(this.layoutRef.current);
    	layoutIntersectionObserver.unobserve(this.layoutRef.current);		
	}

}



function LayoutItem({
		model,
		index,
		pixelWidth=400,
		inUseLayout=false,
		visible=false,
		loadable=false,
		onImageLoad
	}){

	if( !model ){
		return null
	}

	const shopItem = model.category === 'shop' && model.price;

	const imageHash = model.media?.hash ?? model.inuse_screenshot?.hash ?? model.screenshot?.hash ?? null;
	const imageName = model.media?.name ?? model.inuse_screenshot?.name ?? model.screenshot?.name ?? null;
    const imgWidth  = model.inuse_screenshot?.width ?? model.media?.width ?? null;
    const imgHeight = model.inuse_screenshot?.height ?? model.media?.height ?? null;

    const aspectRatio = imgHeight/imgWidth;
	
	const instagramHref = model.instagram ? model.instagram : model.inuse_instagram_url ? model.inuse_instagram_url : null;
	const instagramTag = instagramHref ? instagramHref.replace(/^(https?:\/\/)?(www\.)?instagram\.com\/?/g, '').replace('/', "") : null;

	const siteDirectLink = model.url ? model.url : model.direct_link;
	const siteTitle      = model?.name ? model.name : model.inuse_website_title;

	const url = `https://freight.cargo.site/w/${Math.ceil(pixelWidth/100)*200}/q/75/i/${imageHash}/${imageName}`;


	return <div
		className={`cell ${model.category || 'inuse'}`}
		style={{
			'--aspect-ratio': (imgWidth /imgHeight) || '3456 / 2154',
			'--height-proportion': model?.proportion,
			'--overlap-width': model?.overlapWidth,
		}}		
	>
		<div
			className="item"
			key={'item-'+imageHash+imageName}

		>
			<a
				href={siteDirectLink}
				className="image-link"
				target="_blank"
			>	
				{inUseLayout ? (
					<BrowserBar displayURL={model.display_url} />
				) : null}
				
				<img
					src={visible ? url : ''}
					style={{
						visibility: visible ? 'visible': 'hidden',
						aspectRatio: imgWidth + ' / ' + imgHeight
					}}
					onLoad={onImageLoad}
				/>
				
			</a>


			<div className={`caption ${instagramHref ? '' : 'single-line'} ${visible ? 'animating' : ''}`}>
				<div className="caption-transform">
					<a className="site-link" href={siteDirectLink} target="_blank">{ model.product_name ? <>
							{model.product_name}<br/>
						</> : null}
						{siteTitle}
						{model.price ? <>
							<br/>{model.price}
						</>: null}
					</a>
					{(instagramHref && !model.price) ? ( 
						<><br/><a className="instagram-link" href={instagramHref} target="_blank">@{instagramTag}</a></>
					) : null}
				</div>
			</div>
		</div>	
	</div>

}




function mapReduxStateToProps(state, ownProps) {

	let category = _.find(categoryList, {
		slug: ownProps.match.params.category
	}) || categoryList[0];


  	let doubledPeriods = state.community.periods.reduce((accumulator, period, index)=>{
  		if( index%2==0 || index == 0){
  
  			return [
  				...accumulator,
  				_.cloneDeep(period),
  			]
  
  		} else {
  
  			// combine two periods in order to increase image library size

			const cloned = _.cloneDeep(period);

			Object.keys(cloned.data).forEach((key)=>{
				
				accumulator[accumulator.length-1].data[key] = [
					...accumulator[accumulator.length-1].data[key],				
					...cloned.data[key],
				]
			})
			return accumulator;
  			
  		}
  	},[])
	


	// these categories get redirected into 'double' layouts
	const mixedCategories = ['photo', 'style', 'art', 'architecturedesign'];

	let periodMaps = [];

	for( var i = 0; i < doubledPeriods.length; i ++){

		// with every iteration, we rotate the mixedCategories so spread/spreadfit
		// layouts can prioritize a different category
		mixedCategories.push(mixedCategories.shift())

		const contentMap = doubledPeriods[i].data;

		/** inuse **/

		const inUseMaps = contentMap.inuse.map((item, index)=>{

			return {
				category: 'inuse',
				items: [item],
			}					

		});

		/** graphicdesign **/

		const lengthArray = [4, 2, 4, 1, 4, 2, 2, 4, 1, 2, 4];

		let gdIndex = 0;
		const graphicDesignMaps = [];

		while(gdIndex<contentMap.graphicdesign.length){

			const required = lengthArray[gdIndex % lengthArray.length];
			const items = [];

			for( var j = gdIndex; j < gdIndex+required; j++ ){

				if( contentMap.graphicdesign[j] ){
					items.push(contentMap.graphicdesign[j]);
				}

			}

			// no three-up layouts for graphic design
			// split into one and two
			if( items.length == 3){

				graphicDesignMaps.push({
					category: 'graphicdesign',
					items: [items[0], items[1]],
				});

				graphicDesignMaps.push({
					category: 'graphicdesign',
					items: [items[2]],
				});

			} else {
				graphicDesignMaps.push({
					category: 'graphicdesign',
					items: items,
				})				
			}

			gdIndex = gdIndex+required;

		}

		/** shops **/

		let shopIndex = 0;
		const shopMaps = [];

		while(shopIndex<contentMap.shop.length){

			const required = 3;
			const items = [];

			for( var j = shopIndex; j < shopIndex+required; j++ ){

				if( contentMap.shop[j] ){
					items.push(contentMap.shop[j]);
				}

			}

			shopMaps.push({
				category: 'shop',
				items: items,
			})

			shopIndex = shopIndex+required;

		}


		/** photo, style, architecturedesign, art **/

		const mixedMaps = {
			art: [],
			photo: [],
			style: [],
			architecturedesign: [],
		};
		const spareMaps = {
			spread: [],
			spreadfit: [],
			art: [],
			photo: [],
			style: [],
			architecturedesign: [],			
		};

		// single-item spreads which require special formattings
		const spreadMaps = [];
		const spreadFitMaps = [];

		mixedCategories.forEach((key)=>{

			const itemPool = contentMap[key];

			// look for spreads if necessary
			if( spreadMaps.length < 3){
				let maxDepth = Math.min(itemPool.length, 30);

				for( let depth = 0; depth < maxDepth; depth++){

					let targetItem = itemPool[depth] || null;
					if( !targetItem){
						break;
					}

					if( targetItem.media.width < 1000){
						continue;
					}

					let ratio =(targetItem.media.height/targetItem.media.width);

					const depthlerp = depth/maxDepth;

					// ratio requirements get looser as depth increases
					const ratioIsValid = ratio < .79 + (depthlerp*.1)


					if( ratioIsValid  ){

						// remove targetItem from contentMap array
						itemPool.splice(depth, 1);

						spreadMaps.push({
							category: key,
							type: 'spread',
							items: [targetItem],
						})
						break;

					}
				}				
			}


 			if( spreadFitMaps.length < 1){
 				let maxDepth = itemPool.length;
 
 				for( let depth = 0; depth < maxDepth; depth++){
 
 					let targetItem = itemPool[depth] || null;
 					if( !targetItem){
 						break;
 					}

					if( targetItem.media.width < 800){
						continue;
					} 					
 
 					let ratio =(targetItem.media.height/targetItem.media.width);
 
 					const depthlerp = depth/maxDepth;
 
 					// ratio requirements get looser as depth increases
 					const ratioIsValid = ratio > 1.2 + -(depthlerp*.18);


 					if( ratioIsValid  ){

 						// remove targetItem from contentMap array
 						itemPool.splice(depth, 1);
 
 						spreadFitMaps.push({
 							category: key,
 							type: 'spreadfit',
 							items: [targetItem],
 						})
 						break;
 
 					}
 				}				
 			}

			while(itemPool.length > 0 ){

				const items = [];

				/** searching for landscape **/

				let maxDepth = Math.min(itemPool.length, 30);
			
				let itemFound = false;
				let closestDepthToTarget = 0;
				let closestRatioToTarget = undefined
				let lastRatioFound = null;

				for( let depth = 0; depth < maxDepth; depth++){

					let targetItem = itemPool[depth] || null;
					if( !targetItem){
						break;
					}

					let ratio =(targetItem.media.height/targetItem.media.width);

					const depthlerp = depth/maxDepth;

					// ratio requirements get looser as depth increases
					const ratioIsValid = ratio < .8 + (depthlerp*.18)

					if( closestRatioToTarget == undefined || ratio < closestRatioToTarget ){
						closestRatioToTarget = ratio;
						closestDepthToTarget = depth;
					}

					if( ratioIsValid  ){

						itemFound = true;
						lastRatioFound = ratio;

						// remove targetItem from contentMap array
						itemPool.splice(depth, 1);

						items.push(targetItem);
						break;

					}
				}

				// depth check is exhausted, if we didn't find any items
				// so we grab whatever was 'closest'

 				if( !itemFound  && Math.abs(closestRatioToTarget - 1) < .5 ){
 
 					lastRatioFound = closestRatioToTarget;
 					let usableItem = itemPool[closestDepthToTarget] || null;
 					if( usableItem){
 						// remove usableItem from itemPool
 						itemPool.splice(closestDepthToTarget, 1);
 						items.push(usableItem);
 					}
 				}


				/** searching for portrait **/

				itemFound = false;
				closestDepthToTarget = 0;
				closestRatioToTarget = undefined

				for( let depth = 0; depth < maxDepth; depth++){

					let targetItem = itemPool[depth] || null;
					if( !targetItem){
						break;
					}

					let ratio =(targetItem.media.height/targetItem.media.width);

					const depthlerp = depth/maxDepth;

					// ratio requirements get looser as depth increases
					let ratioIsValid = null;

					if( lastRatioFound ){
						ratioIsValid = ratio > 1 && ratio > lastRatioFound + ( .32  * (1-(depth/maxDepth) ) );
					} else {
						ratioIsValid = ratio > 1.2 + -(depthlerp*.18)
					}			

					if( closestRatioToTarget == undefined || ratio < closestRatioToTarget ){
						closestRatioToTarget = ratio;
						closestDepthToTarget = depth;
					}

					if( ratioIsValid  ){

						itemFound = true;
						lastRatioFound = ratio;

						// remove targetItem from contentMap array
						itemPool.splice(depth, 1);

						items.push(targetItem);
						break;

					}
				}

				// depth check is exhausted, if we didn't find any items
				// so we grab whatever was 'closest'

				if( !itemFound  && Math.abs(closestRatioToTarget - 1) < .5 ){

					let usableItem = itemPool[closestDepthToTarget] || null;
					if( usableItem){
						// remove usableItem from itemPool
						itemPool.splice(closestDepthToTarget, 1);
						items.push(usableItem);
					}
				}


				// less than ideal outcome?
				// into the sparemaps they go

				const isSpare = items.length < 2 || items[0]?.media.width < 600 || items[1]?.media.width < 600

				if( isSpare ) {

					// could have anywhere from 0-2 items
					if( items.length < 2 && itemPool.length > 0){
						items.push(itemPool.shift());
					}

					if( items.length == 1 && itemPool.length > 0){
						items.push(itemPool.shift());
					}

					if( items.length == 1){

						if( items[0].media.width / items[0].media.height > 1.5){
							spareMaps.spread.push({
								category: key,
								type: 'spread',
								items: items,
							})
						} else {
							spareMaps.spreadfit.push({
								category: key,
								type: 'spreadfit',
								items: items,
							})
						}

					} else {

						if( !spareMaps[key]){
							spareMaps[key] = [];
						}

						spareMaps[key].push({
							category: key,
							type: 'double',
							items: items,
						})

					}					

				} else {

					if(!mixedMaps[key]){
						mixedMaps[key] = [];
					}

					mixedMaps[key].push({
						category: key,
						type: 'double',
						items: items,
					})

				}

			}		

		})



		// after assembling layouts, put them in order according to this pattern

		let mapOrder = [
			'inuse',
			'photo',

			'graphicdesign',
			'art',

			'inuse',
			'art',

			'graphicdesign',
			'spread',

			'inuse',
			'style',

			'inuse',
			'shop',			

			'graphicdesign',
			'photo',

			'inuse',
			'spreadfit',

			'inuse',
			'photo',

			'inuse',
			'architecturedesign',

			'graphicdesign',			
			'architecturedesign',

			// second cycle
			'inuse',
			'style',

			'graphicdesign',
			'photo',

			'inuse',
			'shop',			

			'inuse',
			'art',

			'graphicdesign',
			'architecturedesign',

			'inuse',
			'style',

			'graphicdesign',
			'photo',

			'inuse',
			'spread',

			// third cycle

			'inuse',
			'architecturedesign',

			'graphicdesign',
			'art',

			'inuse',
			'shop',

			'graphicdesign',
			'art',

			'inuse',
			'architecturedesign',

		]



		mixedMaps.shop = shopMaps;
		mixedMaps.graphicdesign = graphicDesignMaps;
		mixedMaps.inuse = inUseMaps;
		mixedMaps.spread = spreadMaps.concat(spareMaps.spread || []);
		mixedMaps.spreadfit = spreadFitMaps.concat(spareMaps.spreadfit || []);
		mixedMaps.art = mixedMaps.art.concat(spareMaps.art ||[])
		mixedMaps.photo = mixedMaps.photo.concat(spareMaps.photo ||[])
		mixedMaps.architecturedesign = mixedMaps.architecturedesign.concat(spareMaps.architecturedesign || [])
		mixedMaps.style = mixedMaps.style.concat(spareMaps.style || [])



		let usingSpares = false;
		let mapIndex = 0;
		while(
			mixedMaps.shop.length > 0 ||
			mixedMaps.graphicdesign.length > 0 ||
			mixedMaps.inuse.length > 0 ||

			mixedMaps.architecturedesign.length > 0 ||
			mixedMaps.art.length > 0 ||			
			mixedMaps.photo.length > 0 ||
			mixedMaps.style.length > 0 ||

			mixedMaps.spread.length > 0 ||
			mixedMaps.spreadfit.length > 0

		) {

			const category = mapOrder[mapIndex%mapOrder.length]

			const map = mixedMaps[category]?.shift();
			if( map ){

				if( usingSpares){
					map.categoryViewOnly =true;
				}

				periodMaps.push(map);
				mapIndex++;


			// simply cycle to the next if we're building spares
			} else if ( usingSpares){

				mapIndex++;


			// break it off when we run out of inuse or graphicdesign items
			} else if (
				category === 'inuse' ||
				category === 'graphicdesign' 
			){

				// if we're out of graphicdesign, try swapping out for inuse or vice versa
				if( category ==='inuse' && mixedMaps.graphicdesign.length > 0 ){

					periodMaps.push(mixedMaps.graphicdesign.shift());

				} else if( category ==='graphicdesign' && mixedMaps.inuse.length > 0 ){

					periodMaps.push(mixedMaps.inuse.shift());

				// if we're out of both, start generating spares
				} else {
					usingSpares = true;
				}

				mapIndex++;

			// here we've run out of style/arch/photo/art
			// otherwise, skip ahead two in the cycle and try again				
			} else {
				mapIndex = mapIndex+2;
			}


		}


	}



	let cycleFloat = 0;
	let variationIndex = 0;


	let inuseFlows = [
		'center', 'left-up', 'center', 'right-up', 'inuse-spread', 'center', 'right-up', 'center', 'left-up', 'center', 'inuse-spread'
	]


	let layoutFlows = [
		'normal', 'reverse', 'normal','normal variation','reverse', 'reverse variation',
		'normal', 'normal', 'reverse', 'normal', 'reverse variation',
		'normal', 'reverse', 'normal','normal variation','reverse', 'reverse variation',
		'normal', 'reverse', 'normal variation', 'reverse', 'reverse variation', 'normal variation',
	];


	let inuseIndex = 0;
	let inuseWaitCounter = 0;

	let counter = 0;
	periodMaps.forEach((map, index)=>{


		let layoutFlow = layoutFlows[(counter+index)%layoutFlows.length];


	    switch(map.type){

		    case "spreadfit":

		    	const item = map.items?.[0];
		    	
		    	if( item){
		    		const ratio = item.media.width / item.media.height;

		    		if (ownProps.isMobile){
			    		if (ratio > 1 ) {
			    			map.subtype = 'bigover';
			    		} else {
			    			map.type = 'spread';
			    		}

		    		} else {
			    		if (ratio < .71){
			    			map.subtype = 'fitcolumn'
			    		} else if (ratio < .78) {
			    			map.subtype = 'over';
			    		} else {
							map.subtype = 'bigover';
			    		}		    			
		    		}

		    	} 

		    	if( layoutFlow.includes('normal') ){
		    		map.subtype +=' normal'
		    	} else {
					map.subtype +=' reverse'
		    	}

		    	counter++;

		    	break;


			case "double":	

				const mixedSubTypes = ownProps.isMobile ? ['mixed-overlap']:[
					'unequal-top-aligned',
					'center-aligned',
					'unequal-bottom-aligned',					
					'portrait-push',			
					'unequal-bottom-aligned',
					'portrait-dip',
				]

				map.subtype = mixedSubTypes[Math.floor(cycleFloat+counter)%mixedSubTypes.length];

				// these subtypes need to swap items since their layouts
				// naturally run opposite
			    switch(map.subtype){
				    case 'unequal-bottom-aligned':
				    case 'portrait-push':		    	
				    	map.items.unshift(map.items.pop())
					    break;

					case "mixed-overlap":

						if( layoutFlow.includes('variation')){
					    	map.items.unshift(map.items.pop())							
						}

						// normalize dimensions to same height
						map.items.forEach((item)=>{
						// item.media.width = 999
							// height over width - match it against an 'ideal' aspect ratio of 9/16 - mobile phone
							const yRatio = item.media.height / item.media.width;
							const scale = 1000 / item.media.height;
							item.yRatio =yRatio
							item.scale = {
								w: item.media.width * scale,
								h: item.media.height * scale,
							}
						});

						// then look at first item: how big does it need to have the same area as the second?
						const tallArea = map.items[1].scale.w * map.items[1].scale.h;

						const scale = Math.sqrt( tallArea / ( map.items[0].scale.w * map.items[0].scale.h) );

						// divide the two relative scales together to get 100%
						// tallArea is the reference so its scale is assumed to be 1
						const totalScale = 1 + scale;

						map.items[1].proportion = 1/totalScale
						map.items[0].proportion = scale/totalScale;


						map.items.forEach((item, index)=>{
							
							const minYRatio = item.proportion * (16/9);

							// a number smaller than minYRatio would not completely fill
							// the window
							// so we round its width to a percentage to get the overlap

							item.overlapWidth = Math.max(.5, Math.min(1, (
								Math.floor( minYRatio/item.yRatio * 4)/4 
							) ))


							item.scale = {
								w: item.media.width * scale,
								h: item.media.height * scale,
							}
						});

						if( map.items[0].overlapWidth== .5 && map.items[1].overlapWidth ==.5){
							map.disregardCaption = true;
						}



						break;
			    }

				map.subtype += ' ' +layoutFlow;


		    	if( layoutFlow.includes('variation') ){

					if ( (variationIndex+index)%3 == 0 ){
						map.subtype+= ' variation-small'
					}

					if( (counter+variationIndex) %2 == 0 ){
						map.subtype+= ' '+(['left-align', 'right-align'][index%2]);
					} else {
				    	if( layoutFlow.includes('normal') ){
				    		map.subtype+=' right-align'
				    	} else {
							map.subtype+=' left-align'
				    	}
					}

				}    	
		    	counter++;

		    	break;

	    }

	    switch(map.category){

		    case "inuse":

		    	let currentInuseFlow = inuseFlows[inuseIndex%inuseFlows.length];


		    	switch(currentInuseFlow){
			    	case "inuse-spread":
			    		map.subtype = 'inuse-spread';
			    		break;

			    	case "left-up":
			    		if( inuseWaitCounter%2==0 ){
		    				map.subtype = 'margin-bottom left';
		    			} else {
		    				map.subtype = 'margin-top right'
		    			}
			    		break;

			    	case "right-up":
			    		if( inuseWaitCounter%2==0 ){
		    				map.subtype = 'margin-top left';
		    			} else {
		    				map.subtype = 'margin-bottom right'
		    			}
			    		break;

			    	case "center":
			    		if( inuseWaitCounter%2==0 ){
		    				map.subtype = 'left';
		    			} else {
		    				map.subtype = 'right'
		    			}
		    			break;

		    		default:

		    			break;    		
		    	}



		    	let indexesToWait = 1;
		    	if( currentInuseFlow ==='inuse-spread'){
					indexesToWait = 0;
		    	}


		    	if( inuseWaitCounter >= indexesToWait){
		    		inuseWaitCounter = 0;
		    		inuseIndex++;
		    	} else {
			    	inuseWaitCounter++;		    		
		    	}
		    	break;




		    case "graphicdesign":

		    	if( map.items.length == 2){
				    map.subtype = ['default', 'default', 'uneven'][Math.floor(index+variationIndex)%3];	
		    	} else {
		    		map.subtype = map.items.length == 1 ? 'single' : 'quadruple'
		    	}

		    	map.subtype +=' '+layoutFlow;

		    	counter++;

			    break;


		    case "shop":

				map.subtype = layoutFlow;

		    	if(ownProps.isMobile){
		    		map.category ='shop-mobile'
		    	}				

		    	if( layoutFlow.includes('variation') ){

					if( (counter+variationIndex) %2 == 0 ){
						map.subtype+= ' '+(['left-align', 'right-align'][(counter+index)%2]);
					} else {
				    	if( layoutFlow.includes('normal') ){
				    		map.subtype+=' right-align'
				    	} else {
							map.subtype+=' left-align'
				    	}
					}

				}    

		    	counter++;

				break;			    
	    }


	    cycleFloat = counter%3 == 0 ? cycleFloat + Math.abs( Math.sin(counter*.3)*1.5 + 3 ) : cycleFloat+1
	    variationIndex = Math.floor(variationIndex + Math.abs( Math.sin(index*(index+1) )*2 + 4 ));

	});

	return {
		category,
		community: state.community,
		periodMaps,

		hasTemplates: state.homepageState.hasTemplates,
		loadingTemplates: state.homepageState.loadingTemplates,
	};

}

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		fetchCommunityPeriods     : actions.fetchCommunityPeriods,
		fetchTemplates           : actions.fetchTemplates,
		updateHomepageState      : actions.updateHomepageState,
	}, dispatch);

}


export default withRouter(connect(
	mapReduxStateToProps,
	mapDispatchToProps 
)(
	frontloadConnect(frontload, {
		onMount: true,
		onUpdate: false
	})(
		Community
	)
))
