import React, { useRef, useEffect, useCallback } from 'react';
import { bezierEasing, applyStyles } from './helpers';
import { useAnimationContext } from './animation-context';
import { animateStyles } from './animate-styles';

export const AnimatedElement = ({
    as: Component = 'div',
    initial,
    animate,
    transition = { duration: 1, delay: 0, ease: 'linear' },
    animation = {},
    animationKey,
    once = false,
    deferAnimation = false,
    resetAfterComplete = true,
    resetOnUnmount = true,
    onAnimationComplete = () => { },
    applyInitialStyles = false,
    children,
    ...props
}) => {
    const elementRef = useRef(null);
    const hasStartedRef = useRef(false);
    const {
        registerPotentialAnimation,
        startAnimation: startAnimationInContext,
        completeAnimation: completeAnimationInContext,
        inProgressAnimations,
        completedAnimations,
    } = useAnimationContext();

    // Merge props and animation object
    const initialStyles = animation.initial || initial;
    const animateStylesProps = animation.animate || animate;
    const transitionProps = animation.transition || transition;
    const performOnce = 'once' in animation ? animation.once : once;
    const deferAnimationConfig =
        'deferAnimation' in animation ? animation.deferAnimation : deferAnimation;
    const resetOnUnmountConfig =
        'resetOnUnmount' in animation ? animation.resetOnUnmount : resetOnUnmount;
    const resetAfterCompleteConfig =
        'resetAfterComplete' in animation ? animation.resetAfterComplete : resetAfterComplete;

    const animationComplete = useCallback(() => {
        if (animationKey) {
            completeAnimationInContext(animationKey);
        }
        if (resetAfterCompleteConfig && initialStyles) {
            // Reset to initial styles
            applyStyles(elementRef.current, initialStyles);
        }
        // Reset hasStartedRef to allow re-triggering if needed
        hasStartedRef.current = false;
        onAnimationComplete();
    }, [
        animationKey,
        completeAnimationInContext,
        resetAfterCompleteConfig,
        initialStyles,
        onAnimationComplete,
    ]);

    const startAnimation = useCallback(() => {
        if (!elementRef.current) return;

        // Apply initial styles
        if (initialStyles) {
            applyStyles(elementRef.current, initialStyles);
        }

        // Create easing function
        const easingFunction = Array.isArray(transitionProps.ease)
            ? bezierEasing(...transitionProps.ease)
            : (t) => t; // Default to linear

        // Trigger animation
        requestAnimationFrame(() => {
            animateStyles(
                elementRef.current,
                initialStyles,
                animateStylesProps,
                transitionProps.duration,
                transitionProps.delay * 1000,
                easingFunction,
                true,
                animationComplete
            );
        });
    }, [
        initialStyles,
        animateStylesProps,
        transitionProps,
        animationComplete,
    ]);

    useEffect(() => {
        if (animationKey) {
            registerPotentialAnimation(animationKey);
        }
    }, [animationKey, registerPotentialAnimation]);

    useEffect(() => {
        if (animationKey) {
            if (performOnce && completedAnimations.has(animationKey)) {
                return;
            }

            if (deferAnimationConfig) {
                // Wait until startAnimation is called externally
                if (inProgressAnimations.has(animationKey) && !hasStartedRef.current) {
                    hasStartedRef.current = true;
                    startAnimation();
                }
                // Else, waiting for external trigger
            } else {
                // Start animation immediately
                if (!inProgressAnimations.has(animationKey)) {
                    startAnimationInContext(animationKey);
                }
                if (!hasStartedRef.current) {
                    hasStartedRef.current = true;
                    startAnimation();
                }
            }
        }
    }, [
        animationKey,
        deferAnimationConfig,
        inProgressAnimations,
        startAnimationInContext,
        completedAnimations,
        performOnce,
        startAnimation,
    ]);

    useEffect(() => {
        return () => {
            if (resetOnUnmountConfig && initialStyles) {
                // Reset to initial styles
                applyStyles(elementRef.current, initialStyles);
            }
        };
    }, [resetOnUnmountConfig, initialStyles]);

    useEffect(()=> {
        if (applyInitialStyles && initialStyles) {
            applyStyles(elementRef.current, initialStyles);
        }
    }, [applyInitialStyles]);

    return (
        <Component ref={elementRef} {...props}>
            {children}
        </Component>
    );
};