import { actionTypes } from "../actions";
import { combineReducers } from 'redux';
import _ from "lodash";

export default function(state = [], action){

	if(!action.payload && !action.meta) {
		return state;
	}

	switch(action.type) {

		case actionTypes.FETCH_DELETED_SITES_FULFILLED: 

			if(action?.payload.data) {
				return action?.payload.data;
			}

		break;

		case actionTypes.DELETE_SITE_PENDING:

			if( action?.meta ){
				// Duplicate state
				let newState = [ ...state]
				// Find site obj in state
				let objIndex = newState.findIndex((item) => item.id === action?.meta.siteId );
				// If the site is already deleted ( in the collection ), remove it entirely.
				if( objIndex !== -1 && newState[objIndex].is_deleted === true ){
					// Permanent delete. Filter it out of the collection. 
					newState = newState.filter((item) => item.id !== state[objIndex].id )
					return newState
				}
				// If the site is not yet in the collection and is a Cargo 3 site,
				// add it to the collection.
				if( objIndex === -1 && action.meta.site?.version !== 'Cargo2' ){
					// Add site to the deleted collection.
					return [ action.meta.site, ...newState ]
				}

				return newState
			}

		break;

		case actionTypes.DELETE_SITE_FULFILLED:

			if( action?.payload.data ){
				// Delete a site...
				let newState = [ ...state]
				let objIndex = newState.findIndex((item) => item.id === action.payload.data.id || item.id === action.meta.siteId );
				// objIndex = objIndex === -1 ? newState.findIndex((item) => item.id === action.meta.siteId ) : objIndex;

				if( objIndex === -1 ){
					return state
				}

				// All Store mutation is now handled before the API result is returned in DELETE_SITE_PENDING.
				// Rather than modify anything, lets replace the current item with the server response just to
				// be sure everything is in proper sync if a soft delete took place.
				newState[objIndex] = action.payload.data;

				return newState 
				
			}

		break;

		case actionTypes.DELETE_SITE_REJECTED:
			// Handle failure by reversing change...
			if( action.payload && action.meta ){
				// Duplicate state
				let newState = [ ...state]
				// Find site obj in state
				let objIndex = newState.findIndex((item) => item.id === action?.meta.siteId );
				
				if( objIndex === -1 ){
					return state
				}

				if( _.isEqual(newState[objIndex], action.payload.data) ){
					return state
				} else {
					// Remove from deleted sites collection.
					newState = newState.filter((item) => item.id !== state[objIndex].id )

					return newState
				}

				return newState
				
			}

		break;

		case actionTypes.DELETE_ALL_SITES_PENDING:

			// Duplicate state
			let newState = [ ...state]
			// Find site obj in state
			let objIndex = newState.findIndex((item) => item.is_deleted === true );
			// If there are ANY deleted sites, dump all sites from the store.
			if( objIndex !== -1 ){
				// Permanent delete. Filter all deleted sites out of the collection. 
				newState = newState.filter((item) => item.is_deleted === false );

				return newState
			}

			return newState


		break;

	case actionTypes.DELETE_ALL_SITES_FULFILLED:

			if( action?.payload.data ){	

				let newState = [...state];

				// Clear everything 
				if( action.payload.data.length === 0 ){
					return action.payload.data
				}  

				return newState 
				
			}

		break;

		case actionTypes.RESTORE_SITE_FULFILLED:

			if( action?.payload.data ){
				// Update a site...
				let newState = [ ...state]
				let objIndex = newState.findIndex((item) => item.id === action.payload.data.id );

				if( objIndex === -1 ){
					return state
				}
				// Remove site from deleted sites collection.
				newState = newState.filter((item) => item.id !== state[objIndex].id )

				return newState

			}

		break;

	}

	return state;

};