import React, { Component } from 'react';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from "../actions";

import { Formik, Field } from 'formik';
import { basicFormikChangeListener as FormikChangeListener } from "@cargo/common";
import { Input, Button, SaveHelper, SaveCancelButtons } from '@cargo/ui-kit';

import { InUseImageUploader } from './inuse-image-uploader';

class FeaturedSiteDetailsEditor extends Component {

	constructor(props) {
		super(props);

		this.state = {
			isSaving: false,
			pendingChanges: false,
			transitionSaveOut: false,
			error: [],
		}

	}

	componentDidMount(){
		document.querySelector('body').style.overflow = "hidden"
	}

	componentWillUnmount(){
		document.querySelector('body').style.overflow = ""
	}

	closeWindow = () => {

		this.props.removeUIWindow(uiWindow => { 
			return uiWindow.id === 'inuse-details-editor';
		});

	}

	handleSaveBeforeClose = () => {

        if( !this.state.pendingChanges ){
			this.closeWindow();
			return;
		}

        this.props.Alert.openModal({
            header: 'Save changes?',
            type: 'confirm',
			confirmButton: 'Yes',
            denyButton: 'No',
            HotKeyProxy: this.props.HotKeyProxy,
            onConfirm: (options) => {
                window.dispatchEvent(new CustomEvent('save-before-navigation', {
                    detail: {
                        location: "/"
                    }
                }))
            },
            onDeny: (options) => {
				this.closeWindow();
            }
        });

    }

	removeSaveButton = () => {
        this.setState({ transitionSaveOut: true }, ()=>{
            setTimeout(() => {
                this.setState({ 
                    transitionSaveOut: false,
                    saveVisible: false
                }, ()=>{ 
                	this.setState({
						pendingChanges: false
					})
                })
            }, 151)
        })
    }

	setSaveVisbile = (bool) => {
		this.setState({ saveVisible: bool })
	}
	
	clearFormError = (key) => {
		// Remove error state on ANY change of field value
		let errorIndex = this.state.error.indexOf(key);

		if( errorIndex !== -1 ){

			let errorArr = [...this.state.error ]
			errorArr.splice(errorIndex, 1);

			this.setState({
				error: errorArr,
			})

		} 
	}

	addFormError = (key) => {
		let errorIndex = this.state.error.indexOf(key);

		if( errorIndex === -1 ){
			const newErrorArray = [...this.state.error];
			if( newErrorArray.indexOf(key) === -1 ){
				newErrorArray.push(key);
			}

			this.setState({
				error: newErrorArray,
			})
		}
	}
	
	saveChanges = async (form, formValues) => {
        
        if( this.state.isSaving ) {
            return;
        }

		this.setState({ isSaving: true }, async ()=> {

			if (formValues.delete_image) {
				await this.props.deleteInUseImage(this.props.site.id);
				form.setFieldValue('delete_image', false);
			}

			let changeObj = {
				website_title: formValues.inuse_website_title ? formValues.inuse_website_title.trim() : formValues.inuse_website_title,
				instagram_url: formValues.inuse_instagram_url ? formValues.inuse_instagram_url.trim() : formValues.inuse_instagram_url,
			}

			this.props.updateInUseSite(this.props.site.id, changeObj).then(() => {

				if( formValues.qued_image !== null ){
					const qued_image = formValues.qued_image;
					const saveImage = new CustomEvent('save-cargo-inuse-image', {
						detail: {
							image_to_upload: qued_image,
						}})
						document.dispatchEvent( saveImage );
						form.setFieldValue('qued_image', null);
				} else {
					this.finishSave();
				}

			}).catch((res) => {
                
                this.setState({ isSaving: false, pendingChanges: false })

                // Wait for other modal to close.
                setTimeout(() => {  
                    this.props.Alert.openModal({
                        header : 'Something went wrong.',
                        type: 'notice',
                        ignoreUnmount: true,
                        HotKeyProxy: this.props.HotKeyProxy
                    })
                }, 500)
    
            });
        })

    }

	onChange = (changedValues) => {
		
		const originalValues = { 
			inuse_website_title: this.props.site.inuse_website_title, 
			inuse_instagram_url: this.props.site.inuse_instagram_url,
			delete_image: false,
			qued_image: null,
		};

		const differingValues = this.compareAndRemoveEqual( changedValues, originalValues );

		_.each(differingValues, (value, key) => {
			this.clearFormError(key);
		});

		if (   differingValues.qued_image === null 
			|| differingValues.qued_image === false 
			|| differingValues.qued_image === undefined
		) {
			delete differingValues.qued_image;
		}

		if (	differingValues.delete_image === false
			|| differingValues.delete_image === null
			|| differingValues.delete_image === undefined
		) {
			delete differingValues.delete_image;
		}
		console.log(differingValues);
        this.setState({ 
			pendingChanges: _.isEmpty(differingValues) ? false : true,
			saveVisible: true
		})
	}

	compareAndRemoveEqual = ( formValues, originalValues ) => {

		let formValuesClone = { ...formValues };
		let originalValuesClone = { ...originalValues };

		formValuesClone.qued_image = formValuesClone.qued_image !== undefined && formValuesClone.qued_image !== false && formValuesClone.qued_image !== null;

        const differingValues = _.pickBy(formValuesClone, (value, key) => { return originalValuesClone[key] !== value });

        return differingValues;
    }

	finishSave = () => {

		this.setState({ isSaving: false, pendingChanges: false });
		// this.closeWindow();

	}

	render() {

		if( !this.props.site ){ return null }

		const CloseButton = () => {
			return <div className="close close-uiWindow">
						<Button
							onMouseDown={(e)=>{ 
								this.handleSaveBeforeClose()
							}}
							label={
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none">
									<circle opacity="var(--ui-close-circle-opacity)" cx="10" cy="10" r="10" fill="var(--ui-color-flat-reverse)"/>
									<path fillRule="evenodd" clipRule="evenodd" d="M10.0002 11.0607L14.3099 15.3703L15.3705 14.3096L11.0609 10L15.3705 5.69036L14.3099 4.6297L10.0002 8.93934L5.69054 4.62964L4.62988 5.6903L8.93958 10L4.62988 14.3097L5.69054 15.3704L10.0002 11.0607Z" fill="var(--ui-color-flat)"/>
								</svg>
							}
						/>
					</div>
		}

		const site = this.props.site;
		const siteName = site.inuse_website_title && site.inuse_website_title !== '' ? site.inuse_website_title : site?.website_title;
		const instagramUrl = site.inuse_instagram_url ? site.inuse_instagram_url : '';

		return (
			<>
			{ CloseButton() }
			{ this.props.HotKeyProxy("escape", "interface", (e) => this.handleSaveBeforeClose() ) }
			<window-header>
					<window-label label-size="large">
						<a href={site.direct_link} target="_blank">{site.display_url}</a>
					</window-label>
			</window-header>
			<window-content>

			<div className="ui-group">

				<Formik
					// this is important for websocket updating
					enableReinitialize
					// defaults are set here
					initialValues={{
						inuse_website_title: siteName,
						inuse_instagram_url: instagramUrl,
						qued_image: null,
						delete_image: false,
					}}

				>
				{props => (
				<>
					<FormikChangeListener onChange={this.onChange} />

					{this.props.HotKeyProxy("enter", "interface", (e) => { 
						if( this.state.pendingChanges ) { 
							// Utalizes the custom "save-before-navigation" event caught by the SaveHelper component above.
							// Normally event listeners don't have access to the most recent formik props. They are stale,
							// but the SaveHelper component is keeping track using refs, so we can use that.
							window.dispatchEvent(new CustomEvent('save-before-navigation', {
								detail: {
									location: null
								}
							}))
						} 
					})}

					<SaveHelper 
						form={props}
						formValues={props.values} 
						saveChanges={this.saveChanges}
					/>

					<Field
						component={Input}
						name="inuse_website_title"
						placeholder="Site name..."
						noBars={true}
						onBlur={()=> { 
							let inputValue = props.values['inuse_website_title'];
							props.setFieldValue('inuse_website_title', inputValue)
						}}
						className={`hover${this.state.error.indexOf('inuse_website_title') !== -1 ? ' error' : ''}`}
					/>
					<Field
						component={Input}
						name="inuse_instagram_url"
						placeholder="Instagram..."
						noBars={true}
						onBlur={()=> { 
							let inputValue = props.values['inuse_instagram_url'];
							props.setFieldValue('inuse_instagram_url', inputValue)
						}}
						className={`hover`}
					/>															

					<div className="uiWindow-spacer"></div>
					
					<InUseImageUploader 
						form={props}
						site={site} 
						user={this.props.user} 
						updateLocalSiteData={this.props.updateSiteLocal}
						deleteInUseImage={this.props.deleteInUseImage}
						finishSave={this.finishSave}
						setSaveVisbile={this.setSaveVisbile}
					/>
					{/* <div className="uiWindow-spacer"></div> */}

					<div className="uiWindow-spacer"></div>

					<SaveCancelButtons 
					        form={props}
							noTransition={true}
							saveDisabledIfNoChanges={true}
							// Functions
							closeWindow={this.closeWindow}
							saveChanges={this.saveChanges}
							// Dynamic Variables
							isSaving         = {this.state.isSaving}
							pendingChanges   = {this.state.pendingChanges}
							saveVisible      = {this.state.saveVisible}
							transitionSaveOut= {this.state.transitionSaveOut}
					/>
				</>
			)}
			</Formik>
		</div>
		</window-content>
		</>
        )

	}

}


function mapReduxStateToProps(state, ownProps) {

	const siteId = state.uiWindows.byId?.['inuse-details-editor']?.siteId ?? null;
	const site = _.find(state.sites, (site)=> { return site.id === siteId });

	return {
		folders: state.folders,
		sites: state.sites,
		templateFolders: state.templates,
		isMobile: state.homepageState.isMobile,
		user: state.account,
		siteId: siteId,
		site: site
	};

}

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		updateFolderItems: actions.updateFolderItems,
		updateSite: actions.updateSite,
		updateSiteLocal: actions.updateSiteLocal,
		removeUIWindow: actions.removeUIWindow,
		updateInUseSite: actions.updateInUseSite,
		deleteInUseImage: actions.deleteInUseImage,
	}, dispatch);

}

export default connect(
	mapReduxStateToProps,
	mapDispatchToProps 
)(FeaturedSiteDetailsEditor);
