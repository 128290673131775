import React, { useEffect, useRef, useState } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from "../../actions";
import { NavLink, withRouter } from 'react-router-dom';

import { SectionHeader } from "./section-header";
import { SiteScrollerNav } from "./site-scroller-nav";

// import { isProduction, isProductionTestingAcct } from "@cargo/common/helpers";
import _ from 'lodash'
import Site from "../site";

const SiteScroller = (
    {   //Data:
        section,
        folderId,
        xScrollRefs,
        progressSiteScroller,
        message,
        //Functions:
        pushSiteScroller,
        // Event Handlers:
        onSiteScrollerMouseDown,
        onSiteScrollerMouseUp,
        // From connect redux state to props...
        folders,
        sitePreview,
        authenticated,
        isMobile
    }) => {

    if (!folders || !folderId) {
        return null;
    }

    const [sitePreviewIdOnOpen, setSitePreviewIdOnOpen] = useState(null);
    const prevSitePreview = useRef(sitePreview);
    const observerRef = useRef(null);

    const [endOfScroll, setEndOfScroll] = useState(false);

    const scrollContainerRef = xScrollRefs[folderId];

    const folder = folders.find(folder => folder.id === folderId);
    const folderSites = folder?.sites;

    useEffect(() => {
        // Do something when sitePreview changes
        if (
            prevSitePreview.current !== null &&
            prevSitePreview.current?.containingFolderID === folderId &&
            prevSitePreview.current?.previewingSite === true &&
            sitePreview?.previewingSite === false
        ) {

            if (sitePreviewIdOnOpen === prevSitePreview.current?.previewSiteModel.id) {
                setSitePreviewIdOnOpen(null)
                return
            }

            setSitePreviewIdOnOpen(null)
        }

        if (sitePreview.previewingSite === true
            && prevSitePreview.current?.previewingSite === false
            && sitePreview.containingFolderID === folderId
        ) {
            setSitePreviewIdOnOpen(sitePreview.previewSiteModel.id);
        }

        // Track prevProps (prevSitePreview) for comparison in next render
        prevSitePreview.current = sitePreview;
    }, [sitePreview]);

    useEffect(() => {
        const scrollContainer = xScrollRefs[folderId]?.current;
        // Scroll container not always available. Return if it's not there yet.
        if (!scrollContainer) {
            return;
        }
        
        const scrollingEl = scrollContainer.querySelector('.site-scroller');
        const endScrollEl = scrollingEl ? scrollingEl.lastElementChild : null;

        if (!endScrollEl) {
            return;
        }

        // Clean up existing observer before creating a new one
        if (observerRef.current) {
            observerRef.current.disconnect();
        }

        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0];
                if (entry.isIntersecting) {
                    setEndOfScroll(true);
                } else {
                    setEndOfScroll(false);
                }
            },
            {
                root: scrollContainer,
                rootMargin: '0px',
                threshold: 1.0,
            }
        );

        observer.observe(endScrollEl);
        observerRef.current = observer;

        // Cleanup observer
        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
                observerRef.current = null;
            }
        };
    }, [xScrollRefs[folder.id]?.current, folderSites]);


    if (
        folder &&
        folderSites &&
        folderSites.length > 0
        // && section.rows.some((row)=>(isDev ? row.dev : row.live) === folder.id)
    ) {
        return (
            <div
                className="section"
                folder={folder.id}
                ref={scrollContainerRef}
            >
                <div className="positioner">
                    <SectionHeader folder={folder} authenticated={authenticated} />
                    <div
                        className={`${`row`
                            + `${folder.is_idea ? ' idea' : ''}`
                            + `${folder.is_element ? ' element' : ''}`
                            + `${folder.is_community ? ' community' : ''}`
                            }`}
                        key={`folder-${folder.id}`}
                        row-id={folder.id}
                    >

                        {((folder.is_feed || folderSites.length >= 5) && !endOfScroll) && !isMobile ? (
                            <SiteScrollerNav
                                pushSiteScroller={pushSiteScroller}
                                folder={folder}
                            />
                        ) : null}

                        <div
                            className={`site-scroller siteslist`}
                            onMouseDown={onSiteScrollerMouseDown}
                            onMouseUp={onSiteScrollerMouseUp}
                            folder-id={folder.id}
                        >
                            {folderSites?.map((siteModel, index) => {

                                // Only return first batch of paginated sites in x-row.
                                if (index > 99) { return null }

                                const siteUnavailable = siteModel?.is_coming_soon ?? false;

                                if (siteModel) {
                                    return (
                                        <Site
                                            key={`site-${siteModel.id}-${folder.id}-item`}
                                            index={siteModel.id}
                                            site={siteModel}
                                            containingFolderID={folder.id}
                                            onSiteScrollerMouseUp={onSiteScrollerMouseUp}
                                            progressSiteScroller={siteModel.id === progressSiteScroller}
                                            message={message}
                                            siteUnavailable={siteUnavailable}
                                            imageQualityRes={'75'}
                                            imageQualityWidth={'1500'}
                                        />
                                    )
                                }
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return null;
    }

}

function mapReduxStateToProps(state, ownProps) {

    return {
        folders: state.templates,
        sitePreview: state.sitePreview,
        authenticated: state.auth.authenticated,
        isMobile: state.homepageState.isMobile
    };

}

// function mapDispatchToProps(dispatch) {
//     return bindActionCreators({
//         updateHomepageState: actions.updateHomepageState,
//     }, dispatch);
// }

export default withRouter(connect(
    mapReduxStateToProps,
    // mapDispatchToProps
)(SiteScroller))