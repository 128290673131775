// import React, { useState, useEffect } from 'react';
// import { useAnimationPath, useAnimationContext } from "@cargo/common/animation-context";

const BrowserBar = ({
    displayURL = "https://cargo.site"
}) => {

    return (
        <div className="browser-bar">
            <span className="browser-window-button"/>
            <span className="browser-window-button"/>
            <span className="browser-window-button"/>

            <span className="address-bar">
                {displayURL}
            </span>
        </div>
    );
};

export default BrowserBar;