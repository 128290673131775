import React, { useEffect, useContext } from 'react';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from "../actions";
import { useHistory, useLocation } from "react-router-dom";
import { Alert, AlertContext } from "@cargo/ui-kit";
import { HotKeyProxy } from "./ui-kit";
import { ContextMenuButton, ContextSubMenu, ContextMenuCheckbox } from "@cargo/common/context-menu";
import { filterSites } from '../selectors';
import { helpers } from '@cargo/common';
import FeaturedSiteDetailsEditor from './inuse-details-editor';
import Loadable from '@cargo/loadable-components'
import { SiteDuplicationContext } from './site-duplication-provider';


let loadingTemplates = false;

const SiteContextUI = props => {

    const { handleSiteDuplication } = useContext(SiteDuplicationContext);

	useEffect(() => {
        return () => { props.onClose() };
    }, []);

	if(!loadingTemplates) {
		loadingTemplates = true;
		props.fetchTemplates().catch(() => {
			loadingTemplates = false;
		})
	} 

	let folders = props.folders;
	let location = useLocation();
		location = location.pathname.substring(1); // Strip the /
	let folder = _.find(folders, ['slug', location]); // find folder via route name
		folder = folder === undefined || folder === null ? _.find(folders, ['name', 'All']) : folder;

	const site = props.site;
	let isMobile = props.isMobile;

	let allFolderIds = [];
		allFolderIds = folders.map(folder => allFolderIds.push(folder.id) );

	let userRole = props.userToSite?.role;
	let savedFolder = folders.filter(folder => folder.id === props.savedFolderID)[0]
	// let savedFolder = folders.filter(folder => folder.name.toLowerCase() === 'saved')[0]
	let isViewingSavedFolder = savedFolder && location === 'saved';

	const removeAndDeleteSite = (site) => {

		props.deleteSite(site.id).then((data)=>{

			// do things

		}).catch(e => {

			console.warn('Site delete failed')

		});
	}

	// re-bind actions 
	const restoreSite  = props.restoreSite;
	const updateSite   = props.updateSite;
	const createFolder = props.createFolder;
	const leaveSite    = props.leaveSite;
	const fetchAccount = props.fetchAccount;

	let link = props.site.direct_link
	link = helpers.isLocalEnv ? link.replace('dev.cargo.site', 'local.dev.cargo.site') : link;
	let publicLink = link;
	link = props.site.version === 'Cargo2' ? link+'/admin' : link+'/edit';
	link = userRole === 'Viewer' ? link+'/preview' : link;

	let history = useHistory();

	let CopyButton = () => {
		return (
			<ContextMenuButton 
				onPointerUp={(e) => { 
					if (props.copyShortCode) {
						props.copyShortCode()
					} 
				}} 
				label="Copy" 
			/>
		)
	}

	let DuplicateTemplateButton = () => {
		return (
			<ContextMenuButton 
				onPointerUp={(e) => { 
					handleSiteDuplication( site.id );
				}} 
				label="Duplicate" 
			/>
		)
	}

	let SaveButton = () => {

		let savedFolderSites = savedFolder.sites;
		let isSaved = savedFolderSites?.find((savedSite)=>{ return savedSite.site_id === site.id });

		return (
				<ContextMenuButton
					label={isSaved ? 'Remove from Saved' : 'Add to Saved'}
					key={'save-site'}
					id={'save-site'}
					onPointerUp = {() => {
						if( isSaved ){
							props.updateFolderItems( savedFolder, site, 'remove'); 
						} else {
							props.updateFolderItems( savedFolder, {site_id: site.id}, 'add');
						}
		            	// history.push('/saved/' + site.id)
		            }}
				/>
		)
	}

	let DuplicateButton = (addToAll) => {
		return (
			<ContextMenuButton 
				onPointerUp={(e) => { 
					handleSiteDuplication( site.id );
				}} 
				label="Duplicate" 
			/>
		)
	}

	let UpgradeButton = () => {
		return(
			<ContextMenuButton 
				onPointerUp={(e) => { 
					history.push('/account/upgrade/' + site.id, {
						preventScroll: true
					})
				}}
				label="Upgrade" 
			/>	
		)
	}

	let EditButton = () => {
		if( userRole === 'Inuse' ) {
			return null
		}
		return (
			<ContextMenuButton 
				label={userRole !== 'Viewer' ? site.version === 'Cargo2' ? 'Edit in Cargo 2' : 'Edit' : 'View Draft'}
				onPointerUp = {
					(e) => {
						if (e.metaKey === true) {
							window.open(link, '_blank');
						} else {
							window.location = link;
						}
						return false;
					}
				}
			/>	
		)
	}

	let CopyLinkButton = () => {
		return (
			<ContextMenuButton 
				label="Copy Link"
				onPointerUp = {
					() => {
						navigator.clipboard.writeText( publicLink ).then(function() {
						  // console.log('Async: Copying to clipboard was successful!');
						}, function(err) {
						  console.error('Async: Could not copy text: ', err);
						});
					}
				}
			/>	
		)
	}

	let AddToTemplatesFolderSubmenu = () => {

		return (
			<AlertContext.Consumer>
				{(Alert) => 
					<ContextSubMenu 
						label="Send to In Use"
						layer={2}
						getdimensions={ props.getdimensions }
					>
					{props.templateFolders?.map((folder, index) => {

						if (!Array.isArray(folder.sites)) {
							return;
						}

						const siteInTemplateFolder = ( folder?.sites.find(({ id }) => id === site.id) && ( folder.name === "Artist Spotlight" || folder.name === "Designer Spotlight" ) );

						const existingFolderItem = siteInTemplateFolder ? true : false;

						if (
							folder.name !== "Sites in Use" &&
							folder.name !== "Artist Spotlight" &&
							folder.name !== "Designer Spotlight"
						) {
							return null;
						}

						return ( 
							<ContextMenuCheckbox
								label={folder.name}
								key={folder.name+index}
								id={folder.name+index}
								value={ existingFolderItem }
								onPointerUp = {() => {
									if( existingFolderItem ) {
										// Remove from folder
										props.updateInUseFolder( site.id, folder.id, 'remove' ).then(()=> {
											props.fetchTemplates();
										})
									} else {
										props.updateInUseFolder( site.id, folder.id, 'add' ).then(()=> {
											props.fetchTemplates()
										})
									}
								}}
							/>
						)

					})}
					</ContextSubMenu>
				}
			</AlertContext.Consumer>
		)

	}

	let AddToFolderSubmenu = () => {
		return (
			<ContextSubMenu 
				label="Add to folder"
				layer={1}
				getdimensions={ props.getdimensions }
			>
				{ userRole === 'Inuse' ? (<> {AddToTemplatesFolderSubmenu()} <hr/> </>) : null }
				{folders.map((folder, index) => {

					const existingFolderItem = folder.sites.find(({site_id}) => site_id === site.id);
					
					return folder.name !== 'All' && folder.name.toLowerCase() !== 'saved' && <ContextMenuCheckbox
						label={folder.name}
						key={folder.name+index}
						id={folder.name+index}
						value={ existingFolderItem !== undefined }
						onPointerUp = {() => {
			            	if(existingFolderItem !== undefined) {
			            		// Remove from folder
			            		props.updateFolderItems( folder, site, 'remove');
			            	} else {
								// Add to folder
								props.updateFolderItems( folder, {site_id: site.id}, 'add');
			            	}
			            }}
					/>

				})}

				{ !props.hasUserFolders ? ( null ) : ( <hr/> )} 

				<AlertContext.Consumer>
				    {(Alert) => <ContextMenuButton
				    	label={"New Folder"}
				        onPointerUp = {
				            (e) => {
				            	Alert.openModal({
				            		header: 'New Folder',
				            		type: 'dialog',
				            		placeholder: 'Folder title...',
				            		focusInput: true,
				            		ignoreUnmount: true,
				            		HotKeyProxy: HotKeyProxy,
				            		onConfirm: ( folderName ) => {
				
						            	if( folderName ){

				            				createFolder( folderName ).then((response) => {
				            					
				            					if( !response?.data?.sites ) { 
				            						console.warn("There was an issue with folder creation.")
				            						return
				            					}

				            					let newFolderSitesArray = _.cloneDeep( response.data.sites );

				            					// Add the site with a sort index of 0
				            					newFolderSitesArray.push({
				            						site_id: site.id,
				            						sort: 0
				            					});

				            					props.updateFolder({
				            						...response.data,
				            						sites: newFolderSitesArray
				            					}).then(()=>{

				            						let route = "/"+response.data.slug
				            						history.push( route );

				            					});


				            				})

						            	}

			            			}
			            		})
				        	}
				    	}></ContextMenuButton>
				    }
				</AlertContext.Consumer>
			
			</ContextSubMenu>	
		)
	}

	let UsersButton = () => {
		return (
			<ContextMenuButton 
				onPointerUp={ ( event ) => {
					props.addUIWindow({
						group: 'right-menu-bar',
						component: Loadable({ loader: () => import('./editors-window')}),
						id: `editors-window`,
						props: {
							type: 'popover', 
							positionType: 'center', 
							windowName: 'editors',
							clickoutLayer: true,
							clickoutLayerDim: true,
							draggingUploadedImage: false,
							disableDragging: false,
							waitForHeightBeforeRender: true,
							minimumRenderHeight: 40,
							siteId: site.id,
							siteVersion: site.version,
						}
					})
				}}
				label="Users" 
			/>	
		)
	}

	const UpgradeTransferSelect = () =>{

		const unUpgradedSites = filterSites(props.sites, {
			deleted: false,
			upgraded: false
		});
		let selectValue = getSessionStorageSelectValue();
		return(
			// <Formik
			// // this is important for websocket updating
			// enableReinitialize
			// // defaults are set here
			// >
			// {props => (
			// 	<>
			// 		<FormikChangeListener />
						<select className="transfer-select" onChange={(e)=>{ saveSessionStorage( parseInt(e.target.value) ) }}>
							<option key={`transfer-to`} disabled selected>Transfer upgrade to...</option>
							<option key={`no-transfer`} value="no-transfer">Don’t transfer my upgrade</option>
							<hr/>
							{unUpgradedSites.map((site, index)=>{
								return(
									<option selected={selectValue === site.id ? true : null} key={site.id} value={site.id}>{site.website_title} — {site.display_url}</option>
								)
							})}
						</select>
			// 	</>
			// 	)}
			// </Formik>
		)
	}

	const getSessionStorageSelectValue = () => {
	    const sessionState = window.store.getState().homepageState;
	    return sessionState?.['transferSelectValue'] ?? null;
	}

	const saveSessionStorage = (selectValue) => {
		props.updateHomepageState({'transferSelectValue': selectValue })
	}

	const deleteC2SiteWithUpgrade = ( Alert, site ) => {
		props.deleteSite(site.id).then((data)=>{
			Alert.closeModal();
			saveSessionStorage(null);
		}).catch(e => {
			console.warn('Site delete failed')

		});
	}

	const handleUpgradeTransfer = (Alert, site) => {
		let selectValue = document.querySelector('select.transfer-select')?.value;
		let transferSubscriptionToId = selectValue;
		let transferSubscriptionFromId = site.id;
		// We do not want to transfer if the ID does not evaluate to a number.

		if( isNaN( parseInt( selectValue ) ) ){
			// Delete the site
			removeAndDeleteSite( site );
			// Close the modal.
			Alert.closeModal();
			saveSessionStorage(null);
		} else {
			// Get site subscriptions. 
			props.fetchSiteSubscriptions().then(()=>{
				// Transfer site subscription after we have the collection.
				props.transferSubscription( transferSubscriptionToId, transferSubscriptionFromId ).then(()=>{
					// Delete the site
					removeAndDeleteSite( site )
					// Fetch user acc so everything is up to date w/ server
					fetchAccount().then(()=>{
						// Close modal
						Alert.closeModal();
						saveSessionStorage(null);
					}).catch((err)=>{
						transferError(Alert)
					})
				}).catch((err)=>{
					transferError(Alert)
				})
			}).catch((err)=>{
				transferError(Alert)
			})

		}
	}

	const transferError = (Alert) => {
		Alert.closeModal();
		saveSessionStorage(null);
		setTimeout(()=>{
			Alert.openModal({
				header: 'Something went wrong, please refresh and try again.',
				type: 'notice',
				HotKeyProxy: HotKeyProxy,
				onConfirm: (options) => {
				},
			})
		}, 500)
	}

	let DeleteButton = () => {

		let requireConfirmation = site.is_upgraded || site.version === 'Cargo2';

		const hasUnUpgradedSites = filterSites(props.sites, {
			deleted: false,
			upgraded: false
		}).length > 0;

		return(
			<>
				<hr/>
				<AlertContext.Consumer>
					{Alert => <ContextMenuButton 
						label={`${requireConfirmation ? (site.version === 'Cargo2' ? 'Delete' : 'Move to Trash') : 'Move to Trash'}`}
						onPointerUp = { e => { 

							if (site.is_upgraded && site.version === 'Cargo2') {

								Alert.openModal({
									type: 'slide-confirm',
									header: site.display_url,
									htmlMessage: `This site has a Cargo 2 Upgrade. Deleting this site will cancel this Upgrade. To manage your Cargo 2 Upgrades go <a class="alert-action-link" href="https://account.cargo.site/admin/account/site-upgrades" target="_blank">here</a>.`,
									confirmButton: 'Delete',
									ignoreUnmount: true,
									HotKeyProxy: HotKeyProxy,
									// closeManually: true,
									// preventClose: true,
									onConfirm: () => { deleteC2SiteWithUpgrade(Alert, site) }
								});

							} else {

								// Delete non-upgraded C3 sites immediately
								if (site.version === 'Cargo3') {
									removeAndDeleteSite( site )
								} else {
									Alert.openModal({
										type: 'slide-confirm',
										header: site.display_url,
										message: `Are you sure you want to delete this site and all its contents?`,
										ignoreUnmount: true,
										HotKeyProxy: HotKeyProxy,
										// closeManually: true,
										// preventClose: true,
										onConfirm: () => { handleUpgradeTransfer(Alert, site) }
									});
								}

							}

							
						} }
					/>}
				</AlertContext.Consumer>
			</>
		)
	}

	let LeaveButton = () => {
		const label = userRole !== 'Inuse' ? `Leave Site...` : `Remove...`;
		return (
			<>
				<hr/>
				<AlertContext.Consumer>
					{Alert => <ContextMenuButton 
						label={label}
						onPointerUp = { e => { 

							if( userRole === 'Inuse' ){

								Alert.openModal({
									header: 'Remove?',
									type: 'confirm',
									ignoreUnmount: true,
									HotKeyProxy: HotKeyProxy,
									onConfirm: () => {
										leaveSite( site.id ).then(()=>{
											fetchAccount();
										})
									}
								})

								return
							}

	            			Alert.openModal({
			            		header: 'Leave Site?',
			            		type: 'confirm',
			            		ignoreUnmount: true,
								HotKeyProxy: HotKeyProxy,
			            		onConfirm: () => {
									leaveSite( site.id ).then(()=>{
										fetchAccount();
									})
		            			}
	            			})
							
						} }
					/>}
				</AlertContext.Consumer>
			</>	
		)
	}

	let ActiveSiteUI = () => {
		return(
			<>
				{userRole === 'Inuse' && !props.isTemplates ? ( 
					<>
						<ContextMenuButton 
						label="Edit In Use details"
						onPointerUp={ ( event ) => {
								props.addUIWindow({	
									component: <FeaturedSiteDetailsEditor />,
									id: `inuse-details-editor`,
									siteId: site.id,
									props: {
										type: 'popover', 
										positionType: 'center', 
										windowName: 'inuse-details-editor',
										className: `inuse-details`,
										ignoreClickout: true,
										clickoutLayer: false,
										clickoutLayerDim: true,
										preventClickout: true,
										closeButton: false,
										draggingUploadedImage: false,
										width: 580,
										waitForHeightBeforeRender: true,
										minimumRenderHeight: 100,
										disableDragging: false,
										enableDragging: false,
										site_abc: site,
									}
								}, { removeGroup: false });
						
								props.updateHomepageState({activeWindow: 'inuse-details-editor'})
							}
						}
						/>	
					</> 
				) : ( null )}
				{props.isTemplates ? (

					<>
						{ (!isMobile && props.canCopy) && CopyButton() }
						{ (!isMobile && props.canDuplicate) && DuplicateTemplateButton() }
						{ CopyLinkButton() }
						{ props.authenticated && savedFolder ? ( 
							<>
								{ !props.isIdea ? ( <> <hr/>{ SaveButton() } </> ) : ( null ) }
							</>
						) : ( null )} 

					</>

				) : ( 

					<>

						{ !isMobile && EditButton() }
						{ !isMobile && userRole !== 'Inuse' ? <hr/> : null }
						
						{ ( userRole !== 'Viewer' && userRole !== 'Inuse' && !isMobile ) && <>{DuplicateButton()}</> }

						{ AddToFolderSubmenu() }
						{ CopyLinkButton() }
						{ ( userRole !== 'Viewer' && userRole !== 'Inuse' ) && UsersButton() }

						{/* { !site.is_upgraded && userRole === 'Admin' && <><hr/>{UpgradeButton()}</> } */}
						{site.version === 'Cargo3' && (<>
							{ userRole === 'Admin' ? 
								DeleteButton()
							:
								LeaveButton()
							}
						</>)}

						{site.version === 'Cargo2' && (<>
							{ userRole !== 'Inuse' ? 
								DeleteButton()
							:
								LeaveButton()
							}
						</>)}

					</>

				)}

			</>
		)
	}

	let SavedSiteUI = () => {
		return(
			<>
				{ props.canCopy && CopyButton() }
				{ props.canDuplicate && DuplicateButton(true) }
				{ CopyLinkButton() }
				{ <hr/> }

				<ContextMenuButton 
					label="Remove from Saved"
					onPointerUp = {
						() => {
							props.updateFolderItems( savedFolder, site, 'remove');
						}
					}
				/>	

			</>
		)
	}


	return (
		<>
			{ !site.is_deleted ? (
				<>{ isViewingSavedFolder ? 
					SavedSiteUI() : ActiveSiteUI()
				}</>
			) : (
				<>
					<ContextMenuButton 
						label="Restore" 
						onPointerUp = { e => { 

							restoreSite( site.id ).then(()=> {
								history.push("/")
							})

						}}
					/>

					<AlertContext.Consumer>
						{Alert => <ContextMenuButton 
							label={`Permanently Delete`} 
							onPointerUp = { e => { 

								Alert.openModal(
									!site.is_upgraded 
									? {
										type: 'slide-confirm',
										header: site.display_url,
										message: 'Are you sure you want to delete this site and all its contents?',
										ignoreUnmount: true,
										HotKeyProxy: HotKeyProxy,
										onConfirm: () => {
											removeAndDeleteSite( site )
										}
									} : {
										type: 'slide-confirm',
										header: site.display_url,
										message: `Are you sure you want to delete this site and all its contents?`,
										ignoreUnmount: true,
										HotKeyProxy: HotKeyProxy,
										// subMessage: `Please note: ...`,
										onConfirm: () => {
											removeAndDeleteSite( site )
										}
									}
								);

							} }
						/>}
					</AlertContext.Consumer>
				</>
			)}

		</>
	);

};

function mapReduxStateToProps(state, ownProps) {
	return {
		folders: state.folders,
		sites: state.sites,
		templateFolders: state.templates,
		isMobile: state.homepageState.isMobile,
		user: state.account,
		deletedSites: state.deletedSites,
		savedFolderID: state.account?.saved_folder_id,
		subscriptions: state.subscriptions,
		hasUserFolders: state.folders.length > 2
	};
}

function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		addUIWindow: actions.addUIWindow,
		updateFolder: actions.updateFolder,
		updateInUseFolder: actions.updateInUseFolder,
		updateFolderItems: actions.updateFolderItems,
		restoreSite: actions.restoreSite,
		updateSite: actions.updateSite,
		deleteSite: actions.deleteSite,
		removeSiteLocally: actions.removeSiteLocally,
		createFolder: actions.createFolder,
		syncAfterSiteDuplication: actions.syncAfterSiteDuplication,
		fetchTemplates: actions.fetchTemplates,
		leaveSite: actions.leaveSite,
		fetchAccount: actions.fetchAccount,
		fetchSiteSubscriptions: actions.fetchSiteSubscriptions,
		transferSubscription: actions.transferSubscription,
		updateHomepageState: actions.updateHomepageState,
	}, dispatch);

}

export default connect(
	mapReduxStateToProps,
	mapDispatchToProps 
)(SiteContextUI);