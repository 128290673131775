import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import SortableFolder from './sortable-folder';
import _ from 'lodash';
import { actions } from "../../actions";
import { closestCenter, DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';

export default ({ folders, isMobile }) => {

    const [activeId, setActiveId] = useState(null);
    const [overId, setOverId] = useState(null);
    const [items, setItems] = useState(folders);
    const [disableDragging, setDisableDragging] = useState(false);

    const [scrollingDirection, setScrollingDirection] = useState(null);
    const scrollIntervalRef = useRef(null);

    const dispatch = useDispatch();

    useEffect(() => {
        setItems(folders);
    }, [folders]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey) {
                setDisableDragging(true);
            }
        };

        const handleKeyUp = (event) => {
            if (!event.ctrlKey) {
                setDisableDragging(false);
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, []);

    useEffect(() => {
        const handlePageScrollRegions = (event) => {
            const clientY = event.clientY;
            const nearTop = clientY < 55;
            const nearBottom = clientY > window.innerHeight - 55;

            if (nearTop && !scrollIntervalRef.current) {
                scrollContainer(-10, 'up');
                setScrollingDirection('up');
                return;
            }

            if (nearBottom && !scrollIntervalRef.current) {
                scrollContainer(10, 'down');
                setScrollingDirection('down');
                return;
            }

            if (
                scrollIntervalRef.current &&
                ((!nearTop && scrollingDirection === 'up') ||
                    (!nearBottom && scrollingDirection === 'down'))
            ) {
                clearScrollInterval();
                scrollIntervalRef.current = null;
                setScrollingDirection(null);
                return;
            }
        };

        if (activeId !== null) {
            // Add event listener when activeId is set (dragging starts)
            document.addEventListener('pointermove', handlePageScrollRegions);
        } else {
            // Remove event listener when activeId is null (dragging ends)
            document.removeEventListener('pointermove', handlePageScrollRegions);
            clearScrollInterval(); // Also clear the interval if dragging stops
        }

        // Clean up the event listener when the component unmounts or activeId changes
        return () => {
            document.removeEventListener('pointermove', handlePageScrollRegions);
            clearScrollInterval();
        };
    }, [activeId, scrollingDirection]);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 4
            }
        }),
    );

    const handleDragStart = (event) => {
        const { active } = event;
        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    setActiveId(active.id)
                });
            });
        });
        // setActiveId(active.id);
    };

    const handleDragOver = (event) => {
        const { over } = event;
        setOverId(over ? over.id : null);
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;

        setOverId(null);

        if (!event || activeId === null || over === null) {
            setActiveId(null);
            return;
        }

        if (active.id !== over.id) {
            setItems((items) => {

                const oldIndex = items.findIndex(x => x.id === active.id);
                const newIndex = items.findIndex(x => x.id === over.id);
                const result = arrayMove(items, oldIndex, newIndex);

                dispatch(
                    actions.saveFolderSort(
                        _.map(result, 'id')
                    )
                );

                return result;
            });
        }

        setActiveId(null); // Reset activeId to stop dragging
    };

    const scrollContainer = (amount, direction) => {
        scrollIntervalRef.current = setInterval(() => {
            document.querySelector('.left-menu-bar .inner').scrollBy({left: 0, top: amount, behavior:'smooth'});
        }, 30);
        setScrollingDirection(direction);
    };

    const clearScrollInterval = () => {
        if (scrollIntervalRef.current) {
            clearInterval(scrollIntervalRef.current);
            scrollIntervalRef.current = null;
        }
    };

    // Define activeIndex and overIndex using the current state
    const activeIndex = activeId ? items.findIndex(item => item.id === activeId) : -1;
    const overIndex = overId ? items.findIndex(item => item.id === overId) : -1;

    return (
        <>
            {items && <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                modifiers={[restrictToParentElement, restrictToVerticalAxis]}
                onDragStart={handleDragStart}
                onDragOver={handleDragOver}
                onDragEnd={handleDragEnd}
                autoScroll={false}
            >
                <SortableContext
                    items={items}
                    strategy={horizontalListSortingStrategy}
                >
                    {items.map((item, index) => {
                        let lowerCaseName = item.name.toLowerCase();
                        if( lowerCaseName === 'all' || lowerCaseName === 'saved' ){
                            return null;
                        }
                        return (
                            <SortableFolder
                                isDisabled={item.name === 'All' || isMobile || disableDragging}
                                item={item}
                                key={item.id}
                                id={item.id}
                                index={index}
                                isOver={overId === item.id && activeId !== item.id}
                                isGhost={activeId === item.id}
                                activeIndex={activeIndex}
                                overIndex={overIndex}
                            />
                        )
                    })}
                </SortableContext>
            </DndContext>}
        </>
    );
}