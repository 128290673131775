import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions } from "../../actions/index.js";
import _ from 'lodash'
import { NavLink, withRouter } from 'react-router-dom';
import { helpers } from "@cargo/common";
import { TemplateGroups } from "./templates-config.js";
import SiteScroller from './site-scroller.js';
import { ElementScrollEffect } from '@cargo/ui-kit';

let isDev = CARGO_ENV !== 'production';

class SiteScrollerHandler extends Component {

	constructor(props) {

		super(props);

		this.state = {
			progressSiteScroller: null,
			xScrollRefs: [],
		}

		this.pushItemIndex = null;

		this.showPenguin = false;

	}

	setupSiteScrollerRefs = () => {

		if (!this.props.activeFolder && this.props.folders?.length > 0) {
	
			let xScrollRefs = {};
	
			this.props.folders?.forEach(folder => {
				if (folder) {
					xScrollRefs[folder.id] = React.createRef();
				}
			});
	
			this.setState({ xScrollRefs: xScrollRefs });
	
		} else {
			this.setState({ xScrollRefs: {} });
		}
	}

	componentDidUpdate(prevProps, prevState) {

		if (prevProps.folders !== this.props.folders && this.props.folders.length > 0) {
			this.setupSiteScrollerRefs();
		}

	}

	componentDidMount = () => {
		if (this.props.folders.length > 0) {
			this.setupSiteScrollerRefs();
		}
	}

	componentWillUnmount = () => {

	}	

	onSiteScrollerMouseDown = (e) => {
		// let xScrollElParent = this.state.xScrollRefs[parseInt(e.currentTarget.getAttribute('folder'))]?.current;
		let xScrollEl = this.state.xScrollRefs[parseInt(e.currentTarget.getAttribute('folder-id'))]?.current

		if (xScrollEl?.classList.contains('slow-scroll')) {
			cancelAnimationFrame(this.slowScrollAnimFrame)
			this.slowScrollAnimFrame = undefined;
		}

		if ( e.button === 2 ) {
			return;
		}
		
	}

	onSiteScrollerMouseUp = (e) =>{
		// this.clearSiteScrollerScrubbing();
	}

	getNextSiteScrollerItem = (xScrollEl, forceEl, reverse = false) => {
		let xScrollPaddingLeft = parseInt(window.getComputedStyle(xScrollEl, null).getPropertyValue('padding-left')) + parseInt(window.getComputedStyle(xScrollEl.closest('.row'), null).getPropertyValue('margin-left'));
		let gridColumnGap = window.getComputedStyle(xScrollEl).getPropertyValue('grid-column-gap');
		let inlinePaddingSnapStart = window.getComputedStyle(xScrollEl).getPropertyValue('scroll-padding-inline-start');
		let xScrollAlignment = parseFloat(inlinePaddingSnapStart) || parseFloat(gridColumnGap) - 2 || 0;
		let xScrollRect        = xScrollEl.getBoundingClientRect();
		let xScrollChildEls    = Array.from(xScrollEl.children)
		let nextSiteEl         = null;

		if (!forceEl) {

			for ( const index in xScrollChildEls ){
				const siteEl = xScrollChildEls[index];
				const siteElBounds = siteEl.getBoundingClientRect();
				const siteLeft = parseInt(siteElBounds.left);
				const siteRight = siteLeft + parseInt(siteElBounds.width);

				if ( siteRight >= xScrollRect.right && !reverse ) {
					nextSiteEl = siteEl;
					break;
				}

				if( index >= xScrollChildEls.length - 1 ){
					nextSiteEl = siteEl;
					break;
				}
			}

			

		} else {
			nextSiteEl = forceEl;
		}

		let xScrollElLeft    = xScrollEl.getBoundingClientRect().left;
		let nextSiteElBounds = nextSiteEl ? nextSiteEl.getBoundingClientRect() : null;
		let nextSiteElLeft = nextSiteElBounds ? parseInt(nextSiteElBounds.left) - xScrollElLeft : 0;
		let spaceBetweenSites = parseInt( gridColumnGap ) - 1;
		return {el: nextSiteEl, left: nextSiteElLeft - xScrollPaddingLeft - xScrollAlignment + spaceBetweenSites};

	}

	pushSiteScroller = ( pushURL, folderID ) => {
		let xScrollElParent = this.state.xScrollRefs[parseInt(folderID)]?.current;
		let xScrollEl = xScrollElParent.querySelector('.site-scroller');

		if( !xScrollEl?.children ){ return }
		let xScrollItems = Array.from(xScrollEl.children);

		if (xScrollEl.classList.contains('slow-scroll')) {
			cancelAnimationFrame(this.slowScrollAnimFrame)
			this.slowScrollAnimFrame = undefined;
		}

		if ( xScrollEl.closest('.row').classList.contains('scroll-end') ) {
			this.props.history.push(pushURL);
		}

		xScrollEl.classList?.add('transition');
		let startLeft = xScrollEl.scrollLeft;

		let transitionTime = 600;
		let frames = transitionTime/16.6666;
		let currentFrame = 0;
		let progress = 0;
		let nextItem = null;
		let scrollDistance = null;

		// if there is a current animation going, cancel and push to the next index
		if (this.scrollAnimationFrame) {
			cancelAnimationFrame(this.scrollAnimationFrame);
			nextItem = this.getNextSiteScrollerItem(xScrollEl, xScrollItems[this.pushItemIndex + 1]);
			scrollDistance = nextItem.left;
		// just do the normal thing
		} else {
			nextItem = this.getNextSiteScrollerItem(xScrollEl);
			scrollDistance = nextItem.left// find next spot
		}

		this.pushItemIndex = _.indexOf(xScrollItems, nextItem.el);

		if( nextItem.el == xScrollEl.lastElementChild ){
			scrollDistance = xScrollEl.scrollWidth - xScrollEl.scrollLeft - xScrollEl.clientWidth;
		}

		let scrollDeltaX = scrollDistance;

		let incrementScroll = ()=>{

			let lastProgress = progress
				progress = currentFrame/frames;
			let easedValue = helpers.easeInOutCubic(progress);

			if( progress <= 1){

				xScrollEl.scrollLeft = startLeft + scrollDeltaX*easedValue;
				this.scrollAnimationFrame = requestAnimationFrame(incrementScroll);

			} else {
				// debugger
				xScrollEl.classList.remove('transition');
				this.scrollAnimationFrame = null;
				this.pushItemIndex = null;
			}
		
			currentFrame++;
		}

		requestAnimationFrame(incrementScroll);
	}

	render() {

		return (
			<>
                {TemplateGroups.map((section, index) => {

                    if( _.isEmpty(this.props.folders) ){ 
                        return <Fragment key={index}>{null}</Fragment> 
                    }

                    const anyFolderHasSites = _.some(this.props.folders, (folder) => {
                        return folder.sites.length > 0 && section.rows.some((row) => (isDev ? row.dev : row.live) === folder.id);
                    });

                    if( anyFolderHasSites ){
                        this.showPenguin = true;
                    }

                    return (
                        <React.Fragment key={`section-${index}1`}>
                                {section.rows?.map((folder, index)=>{
									const folder_id = isDev ? folder.dev : folder.live;

									if( this.props.routeInfo.isTemplates && !this.props.authenticated && folder?.excludeFromStart === true ){
										return null 
									}

									return (
                                        <SiteScroller 
                                            key={`folder-${folder_id}`}

                                            folderId        = { folder_id }
                                            section         = { section }
                                            xScrollRefs     = { this.state.xScrollRefs }
                                            progressSiteScroller = { this.state.progressSiteScroller }
                                            message         = { this.props.message }

                                            pushSiteScroller           = { this.pushSiteScroller }
                                            onSiteScrollerMouseDown    = { this.onSiteScrollerMouseDown }
                                            onSiteScrollerMouseUp      = { this.onSiteScrollerMouseUp }
                                        />
                                    )
                                })}
                        </React.Fragment>
                    )}
                )}
				{ !this.props.authenticated ? (
					<ElementScrollEffect elementReferences={this.state.xScrollRefs} authenticated={this.props.authenticated} />
				) : null }
			</>
		)
	}

}

function mapReduxStateToProps(state, ownProps) {

	const activeFolderSlug = ownProps.match.params?.folder ?? null;

	return {
		folders: state.templates,
		templates: state.templates,
		activeFolder: state.templates.find(folder => folder.slug === activeFolderSlug),
		authenticated: state.auth.authenticated,
		sitePreview: state.sitePreview,
	};

}

// function mapDispatchToProps(dispatch) {
	
// 	return bindActionCreators({
// 		addUIWindow: actions.addUIWindow,
// 		removeUIWindow: actions.removeUIWindow,
// 		updateHomepageState: actions.updateHomepageState,
// 	}, dispatch);

// }


export default withRouter(connect(
	mapReduxStateToProps,
	// mapDispatchToProps 
)(SiteScrollerHandler))