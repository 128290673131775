import { animateStyles } from './animate-styles';
import { bezierEasing } from './helpers';

export const AnimateElementOpacity = ( //ref, duration, delay, ease, initialStyles, targetStyles, cleanup, callback
    ref,
    duration = 200,
    delay = 0,
    transitionStyles = { ease: 'linear' },
    initialStyles = { opacity: 0 },
    targetStyles = { opacity: 1 },
    cleanup = true,
    callback = () => {},
) => {

    if ( !ref.current ) return;
    // Derive the easing function
    const easingFunction = Array.isArray(transitionStyles.ease)
        ? bezierEasing(...transitionStyles.ease)
        : (t) => t;

    // Define the callback for when the animation completes
    const animationComplete = () => {
        callback();
    };

    // Trigger the animation using the helper function
    animateStyles(
        ref.current,            // Element reference
        initialStyles,          // Initial styles
        targetStyles,           // Target styles
        duration / 1000,        // Duration in seconds
        delay / 1000,           // Delay in seconds
        easingFunction,         // Easing function
        cleanup,                // Cleanup styles after animation
        animationComplete       // Callback when animation is complete
    );
};