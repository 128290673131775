import { actionTypes, mutateFolderSiteArray } from "../actions";
import { combineReducers } from 'redux';
import _ from "lodash";

export default function(state = [], action){

	if(!action.payload && !action.meta) {
		return state;
	}

	switch(action.type) {

		case actionTypes.FETCH_PUBLIC_FOLDER_FULFILLED: 

			if(action.payload.data) {
                const resFolder = action.payload.data;
                resFolder.creatorId = action.meta.creatorId;
                const newState = [ ...state, resFolder ]

                return newState
			}

		break;

	}

	return state;

};