import React, {useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { ProfileIcon } from "@cargo/common/users";
import FolderIcon from "@cargo/common/icons/page-list-set.svg";
import SavedFolderIcon from "@cargo/common/icons/saved-folder-star.svg";
import _ from 'lodash';
import { MenuContext } from "@cargo/common/context-menu/context-menu-controller";
import FolderLinkContextUI from "./folder-link-context-ui";
import FolderArrow from "@cargo/common/icons/folder-arrow.svg";
import FolderLinks from './folder-links';
import FontTIcon from "@cargo/common/icons/formatting-menu/formatting-textoptions.svg";
import DownwardsCaret from "@cargo/common/icons/downwards-caret.svg";

const AuthenticatedTopMenuBar = ({profileLetter, canSearch, currentFolder, savedFolder, activeFolderSlug, isUserFolder, ...props}) => {

    const userColor = '#25AF3C';
    const lastVisitedFolder = localStorage.getItem('last-visted-slug');
    const restoreLastVisited = lastVisitedFolder ? `/${lastVisitedFolder}` : '/';
    const [showFolders, setShowFolders] = useState(false);
    
    const isTemplates = props.routeInfo.isTemplates;
    const currentFolderName = !isUserFolder || !currentFolder?.name ? 'Your Sites' : currentFolder.name;

    useEffect(() => {
        setShowFolders(false);
    }, [activeFolderSlug]);

    return (<>
        {!props.isMobile && <>
            {props.routeInfo.isCommunity ? <div className="area community-area light-mode"> 
                <NavLink
                    className={`button-link`}
                    draggable={false}
                    to={'/'}
                    activeClassName='selected'
                    onContextMenu={(e)=>{e.preventDefault();}}
                >
                    <FolderArrow />
                </NavLink>
            </div> : 
            <>
                <div className="area left-area">
                    <MenuContext.Consumer>
                    {(Menu) => (
                    <div 
                        className="area account-area"
                        onContextMenu = { e => { e.preventDefault(); }}
                    >
                        <button 
                            className = "profile"
                            onPointerDown = { e => {
                                Menu.openMenu({
                                    innerUI: <FolderLinkContextUI />,
                                    event: e,
                                    type: 'button',
									offset: {x: 30, y: 5}
                                }) 
                            }}
                        >
                            <span style={{pointerEvents: 'none', 'color': userColor}} >
                                <ProfileIcon letter = {profileLetter} color = {userColor} />
                            </span>
                        </button> 
                        {props.routeInfo.isCommunity && <NavLink
                            className={`button-link your-sites`}
                            draggable={false}
                            to={'/'}
                            onContextMenu={(e)=>{e.preventDefault();}}
                        >Your Sites</NavLink>}
                    </div>
                    )}
                    </MenuContext.Consumer>
                </div>


                <div className="area right-area">
                    { !isTemplates ? (
                        <div className="templates-add">
                            <NavLink
                                className={`button-link`}
                                draggable={false}
                                to={'/templates'}
                                // activeClassName='selected'
                                onContextMenu={(e)=>{e.preventDefault();}}
                                onClick={(e) => { 
                                    let templatesContainer = document.querySelector('#templates .sections');
                                    if( templatesContainer ){
                                        templatesContainer.scrollTop = 0;
                                    }
                                    // templatesContainer.scrollTo({top: 0, behavior: 'smooth'});
                                }}
                            >
                                New Site
                            </NavLink>
                        </div>
                    ) : ( <span className="templates-title button-link">Templates</span> )}


                    <div className="background-fill"></div>

                    <NavLink
                        className={`button-link fonts-link square`}
                        draggable={false}
                        to={'/fonts'}
                        activeClassName="selected"
                        onContextMenu={(e)=>{e.preventDefault();}}
                    >
                        <FontTIcon/>
                    </NavLink>

                    
                    {props.routeInfo.isCommunity ? <div className="square globe">
                        <object 
                            className="🌐" 
                            type="image/svg+xml" 
                            data={`${PUBLIC_URL}/images/spinning-globe.svg`}
                        ></object>
                    </div>
                    :
                    <NavLink
                        className={`button-link community square`}
                        draggable={false}
                        to={'/community/all'}
                        activeClassName='selected'
                        onContextMenu={(e)=>{e.preventDefault();}}
                    >
                        <object 
                            className="🌐" 
                            type="image/svg+xml" 
                            data={`${PUBLIC_URL}/images/spinning-globe.svg`}
                        ></object>
                    </NavLink>
                    }
                    

                </div>
            </>}
        </>}
    

        {props.isMobile && <>
            {props.routeInfo.isCommunity ? <div className="area community-area light-mode"> 
                <NavLink
                    className={`button-link`}
                    draggable={false}
                    to={'/'}
                    activeClassName='selected'
                    onContextMenu={(e)=>{e.preventDefault();}}
                >
                    <FolderArrow />
                </NavLink>
            </div> :
            <>
                <div className="area left-area">
                    <MenuContext.Consumer>
                    {(Menu) => (
                    <div 
                        className="area account-area square"
                        onContextMenu = { e => { e.preventDefault(); }}
                        onPointerDown = { e => {
                            Menu.openMenu({
                                innerUI: <FolderLinkContextUI />,
                                event: e,
                                type: 'cursor'
                            }) 
                        }}
                    >
                        <button
                            className = "profile"
                            style={{pointerEvents: 'none'}}
                        >
                            <span style={{pointerEvents: 'none', 'color': userColor}} >
                                <ProfileIcon letter = {profileLetter} color = {userColor} />
                            </span>
                        </button> 
                    </div>
                    )}
                    </MenuContext.Consumer>
                </div>
                <div className={`folder-area${showFolders ? ' open' : ''}`}>
                    <button
                        className = "folder-menu-button button-link"
                        onContextMenu = { e => { e.preventDefault(); }}
                        onPointerDown = { e => { 
                            setShowFolders(!showFolders);
                    }}>
                        {currentFolderName !== 'Saved' ? (
                            <div className="folder-icon"><FolderIcon /></div>
                        ) : (
                            <div className="folder-icon saved-icon"><SavedFolderIcon /></div>
                        )}
                        <div className="folder-title">{currentFolderName}</div>
                        <div className="caret-icon"><DownwardsCaret /></div>                        
                    </button> 
                    {showFolders ? (
                    <>
                    <div className="drop-down-menu">
                        <div className="relative-parent">
                            <FolderLinks folders={props.folders} type="vertical" />
                        </div>
                    </div>
                    <div 
                        className="drop-down-menu-clickout"
                        onClick={() => { setShowFolders(false); }}
                    ></div>
                    </>
                    ): null}
                </div>
                {props.routeInfo.isCommunity ? <div className="square globe">
                    <object 
                        className="🌐" 
                        type="image/svg+xml" 
                        data={`${PUBLIC_URL}/images/spinning-globe.svg`}
                    ></object>
                </div>
                :
                <NavLink
                    className={`button-link community square`}
                    draggable={false}
                    to={'/community/all'}
                    activeClassName='selected'
                    onContextMenu={(e)=>{e.preventDefault();}}
                >
                    <object 
                        className="🌐" 
                        type="image/svg+xml" 
                        data={`${PUBLIC_URL}/images/spinning-globe.svg`}
                    ></object>
                </NavLink>
                }
            </>}
        </>}

    </>);
}

function mapReduxStateToProps(state, ownProps) {

    const folders = state.folders;
    const renderedFolder = state.homepageState?.renderedFolder;
    const isUserFolder = renderedFolder ? folders.find(folder => folder.id === renderedFolder) ? true : false : false;
    
    const activeFolderSlug = ownProps.match.params?.folder ?? null;

    const hasC3Sites = state.account?.sites?.filter( (site)=>{if (site?.version === 'Cargo3') {return site}} )?.length > 0;
    const savedFolder = folders ? folders.filter(folder => folder.name.toLowerCase() === 'saved')[0] : null;

    return {
        folders,
        hasC3Sites,
        hasSavedSites: savedFolder?.sites?.length > 0,
        activeFolderSlug: activeFolderSlug,
        currentFolder: state.folders.find(folder => folder.slug === (activeFolderSlug || 'all')),
        savedFolder,
        isUserFolder,
        isMobile: state.homepageState.isMobile,
    };
}

export default withRouter(connect(
    mapReduxStateToProps
)(AuthenticatedTopMenuBar));