import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { actions } from "../actions";
import { withRouter } from 'react-router-dom';
import SearchIcon from "@cargo/common/icons/search-icon.svg";


const SearchMenu = (props) => {

    const [revealSearch, setRevealSearch] = useState(false);
    const searchRef = useRef(null);

    const prevPath = useRef();

    useEffect(() => {

        if (prevPath.current && props.location.pathname !== '/') {
            if (prevPath.current !== props.location.pathname) {
                clearSearch();
            }
        }

        // Update prevPath to the current props after comparison
        prevPath.current = props.location.pathname;
		
	}, [props.location.pathname]);

    useEffect(() => {
		if ( !props.routeInfo.isHomePage ) {
			// if not on homepage, clear search
			setRevealSearch(false)
			props.updateHomepageState({
				currentSearchTerm: null
			});
		}
	}, [props.routeInfo.isHomePage])

    const searchShortcut = (e) => {
		if (e.metaKey && e.which === 70 && !props.routeInfo.isCommunity && !props.routeInfo.isTemplates) {
			e.preventDefault();
			e.stopImmediatePropagation();
			setRevealSearch(true)
			setTimeout(()=>{
				searchRef?.current?.focus();
			},50)
		}
	}

    const clearSearch = () => {
        setRevealSearch(false);
        props.updateHomepageState({
            currentSearchTerm: null
        });
    }

    return (
        <div id="search-area" className={`${revealSearch ? 'revealed' : ''} ${props.hasSearchTerm ? 'searched' : ''}`}>
            <button 
                id="search-reveal"
                onPointerDown={(e)=>{
                    if (!revealSearch) {
                        setRevealSearch(true)
                        setTimeout(()=>{ !revealSearch && searchRef?.current?.focus(); }, 200)
                    } else {
                        clearSearch();
                    }
                }}
            >
                <SearchIcon />
            </button>
			{!revealSearch ? ( null ) : (
                <div className="search-form">
                    <input 
                        id="search" 
                        ref={searchRef}
                        type="text" 
                        autoComplete="off"
                        placeholder="Search…"
                        onBlur={(e)=>{
                            let value = e.currentTarget.value;
                            value = value.trim();
                            if( value === '') {
                                setRevealSearch(false);
                            }
                        }}
                        onInput={e => {
                            if (e.keyCode === 27) {
                                setRevealSearch(false);
                            } else {
                                props.updateHomepageState({
                                    currentSearchTerm: e.currentTarget.value === '' ? null : e.currentTarget.value
                                });    
                            }
                        }}
                    />
                </div>
			)}
		</div>
    );
}

function mapReduxStateToProps(state) {

    const renderedFolder = state.homepageState?.renderedFolder;
    const isUserFolder = renderedFolder ? state.folders.find(folder => folder.id === renderedFolder) ? true : false : false;

	return {
        isUserFolder,
		account: state.account,
        authenticated: state.auth.authenticated,
        hasSearchTerm: state.homepageState.currentSearchTerm !== null && state.homepageState.currentSearchTerm !== '',
	};

}

function mapDispatchToProps(dispatch) {

	return bindActionCreators({
		updateHomepageState: actions.updateHomepageState,
		addUIWindow: actions.addUIWindow,
	}, dispatch);

}

export default withRouter(connect(
	mapReduxStateToProps, 
	mapDispatchToProps
)(SearchMenu));

