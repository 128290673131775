let isDev = CARGO_ENV !== 'production';

export const TemplateGroups = [
	{
		title: 'Cargo Design Lab™',
		description: '',
		color: '#027AFF',
		rows: [
			{
				title: 'Popular',
				description: `Templates for larger, more comprehensive sites — overviews to display the output of individuals and studios.`,
				label: 'Lorem 22',
				live: 2602590,
				dev: 2602590,
				excludeFromStart: false
			},
			{
				title: 'Wireframes (Light)',
				description: `Full site templates without content.`,
				label: 'Lorem 22',
				live: 783658,
				dev: 783658,
				excludeFromStart: false,
			},
			{
				title: 'Wireframes (Dark)',
				description: `Full site templates without content.`,
				label: 'Lorem 22',
				live: 3570277,
				dev: 3570277,
				excludeFromStart: false,
			},
			{
				title: 'Dinamo Fonts',
				description: `Access the utility and beauty of Dinamo fonts. Cargo and Dinamo have an exclusive and continually developing partnership.`,
				label: 'Lorem 22',
				live: 2602594,
				dev: 2602594,
				excludeFromStart: false
			}
		]
	},
	{
		title: 'Studio Kit™',
		description: 'Groups of useful templates for multi-site applications; that is, the Studio Kit is a kind of model for building wider site representation for individual designers, artists and studios.',
		color: '#ff371e',
		hint: `Note: almost any element of the Design Lab can be turned into a site; look for the <i>Duplicate</i> button.`,
		rows: [
			// {
			// 	title: 'Portfolios',
			// 	description: `Templates for larger, more comprehensive sites — overviews to display the output of individuals and studios.`,
			// 	label: 'Lorem 22',
			// 	live: 2602590,
			// 	dev: 2602590,
			// 	excludeFromStart: false
			// },
			{
				title: 'Landing Pages',
				description: `Subsites, micro-sites, satellites (announcements, releases, invites, brbs, one-offs, menus, drop shops, etc., etc.).`,
				label: 'Lorem 22',
				live: 1606534,
				dev: 1606534,
				excludeFromStart: false
			},
			{
				title: 'Profiles',
				description: `Profile sites to spread around the various platforms (or, of course, for use however you’d like).`,
				label: 'Lorem 22',
				live: 1592887,
				dev: 1592887,
				isElementFolder: true,
				excludeFromStart: false
			},
			{
				title: 'Boards & Presentations',
				description: `Client presentations, brainstorms, visual overviews, proposals, studio organization, etc., etc.`,
				label: 'Lorem 22',
				live: 1592890,
				dev: 1592890,
				excludeFromStart: false
			},
			{
				title: 'Notes & Docs',
				description: `Text based sites (to do’s, essays, studio diaries, CVs, organizational outlines, syllabi, open letters, manifestos, grant applications).`,
				label: 'Lorem 22',
				live: 1592888,
				dev: 1592888,
				excludeFromStart: false
			},
			{
				title: 'Shops',
				description: `Commerce enables any site to have shopping functionality — to sell and receive payments for goods (whether physical or digital, objects or services).`,
				label: 'Lorem 22',
				live: 1592889,
				dev: 1592889,
				excludeFromStart: false
			}
		]
	},
	{
		title: 'Site Elements',
		description: '',
		color: '#00a53d',
		rows: [
			{
				title: 'Menus & Overlays',
				description: `Use overlays for site menus, popup windows or information panels — generally, layering the contents of one page on top of another.`,
				label: 'Lorem 22',
				live: 3518242,
				dev: 3518242,
				isCopyOnlyFolder: true,
				isElementFolder: true,
				excludeFromStart: true
			},
			{
				title: 'Samples & Specimens',
				description: `Interpretations/translations of favorite and “classic” design / typography (copy/paste or build from here).`,
				label: 'Lorem 22',
				live: 1605649,
				dev: 1605649,
				isElementFolder: true,
				excludeFromStart: false
			},
			{
				title: 'Backdrops/Filters',
				description: `High powered filters, patterns, and fields — animated or static — to change the environment of a site. Completely customize or use as is.`,
				label: 'Lorem 22',
				live: 1657223,
				dev: 1657223,
				isElementFolder: true,
				elementType: 'copybackdrop',
				excludeFromStart: true
			},
			{
				title: 'Text Styles',
				description: `Copy and paste text and font components, to be used as accents or anchors for an existing design.`,
				label: 'Lorem 22',
				live: 3408590,
				dev: 3408590,
				isCopyOnlyFolder: true,
				isElementFolder: true,
				excludeFromStart: true
			}
		]
	},
	{
		title: 'More...',
		description: '',
		color: '#6916d5',
		rows: [
			{
				title: 'Layout Fundamentals',
				description: `Fundamental ways to display / layout content, for research or direct site building`,
				label: 'Lorem 22',
				live: 1647628,
				dev: 1647628,
				excludeFromStart: false,
			},
			{
				title: 'Standard Sites',
				description: `Primary templates stocked w/ example content.`,
				label: 'Lorem 22',
				live: 783880,
				dev: 783880,
				excludeFromStart: false,
			},
			// {
			// 	title: 'Artist Spotlight',
			// 	description: '🖤🧡💙🖤🧡💙🖤🧡💙',
			// 	label: 'Lorem 22',
			// 	live: 799207,
			// 	dev: 799207,
			// 	isIdeaFolder: true,
			// 	excludeFromStart: true
			// },
			// {
			// 	title: 'Designer Spotlight',
			// 	description: '💚❤️💛💚❤️💛💚❤️💛',
			// 	label: 'Lorem 22',
			// 	live: 799208,
			// 	dev: 799208,
			// 	isIdeaFolder: true,
			// 	excludeFromStart: true
			// },
		]
	}
]

