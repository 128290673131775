import React from 'react';
import _ from 'lodash';
import { FontsPreview } from "@cargo/common/fonts-preview";
import { AnimatedDinamoIcon } from "./animated-dinamo-icon";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const FontsPage = ({isMobile}) => {

    return (
        <div id="fonts-page">
            <div className="background"></div>
            <div className="fill row"></div>
            <div className="row page-window">
                <div className="page-scroll">
                    { !isMobile ? 
                        <a target="_blank" href="https://abcdinamo.com" className="dinamo-inside"><AnimatedDinamoIcon animate={true} /></a>
                    : null }
                    <FontsPreview isMobile={isMobile} />
                </div>
            </div>
        </div>
    )

}

function mapReduxStateToProps(state, ownProps) {

    return {
        isMobile: state.homepageState.isMobile
    };

}

export default withRouter(connect(
    mapReduxStateToProps
)(FontsPage));