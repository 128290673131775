import React from 'react';
import _ from 'lodash'
import { NavLink, withRouter } from 'react-router-dom';

export const SectionHeader = withRouter(({ folder, isPublic, routeInfo, authenticated, ...props }) => {

    const sectionTitle = !isPublic ? folder?.row?.title : folder.name;

    return(
        <div className={`section-header`}>
            <div className="title-nav">
                <div className="header-row">
                    <span className="name">
                        {/* {authenticated ? ( */}
                            <NavLink
                                to={'/templates/' + folder.slug}
                                activeClassName='active'
                                exact
                                className={`name`}
                            >
                                {sectionTitle}
                            </NavLink>
                        {/* ) : ( sectionTitle )} */}
                    </span>
                </div>
                <a
                    onClick={(e) => {props.history.push("/templates/", {restoreScroll: true});}}
                    className={`back`}
                >
                    ←&nbsp;Back
                </a>
            </div>
        </div>
    )

})