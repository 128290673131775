import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAnimationContext } from './animation-context';

export const useAnimationPath = (key, pathCondition, initial = false, initialLocation) => {
	
	const history = useHistory();
	const currentPath = history.location.pathname;
	
	const {
		registerPotentialAnimation,
		startAnimation,
		potentialAnimations,
		inProgressAnimations,
		completedAnimations,
	} = useAnimationContext();

	const hasAnimated = useRef(false);
	const isInitialLoad = useRef(true);
	const previousPath = useRef(currentPath);

	const animationCompleted = completedAnimations.has(key);
	const applyInitialStyles = initial ? isInitialLoad.current && initialLocation === pathCondition && !animationCompleted : isInitialLoad.current && currentPath === pathCondition && potentialAnimations.has(key);

	const [shouldApplyInitialStyles, setShouldApplyInitialStyles] = useState( applyInitialStyles );

	useEffect(() => {
		if (hasAnimated.current) {
			return;
		}

		if (initial) {

			if (
				isInitialLoad.current &&
				initialLocation === pathCondition && 
				!completedAnimations.has(key)		
			) {
				setShouldApplyInitialStyles(true);
				registerPotentialAnimation(key);
				requestAnimationFrame(() => {
					startAnimation(key);
					hasAnimated.current = true;
					setShouldApplyInitialStyles(false);
				})
			}

		} else {

			// Trigger whenever the path matches
			if (currentPath === pathCondition && potentialAnimations.has(key)) {
				setShouldApplyInitialStyles(true);
				startAnimation(key);
				hasAnimated.current = true;
			}
			
		}

	}, [
		key,
		pathCondition,
		initial,
		currentPath,
		initialLocation,
		startAnimation,
		potentialAnimations,
	]);

  // Set intial load to false after the path changes.
	useEffect(() => {
		if (currentPath !== previousPath.current) {
			if (isInitialLoad.current) {
				isInitialLoad.current = false;
			}
			previousPath.current = currentPath;
		}
	}, [currentPath]);

	useEffect(() => {
		if (
			!inProgressAnimations.has(key) &&
			!completedAnimations.has(key) &&
			hasAnimated.current
		) {
			hasAnimated.current = true;
			setShouldApplyInitialStyles(false);
		}
	}, [key, inProgressAnimations, completedAnimations]);

	return shouldApplyInitialStyles;
};