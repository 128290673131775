import { actionTypes } from "../actions";
import { combineReducers } from 'redux';

import _ from "lodash";


export default function(state = [], action) {

	switch(action.type) {

		case actionTypes.FETCH_SITE_SUBSCRIPTIONS_FULFILLED: 

			if(!action.payload) {
				return state;
			}

			return action.payload.data;

		break;

		case actionTypes.TRANSFER_SITE_SUBSCRIPTION_FULFILLED:

			return [...state]

		break;
	}

	return state;

}