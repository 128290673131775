import React, { useState, useEffect } from 'react';
import _ from 'lodash';

const Clock = () => {

    const [time, setTime] = useState(new Date());

    // only render the clock clientside otherwise we cannot
    // hydrate the SSR HTML
    const [firstClientRenderComplete, setFirstClientRenderComplete] = useState(false);

    useEffect(() => {

        const intervalId = setInterval(() => {
            setTime(new Date());
        }, 1000);

        setFirstClientRenderComplete(true);

        return () => clearInterval(intervalId);
    }, []);

    const formattedTime = time.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    });

    return (
        <>
            {firstClientRenderComplete ? formattedTime : null}
        </>
    )

}

export default Clock;