import React, { Component, useEffect, useState } from 'react';
import { NavLink, withRouter, Route, Switch, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from "../actions";
import "../css/home.scss";
import BaseUnit from "./baseunit";
import UIWindowLayer from "./ui-window-layer";
import { RemoteAlert, HotKey } from "./ui-kit";
import { ContextMenu } from "@cargo/common/context-menu";
import { AnimationProvider } from "@cargo/common/animation-context";
import { DevGridLayer } from "@cargo/common/dev-grid-layer";
import { ToolTip } from "@cargo/common/tooltip";
import { IntercomButton } from '@cargo/common/intercom';
import { DroppableWrapper } from "./droppable-context-wrapper";
import TopMenuBar from "./top-menu-bar";
import LeftMenuBar from "./left-menu-bar";
import MainMenu from "./main-menu";
import { toggleDarkMode, storeDarkMode } from '../darkmode';
import { Login } from "@cargo/common/login";
import _ from 'lodash';
import { isSafari, isMac, isIOS, isServer, isServerRendered } from "@cargo/common/helpers";
import { Alert, AlertContext, Message } from "@cargo/ui-kit";
import * as Sentry from "@sentry/browser";
import { IntercomProvider } from 'react-use-intercom';
import { INTERCOM_APP_ID } from "@cargo/common";
import CargoLogo from "./svg/cargo-logo.svg";
import { staticPageComponents, paths, routesByPath, routes} from './router';
import SiteDuplicationProvider from './site-duplication-provider';

let currentHomepageViewport;
let fetchingInitialData = false;
let initialLoad = true;

export const IntercomSetup = (type) => { 

	return <AlertContext.Consumer>
		{(Alert) => (
			<IntercomProvider
				appId={INTERCOM_APP_ID}
				autoBoot={false}
				tunnelEnabled={INTERCOM_TUNNEL_ENABLED}
				tunnelPath={'/intercom.tunnel/'}
			>
				<IntercomButton 
					location='u.cargo' 
					sentry={Sentry}
					Alert={Alert}
					type={type}
					side={'left'}
				/>
			</IntercomProvider>
		)}
	</AlertContext.Consumer>

}

class App extends Component {
	
	constructor(props) {

		super(props);

		this.state = {
			devGridMode: false
		}

		this.windowData = {

			baseUnit: -1,

			mobile: {
				active: false,
				chrome: 0, // the size of the user interface, calculated by subtracting window height from maxheight
				maxHeight: 720, // the size of the viewport when the chrome is hidden, caused by scrolling
			},

			window: {
				w: 1280,
				h: 720,
			}

		}

		if(!isServer) {

			// check stored path and navigate there
			if(this.props.location.pathname === "/" && this.props.authenticated ) {
				try {

					const duplicatingViaQueryString = this.props?.location?.search && this.props.location?.search?.includes('?duplicate=')
					const joinSiteQueryString = this.props?.location?.search && this.props.location?.search?.includes('?site-join')

					const topLayer = _.last(this.props.layeredRouter.layerStack);

					if(topLayer.location.pathname === '/') {

						const lastVisitedFolder = localStorage.getItem('last-visted-slug')
						if( lastVisitedFolder && !duplicatingViaQueryString && !joinSiteQueryString ){
							this.props.history.replace(`/${lastVisitedFolder}`);
						}

					}

					if( joinSiteQueryString ){
						this.props.history.replace(`/`);
					}

				} catch(e) { console.error(e) }
			}

			// grab data needed to render
			if( this.props.authenticated && !fetchingInitialData ) {
				fetchingInitialData = true;
				this.fetchInitialData().then(()=>{
					this.props.fetchDeletedSites();
				});
			}

			this.SvhSupported = CSS.supports("height", "100svh");

			if( this.SvhSupported ){
				this.minHeightMeasuringStick = document.createElement('div');
				this.minHeightMeasuringStick.setAttribute('style', 'position: fixed; top: 0;left: 0;z-index: -99; visibility: hidden; height: 100svh;');
				this.minHeightMeasuringStick.setAttribute('tabindex', -1);
				this.minHeightMeasuringStick.setAttribute('aria-hidden', true);
				document.body.appendChild(this.minHeightMeasuringStick);				
			}

			this.maxHeightMeasuringStick = document.createElement('div');
			this.maxHeightMeasuringStick.setAttribute('style', 'position: fixed; top: 0;left: 0;z-index: -99; visibility: hidden; height: 100vh;');
			this.maxHeightMeasuringStick.setAttribute('tabindex', -1);
			this.maxHeightMeasuringStick.setAttribute('aria-hidden', true);
			document.body.appendChild(this.maxHeightMeasuringStick);		

			this.portraitAspectQuery = window.matchMedia(`(max-aspect-ratio: 4/5)`);
			this.touchQuery = window.matchMedia(`(pointer: coarse)`);
			this.setupMobileListener();
			this.onWindowResize();
			this.setOSClass();

			window.addEventListener('resize', this.onWindowResize, {passive: true});

		}

	}

	setMobileChange = (isMobile) => {
		this.props.updateHomepageState({
			isMobile
		});

		this.setMobileClass(isMobile);
	}

	setMobileClass = (state) => {

		if (state) {
			document.body.classList.add('mobile')
			document.documentElement.classList.add('mobile')
		} else {
			document.body.classList.remove('mobile')
			document.documentElement.classList.remove('mobile')
		}
	}

	setOSClass = () => {
		if (!isMac() && !isIOS()) {
			document.body.classList.add('f-f');
		}
	}

	getBaseUnit(options) {
		options = options || {};

		var baseUnit = 0;
		options = _.extend(true, {
			height: this.windowData.window.h,
			width : this.windowData.window.w,
			min   : 11,
			max   : false,
			weight: this.touchQuery.matches && !this.portraitAspectQuery.matches ? 16 : 5,
		}, options);

		// Start height or width, depending upon which is smallest
		baseUnit = options.width < options.height ? options.width : options.height;
		
		// Set as a percentage of the width/height
		baseUnit /= (100 / options.weight);

		// Minimums and maximums
		if (options.min && baseUnit < options.min) baseUnit = options.min;
		if (options.max && baseUnit > options.max) baseUnit = options.max;

		// Return the calculated unit
		return baseUnit;
	};

	onWindowResize =() =>{

		const {windowData} = this;
		let w = document.documentElement.clientWidth;
		let h = 0;
		let chrome = 0;
		let minHeight = 0;
		let maxHeight = this.maxHeightMeasuringStick.getBoundingClientRect().height;

		if( this.SvhSupported ){

			minHeight = this.minHeightMeasuringStick.getBoundingClientRect().height;		

		} else {

			let increment = 100;
			minHeight = 0;
			let lastResult;

			while(true) {

				const result = window.matchMedia.call(window, '(min-height:' + (minHeight) + 'px)')['matches'];

				if(lastResult !== undefined && result !== lastResult) {
					// we've overshot the value. Increase granularity
					increment /= 2;
					// start searching below the current value
					increment *= -1;
				}
				
				minHeight += increment;
				lastResult = result;

				// we've honed in on a value with 2 decimal precision
				if(Math.abs(increment) < 0.01) {
					minHeight = Math.round(minHeight)
					break;
				}

			}

		}

		chrome = maxHeight-minHeight;
		h = minHeight;

		let _diff   = 0;
		let _scale  = 5;
		let _size   = 0;
		let _weight = this.touchQuery.matches && !this.portraitAspectQuery.matches ? 16 : 9; // default value

		// Is this portrait?
		if (h+chrome > w) {
			_diff = ((h+chrome) / w) - 1;
		}

		// Ratio
		_diff = (_diff / 0.777777778);

		// iPhone vertical orientation ratio
		if (_diff > 1) {
			_diff = 1;
		}

		// Base unit
		_size = this.getBaseUnit({
			weight: _weight + (_scale * _diff),
			min: 20,
			width: w,
			height: h
		});
		
		if ( _size !== windowData.size ) {
			document.documentElement.style.setProperty('--base-size', _size + '%'); 
			document.documentElement.style.setProperty('--viewport-height', h+'px'); 
		}

		windowData.window.h = h;
		windowData.window.w = w;
	
		windowData.mobile.maxHeight = maxHeight;
		windowData.mobile.chrome = windowData.mobile.maxHeight - windowData.window.h;
		windowData.baseUnit= _size;

		this.windowData = windowData;
	}

	onMobileChange = () => {

		let isMobile;

		// admin viewport overrides mediaQuery results
		if(currentHomepageViewport) {
			isMobile = currentHomepageViewport === 'mobile';
		} else {
			isMobile = this.portraitAspectQuery.matches || this.touchQuery.matches || /Android/i.test(navigator.userAgent || '');
		}

		if(isMobile !== this.windowData.mobile.active) {
			this.windowData.mobile.active = isMobile;
			// this.emit('mobile-change', [isMobile]);
			this.setMobileChange(isMobile)
		}

	}

	setupMobileListener =()=>{

		this.portraitAspectQuery.removeEventListener('change', this.onMobileChange);

		// trigger immediately to test current state
		this.onMobileChange();

		// bind listener for future changes
		const matchListener = this.portraitAspectQuery.addEventListener('change', this.onMobileChange);

	}

	fetchInitialData = () => {

		// don't run this multiple times
		if(this.initialDataPromise) {
			return this.initialDataPromise
		}

		const promises = [];

		if( !this.props.hasAccount ){
			promises.push(this.props.fetchAccount())
		}

		return this.initialDataPromise = Promise.all(promises);

	}

	componentDidUpdate(prevProps, prevState) {

		if(
			prevProps.authenticated !== true
			&& this.props.authenticated === true
		) {
			document.body.classList.remove('home');
			// clear old initial data promise
			delete this.initialDataPromise;
			// went from not authed to authed
			this.fetchInitialData().then(()=>{
				this.props.fetchDeletedSites();
				//this.router(this.props.location);
				this.props.updateHomepageState({requireLogin: false})
			});
			
		}

		if( prevProps.authenticated !== this.props.authenticated ){
			this.addBodyPathAttribute();
		}

		if( !prevProps.hasAccount && this.props.hasAccount ){
			this.duplicateFromQueryString(this.props.location);
		}

		if (this.props.location !== prevProps.location) {
			this.addBodyPathAttribute();

		}

		// if( this.props.routeInfo 
		// 	&& this.props.routeInfo.isCommunity !== prevProps.routeInfo.isCommunity
		// ){
		// 	AnimateElementOpacity('body', 1500, 0, { ease: [0.25, 0.1, 0.25, 1] } );
		// }

		if( this.props.suppressLoginForm === true ){
			setTimeout(()=>{
				this.props.updateHomepageState({ suppressLoginForm: false })
			}, 1000)
		}

	}

	componentDidMount() {

		document.body.style.visibility = '';

		// preload CSS to prevent window flash
		import('@cargo/subscription-manager/css/account-settings-window.scss');

		this.addBodyPathAttribute();
		window.addEventListener("keydown", this.handleGlobalKeyDown);
		window.addEventListener("keyup", this.handleGlobalKeyUp);

		if( !this.props.authenticated ){
			this.duplicateFromQueryString(this.props.location);
		}

		setTimeout(()=>{
			initialLoad = false;
		}, 1500)

	}

	handleGlobalKeyDown = (e) => {
		if ( ( isMac() && e.metaKey === true ) || ( !isMac() && e.ctrlKey === true ) ) {
			document.body.classList.add('meta-key-pressed')
		}
	}

	handleGlobalKeyUp = (e) => {
		if ( ( isMac() && e.metaKey !== true ) || ( !isMac() && e.ctrlKey !== true ) ) {
			document.body.classList.remove('meta-key-pressed')
		}
	}

	addBodyPathAttribute = () => {
		const pathKeys = Object.keys(paths);
		const staticPathKeys = Object.keys(staticPageComponents);

		// Clear old attribute
		document.body.removeAttribute('data-route');
		// Determine the new attribute value
		let newPath = '';

		if (this.props.match?.path === paths.STATIC_PAGES) {

			let staticLocation = this.props.location.pathname.replace(/\//, '');
			newPath = staticPathKeys.find(key => key === staticLocation);
			// newPath !== 'fonts' ? newPath = 'static' : newPath = 'fonts';
			// newPath = "static";
		} else {
			newPath = pathKeys.find(key => paths[key] === this.props.match?.path);
		}

		// Add the new data-route attribute if it exists
		if ( newPath ) {
			document.body.setAttribute('data-route', newPath.toLowerCase().replace(/_/g, '-'));
		}

		// Special case for the ROOT path when not authenticated
		if (this.props.match?.path === paths.ROOT && !this.props.authenticated) {
			document.body.setAttribute('data-route', 'home');
		}

		if( this.props.match?.path === paths.ROOT && this.props.authenticated ){
			document.body.setAttribute('data-route', 'folder');
		}

	};

	duplicateFromQueryString = (currLocation) => { 
		let searchParams = new URLSearchParams(window.location.search);
		let templateIdentifier = null;

		if( searchParams && searchParams.has('duplicate') ){

			// If we have ?duplicate=xxxx in the URL, get it
			templateIdentifier = searchParams.get('duplicate');
			// delete it
			searchParams.delete('duplicate');
			// remove query string from URL without reloading page
			window.history.replaceState({}, document.title, window.location.origin + window.location.pathname );
		}

		// If we're not authenticated, save the query string to redux for use after auth.
		if( !this.props.authenticated && templateIdentifier ){

			this.props.updateHomepageState({
				queryStringDuplicateId: templateIdentifier,
				'requireLogin': true
			});
			// Turn back, will re-run function after authentication
			return
		}

		// If we are authenticated
		if( this.props.hasAccount && this.props.authenticated ){
			// turn back if we don't have an ID to duplicate...
			if( !this.props.queryStringDuplicateId && !templateIdentifier ){
				return
			}
			// Otherwise use the value from redux, or straight from the URL
			templateIdentifier = this.props.queryStringDuplicateId ? this.props.queryStringDuplicateId : templateIdentifier;
			// and start duplicating a site.
			window.dispatchEvent(new CustomEvent('duplicate-site-from-query', {
				detail: {
					siteId: templateIdentifier
				}
			}));
		} 

	}

	render() {

		const matchedRoute = routes.find(route => route.path === this.props.match.path)

		// this route requires being logged in
		if(matchedRoute?.requireLogin === true && !this.props.authenticated) {

			if(this.props.suppressLoginForm) {
				return null;
			}

			return <Login 
				getAuthTokenAction={actions.getAuthToken} 
				animateIn={ initialLoad ? false : true }
				canCreateNewAccount={false}
			/>

		}

		// if the route is marked to render standalone, render it
		// by itself
		if(matchedRoute?.renderStandalone) {
			return this.props.layeredRouter.renderMainRoute();
		}

		const hasStaticPage = this.props.layeredRouter.layerStack ? this.props.layeredRouter.layerStack.some(obj => obj.routeInfo.isStaticPage === true) : false;
		let canCreateNewAccount = false;

		// never allow signups on mobile
		if(!this.props.isMobile) {
			canCreateNewAccount = (this.props.homepageState.siteQueuedForDuplication || this.props.homepageState.loginCanCreateNewAccount) ? true : false;
		}

		const homepageClassList = `${isSafari() ? 'safari ' : ''}${ hasStaticPage ? 'static-page-active' : ''}`.trim();
		return (
			<Alert>
				<div id="index-anchor" style={{height:0,width:0}}></div>
				<Message>
					<AlertContext.Consumer>{(Alert) => (<RemoteAlert alert={Alert} />)}</AlertContext.Consumer>
					<ToolTip>
						<SiteDuplicationProvider>
						<ContextMenu>
						<AnimationProvider location={this.props.match.path}>
							<BaseUnit isTouch={this.touchQuery?.matches} isPortrait={this.portraitAspectQuery?.matches} isAuthenticated={this.props.authenticated} isMobile={this.props.isMobile} >
								{this.props.renderLoginForm ? ( 
									<Login 
										getAuthTokenAction={actions.getAuthToken} 
										canCreateNewAccount={ canCreateNewAccount } 
										templateID={this.props.siteQueuedForDuplication} 
										canClickout={ true }
										noOverlay={ true }
										onClickout={()=> { 
											this.props.updateHomepageState({
												'requireLogin': false,
												'siteQueuedForDuplication': null 
											})
										}}
										onLoginSuccess={() => {

											// always redirect to your "All" folder after logging in
											this.props.history.replace('/');

										}}
										loadingTillFormRemoval={true}
										animateIn={null}
									/>
								) : null }
								<DroppableWrapper>
									{this.props.layeredRouter.renderOverlays()}
									{!this.props.routeInfo.isPublicFolder ? (
										<TopMenuBar id="main-menu" location={this.props.location}>
											<MainMenu location={this.props.location} />
										</TopMenuBar>
									) : ( null )}
									{!this.props.routeInfo.isPublicFolder && !this.props.routeInfo.isCommunity && this.props.authenticated ? (
										<LeftMenuBar location={this.props.location} />
									) : ( null )}
									<div id="manager" className={homepageClassList}>
										{this.props.layeredRouter.renderMainRoute()}
									</div>
									<DevGridLayer gridSystemEnabled={this.props.gridSystemEnabled} updateHomepageState={this.props.updateHomepageState} showGridLines={this.state.devGridMode} authenticated={this.props.authenticated} isMobile={this.props.isMobile} />
								</DroppableWrapper>
							</BaseUnit>

							{ this.props.routeInfo.isTemplates && this.props.authenticated ? <CargoLogo /> : null }

							<UIWindowLayer history={this.props.history} />

							<HotKey shortcut="ctrl+shift+i" boundTo="all" callback={toggleDarkMode} />
							<HotKey shortcut="cmd+shift+g" boundTo="all" callback={() => {
								this.setState({devGridMode: !this.state.devGridMode})
							}} />
							
							<HotKey shortcut="escape" boundTo="interface" callback={() => {
								let activeWindow = Object.keys(this.props.activeUIWindows).length > 0;
								let alertActive = document.querySelector('.alert-window.active');
								let contextMenu = document.querySelector('.context-menu-layer');
								let loginWindow = document.querySelector('.login-window');
								let sitePreview = this.props.sitePreview.previewingSite;
								if( activeWindow || alertActive || contextMenu || sitePreview || loginWindow ){ return }
								document.activeElement.blur();
								this.props.history.push("/");
							}} />

						</AnimationProvider>
						</ContextMenu>
						</SiteDuplicationProvider>
					</ToolTip>

				</Message>
			</Alert>
		)
	}

}

function mapReduxStateToProps(state, ownProps) {
	return {
		homepageState: state.homepageState,
		hasAccount: state.homepageState.hasAccount,
		queryStringDuplicateId: state.homepageState.queryStringDuplicateId,
		authenticated: state.auth.authenticated,
		activeUIWindows: state.uiWindows.byId,
		userMeta: state.account?.meta,
		suppressLoginForm: state.homepageState.suppressLoginForm,
		sitePreview: state.sitePreview,
		deviceType: state.homepageState.deviceType,
		isMobile: state.homepageState.isMobile,
		gridSystemEnabled: state.homepageState.gridSystemEnabled,
		renderLoginForm: state.homepageState.requireLogin,
		siteQueuedForDuplication: state.homepageState.siteQueuedForDuplication
	}
}

const mapDispatchToProps = dispatch => bindActionCreators({
	fetchAccount: actions.fetchAccount,
	fetchFolders: actions.fetchFolders,
	fetchDeletedSites: actions.fetchDeletedSites,
	addUIWindow: actions.addUIWindow,
	updateUIWindow: actions.updateUIWindow,
	removeUIWindow: actions.removeUIWindow,
	updateHomepageState: actions.updateHomepageState,
	updateUserMeta: actions.updateUserMeta,
	getAuthToken: actions.getAuthToken,
}, dispatch)

export default withRouter(connect(
	mapReduxStateToProps, 
	mapDispatchToProps
)(App));
